import { useEffect } from "react";
import { useRef } from "react";
import { useCallback, useState } from "react";

export default function useToggleTool() {
  const [isOpenedTool, setIsOpenedTool] = useState(false);
  const MSWToolRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMSWToolClose = (e: MouseEvent) => {
      if (
        isOpenedTool &&
        e.target instanceof Node &&
        !MSWToolRef.current?.contains(e.target)
      ) {
        setIsOpenedTool(false);
      }
    };

    window.addEventListener("click", handleMSWToolClose);

    return () => {
      window.removeEventListener("click", handleMSWToolClose);
    };
  });

  const handleMSWToolToggle = useCallback(() => {
    setIsOpenedTool((prevState) => !prevState);
  }, []);

  return { MSWToolRef, isOpenedTool, handleMSWToolToggle };
}
