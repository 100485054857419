import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { TableDataListItem } from "@sellernote/_shared/src/components/table/Table";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import { InvoiceItem } from "@sellernote/_shared/src/types/fulfillment/packing";
import { getFormattedSKUId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { sortListByItemNameAndSkuId } from "@sellernote/_shared/src/utils/fulfillment/packing";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";

import { InvoiceDetailListItem } from ".";

const getTempRowList = (list: InvoiceItem[]) =>
  Array.from({ length: 4 - list.length }).map((item, i) => ({
    rowKey: `-${i}`,
    no: list.length + i + 1,
    packingNo: "",
    skuId: "",
    itemName: "",
    quantity: "",
  }));

const getRowList = (list: InvoiceItem[]) =>
  sortListByItemNameAndSkuId(list).map((item, i) => ({
    rowKey: item.skuId,
    no: i + 1,
    packingNo: item.packingNo,
    skuId: `${getFormattedSKUId(item.skuId)} ${
      item.skuBarcode ? `/ ${item.skuBarcode}` : ""
    }`,
    itemName: item.itemName,
    quantity: getStyledSKUCount({
      currentCount: item.currentQty,
      goalCount: item.quantity,
    }),
  }));

export default function useInvoiceDetail() {
  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);
  const isStatusOfScanningCompletedInvoice = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );
  const invoiceDetailList = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const isVisibleScrollbarForInvoiceDetailTable = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_VISIBLE_SCROLL_BAR_FOR_INVOICE_DETAIL_TABLE
  );
  const totalCurrentQty = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.TOTAL_CURRENT_QTY
  );
  const totalQuantity = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.TOTAL_QUANTITY
  );

  const invoiceDetailTableDataList =
    useMemo((): TableDataListItem<InvoiceDetailListItem>[] => {
      const isEmptyData = invoiceDetailList.length === 0;
      const isLessThanFiveData = invoiceDetailList.length < 5;

      return isEmptyData
        ? getTempRowList(invoiceDetailList)
        : isLessThanFiveData
        ? [
            ...getRowList(invoiceDetailList),
            ...getTempRowList(invoiceDetailList),
          ]
        : getRowList(invoiceDetailList);
    }, [invoiceDetailList]);

  return {
    isVisibleScrollbarForInvoiceDetailTable,

    isWorking,
    isStatusOfScanningCompletedInvoice,
    totalCount: getStyledSKUCount({
      currentCount: totalCurrentQty,
      goalCount: totalQuantity,
    }),
    invoiceDetailTableDataList,
  };
}
