import { COLOR } from "../../styles/constants";

function getStyledSKUCount({
  currentCount,
  goalCount,
}: {
  currentCount?: number;
  goalCount?: number;
}) {
  if (!goalCount) return;

  const isCompleted = currentCount === goalCount;

  return (
    <div>
      {/* 스캔해야할 수량은 검은색, 스캔한 수량은 스캔해야할 수량과 다르면 빨강, 같으면 녹색 */}
      <span
        style={{ color: isCompleted ? COLOR.pointSuccess : COLOR.pointWarning }}
      >
        {currentCount || 0}
      </span>
      {" / "}
      <span>{goalCount}</span>
    </div>
  );
}

/**
 * SKUId에서 접두어 S(s)를 뺀 결과를 반환
 */
function removeSKUIdPrefix(SKUId: string | number | undefined) {
  if (!SKUId) return SKUId;

  if (typeof SKUId === "number") return SKUId;

  return SKUId.replace(/^s/gi, "");
}

export { getStyledSKUCount, removeSKUIdPrefix };
