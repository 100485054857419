export const E5000 = {
  code: 400,
  error: "[Critical] 데이터베이스의 재고와 출고 데이터 불일치",
  errorCode: "E5000",
};

export const E5001 = {
  code: 400,
  error: "요청한 수량보다 재고가 부족하다.",
  errorCode: "E5001",
};

export const E5002 = {
  code: 400,
  error: "요청한 재고와 저장된 재고와 맞지 않다.",
  errorCode: "E5002",
};
export const E5006 = {
  code: 400,
  error: "choose the empty location",
  errorCode: "E5006",
  locations: [
    { id: 1, barCode: "a01-01-01" },
    { id: 2, barCode: "a02-02-02" },
  ],
};
