import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ErrorBoundary from "@sellernote/_shared/src/components/error/ErrorBoundary";
import PageNotFoundForCRA from "@sellernote/_shared/src/components/error/PageNotFoundForCRA";

import Login from "pages/login";
import Packing from "pages/packing";
import Print from "pages/packing/print";
import Sample from "pages/sample";

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <Route path="/" component={Login} exact />
          <Route path="/login" component={Login} exact />

          <Route path="/packing" component={Packing} exact />
          <Route path="/packing/print" component={Print} exact />

          <Route path="/sample" component={Sample} exact />

          <Route path="*" component={PageNotFoundForCRA} />
        </Switch>

        {children}
      </ErrorBoundary>
    </Router>
  );
}

export default AppRouter;
