import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { E5006 } from "../../error/5000_BOFUL_INVENTORY_ERROR";

export const getInventoryListForPicking = rest.get(
  getBofulDevAPI("/inventory/list/sku"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        // PAGE_UNIT이 5이나, 2페이지 표시 확인을 위해 6개를 반환
        total: 6,
        list: [
          {
            id: 1,
            warehouseId: 1,
            locationId: 2,
            availableQty: 1000,
          },
          {
            id: 2,
            warehouseId: 1,
            locationId: 2,
            availableQty: 1500,
          },
          {
            id: 3,
            warehouseId: 1,
            locationId: 3,
            availableQty: 2000,
          },
          {
            id: 4,
            warehouseId: 1,
            locationId: 4,
            availableQty: 100,
          },
          {
            id: 5,
            warehouseId: 1,
            locationId: 5,
            availableQty: 1030,
          },
          {
            id: 6,
            warehouseId: 1,
            locationId: 6,
            availableQty: 1090,
          },
        ],
      })
    );
  }
);

export const adjustmentAdminInventory = rest.patch(
  getBofulDevAPI("/inventory/admin/adjustment"),
  (req, res, ctx) => {
    return res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(E5006)
    );
  }
);
