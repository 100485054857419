import React from "react";

import TabFilter from "../../components/TabFilter";
import Table, { TableDataListItem } from "../../components/table/Table";

import { HandlerTableItem, MSWAPIType } from "./useHandlerList";
import Styled from "./index.styles";

export default function HandlerList({
  mswAPIType,
  handleMSWAPITypeChange,
  handlerTableList,
}: {
  mswAPIType: MSWAPIType;
  handleMSWAPITypeChange: (v: MSWAPIType) => void;
  handlerTableList: TableDataListItem<HandlerTableItem>[];
}) {
  return (
    <Styled.HandlerList>
      <TabFilter<MSWAPIType>
        filterList={[
          {
            label: "Shipda",
            filter: "shipda",
          },
          {
            label: "Boful",
            filter: "boful",
          },
          {
            label: "Content",
            filter: "content",
          },
          {
            label: "LocalPrinter",
            filter: "localPrinter",
          },
          {
            label: "shipdaAdmin",
            filter: "shipdaAdmin",
          },
        ]}
        selectedFilter={mswAPIType}
        setFilter={handleMSWAPITypeChange}
      />

      <Table<HandlerTableItem>
        columnInfo={{
          handler: {
            label: "Handler",
            portion: 3,
          },
          active: {
            label: "Active",
          },
        }}
        dataList={handlerTableList}
      />
    </Styled.HandlerList>
  );
}
