import React, { memo } from "react";

import TabFilter from "@sellernote/_shared/src/components/TabFilter";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";
import TableForCounting from "@sellernote/_shared/src/components/table/TableForCounting";

import usePackingDetail from "./usePackingDetail";
import Styled from "./index.styles";

export interface PackingDetailTableItem {
  no: number | "";
  skuId: string;
  itemName: string;
  quantity: React.ReactNode;
  bufferPackage: React.ReactNode;
  tapePackage: string;
  deleteButton: React.ReactNode;
}

export interface SkuToDelete {
  skuId: number;
  itemName: string;
}

function PackingDetail({
  rowInfoToHighlight,
  setRowInfoToHighlight,
}: {
  rowInfoToHighlight?: TableRowInfoToHighlight;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const {
    isEmptyPackingDetailList,
    isVisibleScrollbarForPackingDetailTable,

    tabFilterListOfPackingDetail,
    filterForPackingDetail,
    handlePackingDetailTabFilterChange,

    isSelectedTabFilter,

    packingDetailTableDataList,

    CountForScanningModal,

    DeletePackingSkuModal,

    ResponseHandlerOfDeletingLastPacking,
  } = usePackingDetail({ setRowInfoToHighlight });

  return (
    <>
      {/* 초기 상태에 아무값도 없더라도 높이를 유지시켜줘야 한다... */}
      <Styled.packingDetail
        isEmpty={isEmptyPackingDetailList}
        isVisibleScrollbar={isVisibleScrollbarForPackingDetailTable}
      >
        <TabFilter<string>
          filterList={tabFilterListOfPackingDetail}
          selectedFilter={filterForPackingDetail}
          setFilter={handlePackingDetailTabFilterChange}
        />
        <TableForCounting<PackingDetailTableItem>
          rowInfoToHighlight={
            isSelectedTabFilter ? rowInfoToHighlight : undefined
          }
          className="packing-detail-table"
          columnInfo={{
            no: {
              label: "목록",
            },
            skuId: {
              label: "SKU ID / 상품바코드",
              portion: 3,
            },
            itemName: {
              label: "상품명",
              portion: 4,
            },
            quantity: {
              label: "수량",
              portion: 2,
            },
            bufferPackage: {
              label: "완충재",
              portion: 3,
            },
            tapePackage: {
              label: "테이프",
              portion: 3,
            },
            deleteButton: {
              label: "삭제",
              portion: 1.5,
            },
          }}
          dataList={packingDetailTableDataList}
        />
      </Styled.packingDetail>

      {CountForScanningModal}

      {DeletePackingSkuModal}

      {ResponseHandlerOfDeletingLastPacking}
    </>
  );
}

export default memo(PackingDetail);
