import styled, { css } from "styled-components";

import { COLOR } from "../../../styles/constants";
import { fontStyle } from "../../../styles/functions";
import { setFontStyle } from "../../../styles/typography";

import { InputSelectUiType } from ".";

interface ContainerProps {
  width?: string;
}
const container = styled.div`
  ${(props: ContainerProps) => {
    if (props.width) {
      return css`
        width: ${props.width};
      `;
    }
  }}

  > .label {
    ${fontStyle("body4")};
    color: ${COLOR.grayScale_800};
    margin-bottom: 0.125rem;
  }

  em {
    ${fontStyle("caption")};
    color: ${COLOR.pointWarning};
  }

  > .option-list-wrapper {
    position: relative;
  }

  > .note-wrapper {
    position: relative;
  }

  &.disabled {
    > .label {
      color: ${COLOR.grayScale_400};
    }
  }

  > .warning-message-wrapper {
    position: relative;

    .warning-message {
      position: absolute;
      top: 2px;
      left: 0;
      ${setFontStyle("Caption")};
      color: ${COLOR.pointWarning};
    }
  }
`;

interface SelectedProps {
  uiType: InputSelectUiType;
}
const selected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${(props: SelectedProps) => {
    switch (props.uiType) {
      case "filled": {
        return css`
          background-color: ${COLOR.grayScale_100};
          border-bottom: 1px solid ${COLOR.grayScale_500};
          padding: 11px 8px;

          &.disabled {
            cursor: not-allowed;

            > .value {
              &.has-value {
                color: ${COLOR.grayScale_400};
              }
            }
          }

          &.opened {
            border-bottom: 1px solid ${COLOR.secondaryDeepBlue};
          }

          > .value {
            ${fontStyle("body3")};
            color: ${COLOR.grayScale_400};
            height: auto;
            max-height: 22px;
            overflow: hidden;

            &.has-value {
              color: ${COLOR.grayScale_800};
            }
          }
        `;
      }
      case "outline": {
        return css`
          background-color: transparent;
          border: 1px solid ${COLOR.grayScale_300};
          padding: 11px 8px;
          border-radius: 2px;

          > .value {
            ${setFontStyle("Body7")};
            color: ${COLOR.grayScale_300};
            height: auto;
            max-height: 22px;
            overflow: hidden;

            &.has-value {
              color: ${COLOR.grayScale_800};
            }
          }
        `;
      }
    }
  }}
`;

const optionList = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  max-height: 14.375rem;
  overflow: scroll;

  > .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0.5rem;
    background-color: ${COLOR.white};
    color: ${COLOR.grayScale_800};
    cursor: pointer;
    height: auto;
    min-height: 2.75rem;

    &.disabled {
      background-color: ${COLOR.grayScale_100};
      color: ${COLOR.grayScale_300};
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      background-color: ${COLOR.primaryBlue_10};
    }

    > .label {
      ${fontStyle("body3")};
    }

    > .desc {
      ${fontStyle("body4")};
    }
  }
`;

const note = styled.div`
  ${fontStyle("caption")};
  color: ${COLOR.pointWarning};
  position: absolute;
  top: 0.125rem;
  left: 0;
`;

export default {
  container,
  selected,
  optionList,
  note,
};
