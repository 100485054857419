import React, { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";

import Modal from "@sellernote/_shared/src/components/Modal";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { COLOR } from "@sellernote/_shared/src/styles/constants";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useScanCompletedInvoice() {
  const [isCompletedInvoice, setIsCompletedInvoice] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_COMPLETED_INVOICE
  );

  const handleCompletedInvoiceModalClose = useCallback(
    () => setIsCompletedInvoice(false),
    [setIsCompletedInvoice]
  );

  const CompletedInvoiceModal = useMemo(
    () => (
      <Modal
        barcodeValues={{
          actionPositive: "O_COM_INV",
        }}
        uiType="content"
        active={isCompletedInvoice}
        title="패킹이 완료된 송장입니다."
        body={
          <>
            <strong style={{ color: COLOR.pointWarning }}>
              패킹 내역 조회, 패킹 라벨 출력, 송장(QR)수동출력
            </strong>
            이 가능합니다.
            <br />
            새로운 패킹 작업을 시작하시려면, 패킹이 완료되지 않은 송장을
            스캔해주세요.
          </>
        }
        actionPositive={{
          label: "확인",
          handleClick: handleCompletedInvoiceModalClose,
        }}
      />
    ),
    [handleCompletedInvoiceModalClose, isCompletedInvoice]
  );

  useScanActionBarcode({
    actionBarcode: "O_COM_INV",
    actionFn: handleCompletedInvoiceModalClose,
  });

  return { CompletedInvoiceModal };
}
