import { MutableRefObject, useMemo } from "react";
import { SetupWorkerApi } from "msw";

import useLocalStorage, { parseJSON } from "../../hooks/common/useLocalStorage";
import { getHandlersToReset } from "../../services/msw";

import bofulHandlers from "../boful-api";
import contentHandlers from "../content-api";
import localPrinterHandlers from "../local-printer";
import shipdaAdminHandlers from "../shipda-admin-api";
import shipdaHandlers from "../shipda-api";
import { MSWAPIType } from "./useHandlerList";

type ShipdaHandlersType = typeof shipdaHandlers;
type BofulHandlersType = typeof bofulHandlers;
type ContentHandlersType = typeof contentHandlers;
type LocalPrinterHandlersType = typeof contentHandlers;
type ShipdaAdminHandlersType = typeof shipdaAdminHandlers;

interface ShipdaHandler {
  type: "shipda";
  handler: keyof ShipdaHandlersType;
  active: boolean;
}
interface BofulHandler {
  type: "boful";
  handler: keyof BofulHandlersType;
  active: boolean;
}
interface ContentHandler {
  type: "content";
  handler: keyof ContentHandlersType;
  active: boolean;
}
interface LocalPrinterHandler {
  type: "localPrinter";
  handler: keyof LocalPrinterHandlersType;
  active: boolean;
}
interface ShipdaAdminHandler {
  type: "shipdaAdmin";
  handler: keyof ShipdaAdminHandlersType;
  active: boolean;
}

export type Handler =
  | ShipdaHandler
  | BofulHandler
  | ContentHandler
  | LocalPrinterHandler
  | ShipdaAdminHandler;

function getHandlers<T extends MSWAPIType>(
  type: T,
  handlers: T extends "shipda"
    ? ShipdaHandlersType
    : T extends "boful"
    ? BofulHandlersType
    : T extends "content"
    ? ContentHandlersType
    : T extends "localPrinter"
    ? LocalPrinterHandlersType
    : T extends "shipdaAdmin"
    ? ShipdaAdminHandlersType
    : never
): Handler[];
function getHandlers(type: any, handlers: any): any {
  return Object.keys(handlers).map((handler) => ({
    type,
    handler,
    active: false,
  }));
}

export default function useHandlerData({
  workerRef,
}: {
  workerRef: MutableRefObject<SetupWorkerApi | undefined>;
}) {
  const allHandlers: Handler[] = useMemo(
    () => [
      ...getHandlers("shipda", shipdaHandlers),
      ...getHandlers("shipdaAdmin", shipdaAdminHandlers),
      ...getHandlers("boful", bofulHandlers),
      ...getHandlers("content", contentHandlers),
      ...getHandlers("localPrinter", localPrinterHandlers),
    ],
    []
  );

  /**
   * useLocalStorage는 기존에 저장된 데이터를 우선적으로 활용하기 때문에
   * 핸들러를 추가/삭제할 경우 리스트에 즉시 반영되지 않음.
   * 핸들러 리스트 변경 여부를 반영
   */
  const initialHandlers = useMemo(() => {
    const savedHandlerData =
      parseJSON<Handler[]>(window.localStorage.getItem("mswHandler")) ?? [];

    if (
      savedHandlerData.length !== 0 &&
      savedHandlerData.length !== allHandlers.length
    ) {
      let largeData: Handler[];
      let shortData: Handler[];
      if (savedHandlerData.length > allHandlers.length) {
        largeData = savedHandlerData;
        shortData = allHandlers;
      } else {
        largeData = allHandlers;
        shortData = savedHandlerData;
      }

      const difference = largeData.find(
        (v, i) => v.handler !== shortData[i]?.handler
      )!;
      const savedHandlerDataIndex = savedHandlerData.findIndex(
        (v) => v.handler === difference.handler
      );
      const newHandlerDataIndex = allHandlers.findIndex(
        (v) => v.handler === difference.handler
      );

      const newHandlerData =
        savedHandlerDataIndex === -1
          ? [
              ...savedHandlerData.slice(0, newHandlerDataIndex),
              difference,
              ...savedHandlerData.slice(newHandlerDataIndex),
            ]
          : savedHandlerData.filter((v) => v.handler !== difference.handler);

      // todo - normal 모드에서 reset 해줘서 체크 필요...
      workerRef.current?.resetHandlers(...getHandlersToReset(newHandlerData));

      window.localStorage.setItem("mswHandler", JSON.stringify(newHandlerData));

      return newHandlerData;
    } else {
      return allHandlers;
    }
  }, [allHandlers, workerRef]);

  const [handlerData, setHandlerData] = useLocalStorage(
    "mswHandler",
    initialHandlers
  );

  return { handlerData, setHandlerData };
}
