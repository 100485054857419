import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import Router from "Router";

import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import GlobalStyle from "@sellernote/_shared/src/legacyStyles/global";
import MSWTool from "@sellernote/_shared/src/mocks/MSWTool";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";

const simulateBrowserScan = (barcode: string) => {
  barcode.split("").forEach((v) => {
    document.dispatchEvent(
      new KeyboardEvent("keypress", {
        key: v,
      })
    );
  });
};

function App() {
  const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);

  useEffect(() => {
    if (!IS_UNDER_PRODUCTION)
      (window as any).simulateBrowserScan = simulateBrowserScan;
  }, []);

  return (
    <>
      <RecoilRoot>
        {IS_READY_FOR_MSW_TOOL && <MSWTool type="web" />}

        <QueryClientProvider client={queryClient}>
          {/* dev tool은 process.env.NODE_ENV === 'development' 일때만 표시됨 */}
          <ReactQueryDevtools initialIsOpen={false} />

          <Router>
            <GlobalStyle />
          </Router>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
