import React, { useCallback, useMemo } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import Modal from "@sellernote/_shared/src/components/Modal";
import useResetPackingStates from "@sellernote/_shared/src/hooks/fulfillment/useResetPackingStates";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

import Styled from "./index.styles";

export default function useCanceledInvoice() {
  const canceledInvoiceNo = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );
  const resetCanceledInvoiceNo = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );

  const { resetPackingStates } = useResetPackingStates();

  const handleCanceledInvoiceModalConfirm = useCallback(() => {
    resetCanceledInvoiceNo();

    resetPackingStates();
  }, [resetCanceledInvoiceNo, resetPackingStates]);

  const CanceledInvoiceModal = useMemo(
    () => (
      <Styled.canceledInvoiceModalWrapper>
        <Modal
          barcodeValues={{
            actionPositive: "O_CANCELED_INVOICE",
          }}
          uiType="contentWithCustomBody"
          active={!!canceledInvoiceNo}
          title={`${canceledInvoiceNo} (송장(QR)번호)`}
          body={
            <>
              <div>
                해당 건은 <strong>주문이 취소</strong>되었습니다.
                <br />
                매니저에게 안내 바랍니다.
              </div>
              <em>
                *확인버튼을 누르면 취소시점 담당자로 지정됩니다.
                <br />
                재입고 진행 전 까지 상품을 잘 보관해주세요.
              </em>
            </>
          }
          actionPositive={{
            label: "확인",
            handleClick: handleCanceledInvoiceModalConfirm,
          }}
        />
      </Styled.canceledInvoiceModalWrapper>
    ),
    [canceledInvoiceNo, handleCanceledInvoiceModalConfirm]
  );

  useScanActionBarcode({
    actionBarcode: "O_CAN_INV",
    actionFn: handleCanceledInvoiceModalConfirm,
  });

  return { CanceledInvoiceModal };
}
