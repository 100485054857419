import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/components/button/Button";
import InputSelect, {
  InputSelectOption,
} from "@sellernote/_shared/src/components/input/InputSelect";
import Modal from "@sellernote/_shared/src/components/Modal";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { getFormattedSKUId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getDetailOfSku } from "@sellernote/_shared/src/utils/fulfillment/packing";

import useSetSkuScanningResult from "../useSetSkuScanningResult";
import Styled from "./index.styles";

export default function useSelectDuplicateBarcode({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: Dispatch<
    SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const [isVisibleConfirmSelectionModal, setIsVisibleConfirmSelectionModal] =
    useState(false);
  const [isVisibleSelectSkuIdModal, setIsVisibleSelectSkuIdModal] =
    useState(false);
  const [scannedBarcode, setScannedBarcode] = useState<string>();
  const [selectedSkuId, setSelectedSkuId] = useState<number>();

  const invoiceDetailList = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );

  const { setSkuScanningResult } = useSetSkuScanningResult({
    setRowInfoToHighlight,
  });

  const handleConfirmSelectionModalOpen = useCallback(
    (scannedBarcode: string) => {
      setScannedBarcode(scannedBarcode);
      setIsVisibleConfirmSelectionModal(true);
    },
    []
  );

  const handleConfirmSelectionModalClose = useCallback(() => {
    setIsVisibleConfirmSelectionModal(false);
  }, []);

  const handleSelectSkuIdModalOpen = useCallback(() => {
    handleConfirmSelectionModalClose();
    setIsVisibleSelectSkuIdModal(true);
  }, [handleConfirmSelectionModalClose]);

  const handleSelectSkuIdModalClose = useCallback(() => {
    setIsVisibleSelectSkuIdModal(false);
    setScannedBarcode(undefined);
    setSelectedSkuId(undefined);
  }, []);

  const handleSelectionCompleteClick = useCallback(() => {
    // 선택된 SKU ID 정보는 반드시 존재한다.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const invoiceDetailOfScannedResult = getDetailOfSku({
      list: invoiceDetailList,
      skuId: selectedSkuId,
    })!;

    setSkuScanningResult({
      invoiceDetailOfScannedResult,
      scannedSkuId: selectedSkuId,
    });
    handleSelectSkuIdModalClose();
  }, [
    handleSelectSkuIdModalClose,
    invoiceDetailList,
    selectedSkuId,
    setSkuScanningResult,
  ]);

  const handleOptionClick = useCallback(
    (selectedOption: InputSelectOption<number>) => {
      setSelectedSkuId(selectedOption.value);
    },
    []
  );

  const SkuIdOptionListToSelect = useMemo(
    () =>
      invoiceDetailList
        .filter(
          ({ skuBarcode, currentQty, quantity }) =>
            skuBarcode === scannedBarcode && currentQty !== quantity
        )
        .map(({ skuId, itemName, skuBarcode }) => ({
          label: `${getFormattedSKUId(skuId)} - ${itemName} (${skuBarcode})`,
          value: skuId,
        })),
    [invoiceDetailList, scannedBarcode]
  );

  const selectedSkuIdOption = useMemo(
    () => SkuIdOptionListToSelect.find((item) => item.value === selectedSkuId),
    [SkuIdOptionListToSelect, selectedSkuId]
  );

  const ConfirmSelectionModal = useMemo(
    () => (
      <Modal
        active={isVisibleConfirmSelectionModal}
        title="상품 바코드가 동일한 상품이 있습니다. 선택하시겠습니까?"
        uiType="titleOnly"
        actionPositive={{
          label: "네",
          handleClick: handleSelectSkuIdModalOpen,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handleConfirmSelectionModalClose,
        }}
      />
    ),
    [
      handleSelectSkuIdModalOpen,
      handleConfirmSelectionModalClose,
      isVisibleConfirmSelectionModal,
    ]
  );

  const SelectSkuIdModal = useMemo(
    () => (
      <Modal
        active={isVisibleSelectSkuIdModal}
        title="상품 바코드에 해당하는 SKU ID 선택"
        uiType="contentWithCustomBody"
        body={
          <Styled.selectDuplicateBarcodeModalBody>
            <InputSelect
              uiType="outline"
              optionList={SkuIdOptionListToSelect}
              label="SKU ID 선택"
              selectedOption={selectedSkuIdOption}
              handleSelect={handleOptionClick}
            />
            <Button
              theme="primary"
              size="normal"
              label="선택 완료"
              disabled={!selectedSkuId}
              handleClick={handleSelectionCompleteClick}
            />
          </Styled.selectDuplicateBarcodeModalBody>
        }
        onClose={handleSelectSkuIdModalClose}
      />
    ),
    [
      SkuIdOptionListToSelect,
      handleSelectSkuIdModalClose,
      handleSelectionCompleteClick,
      handleOptionClick,
      isVisibleSelectSkuIdModal,
      selectedSkuId,
      selectedSkuIdOption,
    ]
  );

  return {
    handleConfirmSelectionModalOpen,

    ResultHandlerOfSelectingDuplicateBarcode: (
      <>
        {ConfirmSelectionModal}
        {SelectSkuIdModal}
      </>
    ),
  };
}
