import { useState } from "react";
import { useQueryClient } from "react-query";

import PACKING_QUERY, {
  PACKING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useFetchOutSidePackageOfShipda() {
  /** Query 요청에 실패한 경우 바코드 모달을 닫고, 재요청하는 것을 막기 위해 사용 */
  const [disabledQueryByForce, setDisabledQueryByForce] = useState(false);

  const queryClient = useQueryClient();

  const {
    ResponseHandler: ResponseHandlerOfGettingOutSidePackageListOfShipda,
  } = PACKING_QUERY.useGetOutsidePackageListOfShipda({
    enabled: !disabledQueryByForce,
  });

  useScanActionBarcode({
    actionBarcode: "H_GET_SHI_OUT",
    actionFn: () => {
      queryClient.removeQueries(
        PACKING_QUERY_KEY_GEN.outSidePackageListOfShipda()
      );
      setDisabledQueryByForce(true);
    },
  });

  return {
    ResponseHandlerOfGettingOutSidePackageListOfShipda,
  };
}
