export const GET_BID_TRELLO_LIST = {
  list: [
    {
      id: 123,
      management: {
        BLType: "HBL",
        forwardingManagerId: 52,
        bidId: 102725,
        detailStatus: null,
        confirmFlag: false,
        customsPaymentFlag: false,
        ETD: null,
      },
      projectStatus: "beforeContactPartner",
      freightType: "FCL",
      incoterms: "FOB",
      attachmentFlag: false,
      invoices: null,
      containCustoms: false,
      user: {
        company: "우왕ㅋ굳ㅋ",
      },
      partnerManagement: {
        inlandPartnerCompanyId: null,
      },
      endAddress: null,
      endAddressDetail: null,
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
      },
      endViaPort: null,
      endCountry: "KR",
      startCountry: "CN",
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
      },
      startViaPort: null,
      startAddress: null,
      zone: {
        name: null,
      },
      wareHouse: {
        addressCN: null,
      },
      serviceType: "general",
    },

    {
      id: 1234,
      management: {
        BLType: "HBL",
        forwardingManagerId: 52,
        bidId: 101725,
        detailStatus: null,
        confirmFlag: false,
        customsPaymentFlag: false,
        ETD: null,
      },
      projectStatus: "beforeContactPartner",
      freightType: "FCL",
      incoterms: "FOB",
      attachmentFlag: false,
      invoices: null,
      containCustoms: false,
      user: {
        company: "우왕ㅋ굳ㅋ",
      },
      partnerManagement: {
        inlandPartnerCompanyId: null,
      },
      endAddress: null,
      endAddressDetail: null,
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
      },
      endViaPort: null,
      endCountry: "KR",
      startCountry: "CN",
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
      },
      startViaPort: null,
      startAddress: null,
      zone: {
        name: null,
      },
      wareHouse: {
        addressCN: null,
      },
      serviceType: "general",
    },

    {
      id: 1235,
      management: {
        BLType: "HBL",
        forwardingManagerId: 52,
        bidId: 102225,
        detailStatus: null,
        confirmFlag: false,
        customsPaymentFlag: false,
        ETD: null,
      },
      projectStatus: "beforeContactPartner",
      freightType: "FCL",
      incoterms: "FOB",
      attachmentFlag: false,
      invoices: null,
      containCustoms: false,
      user: {
        company: "우왕ㅋ굳ㅋ",
      },
      partnerManagement: {
        inlandPartnerCompanyId: null,
      },
      endAddress: null,
      endAddressDetail: null,
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
      },
      endViaPort: null,
      endCountry: "KR",
      startCountry: "CN",
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
      },
      startViaPort: null,
      startAddress: null,
      zone: {
        name: null,
      },
      wareHouse: {
        addressCN: null,
      },
      serviceType: "general",
    },
  ],
  total: 134,
};
