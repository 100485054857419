import React, { Dispatch } from "react";
import { SetStateAction } from "react";

import Styled from "./index.styles";

export default function ConfirmBeforeCloseModal({
  setIsVisibleModal,
  actionPositiveHandleClick,
}: {
  setIsVisibleModal: Dispatch<SetStateAction<boolean>>;
  actionPositiveHandleClick: () => void;
}) {
  return (
    <Styled.containerWrapper
      className={`confirm-before-close-modal`}
      onClick={(e) => e.stopPropagation()}
    >
      <Styled.container uiType="webNegativeTitleOnly" hasBorder={false}>
        <div className="header">
          <div className="title">
            입력을 중단하고, 창을 닫으시겠습니까?
            <br />
            작성된 내용은 모두 삭제됩니다.
          </div>
        </div>

        <div className="actions">
          <div
            className="negative"
            onClick={(e) => {
              setIsVisibleModal(false);
              e.stopPropagation();
            }}
          >
            취소
          </div>

          <div
            className="positive"
            onClick={(e) => {
              actionPositiveHandleClick();
              setIsVisibleModal(false);
              e.stopPropagation();
            }}
          >
            삭제
          </div>
        </div>
      </Styled.container>

      <Styled.dimmedBackground />
    </Styled.containerWrapper>
  );
}
