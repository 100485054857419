import React, { memo } from "react";

import Barcode from "@sellernote/_shared/src/components/Barcode";
import Button from "@sellernote/_shared/src/components/button/Button";

import useNextPacking from "./useNextPacking";
import Styled from "./index.styles";

function NextPacking() {
  const {
    isActiveNextPackingButton,
    checkHasOutSidePackage,

    CheckHasOutSidePackageModal,

    ResponseHandlerOfAddingNextPacking,
  } = useNextPacking();

  return (
    <>
      <Styled.nextPacking isActive={isActiveNextPackingButton}>
        <Barcode
          value="NEXT_PAC"
          options={{
            displayValue: false,
            margin: 0,
          }}
        />
        <Button
          className="next-packing-button"
          theme="primary"
          label="다음 포장으로"
          size="block"
          handleClick={checkHasOutSidePackage}
          disabled={!isActiveNextPackingButton}
        />
      </Styled.nextPacking>

      {CheckHasOutSidePackageModal}

      {ResponseHandlerOfAddingNextPacking}
    </>
  );
}

export default memo(NextPacking);
