import {
  GET_CUSTOMS_LIST_RES,
  GET_SELECTED_CUSTOMS_RES,
} from "../../../api-interfaces/shipda-api/customs";

export const CUSTOMS_LIST: GET_CUSTOMS_LIST_RES = {
  total: 5,
  list: [
    {
      id: 1,
      email: "aaa@gmail.com",
      phone: "0335555666",
      company: {
        name: "원영관세사",
      },
    },
  ],
};

export const CUSTOMS_DETAIL: GET_SELECTED_CUSTOMS_RES = {
  companyName: "원영관세사",
  email: "aaa@gmail.com",
  phone: "0335555666",
  changeFlag: true,
  // changeFlag: false,
};
