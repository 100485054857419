import { GET_ADMIN_LOCATION_DETAIL_RES } from "../../../api-interfaces/boful-api/location";

export const ADMIN_LOCATION_DETAIL: GET_ADMIN_LOCATION_DETAIL_RES = {
  list: [
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: -100,
      sku: {
        id: 20072491,
        itemName: "당근두개당근두개당근두개당근두개당근두개",
        barCode: "10050010040011",
        managementCode: "R&JGLCAP001 - B",
      },
    },
    {
      actionType: "shipping",
      createdAt: new Date("2021-11-11T05:54:44.619Z"),
      companyName: "당근",
      actionId: 33333,
      quantity: 1011100,
      sku: {
        id: 1111,
        itemName: "당근",
        barCode: "111111",
        managementCode: "1111111",
      },
    },
    {
      actionType: "inventoryAdjustment",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 3,
      sku: {
        id: 222,
        itemName: "마늘",
        barCode: "11111",
        productCode: "110102022020",
      },
    },
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 100,
      sku: {
        id: 3333,
        itemName: "하",
        barCode: "111111",
      },
    },
    {
      actionType: "inventoryAdjustment",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 3,
      sku: {
        id: 222,
        itemName: "마늘",
        barCode: "11111",
        productCode: "110102022020",
      },
    },
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 100,
      sku: {
        id: 3333,
        itemName: "하",
        barCode: "111111",
      },
    },
    {
      actionType: "inventoryAdjustment",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 3,
      sku: {
        id: 222,
        itemName: "마늘",
        barCode: "11111",
        productCode: "110102022020",
      },
    },
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 100,
      sku: {
        id: 3333,
        itemName: "하",
        barCode: "111111",
      },
    },
    {
      actionType: "inventoryAdjustment",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 3,
      sku: {
        id: 222,
        itemName: "마늘",
        barCode: "11111",
        productCode: "110102022020",
      },
    },
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 100,
      sku: {
        id: 3333,
        itemName: "하",
        barCode: "111111",
      },
    },
    {
      actionType: "inventoryAdjustment",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 3,
      sku: {
        id: 222,
        itemName: "마늘",
        barCode: "11111",
        productCode: "110102022020",
      },
    },
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 100,
      sku: {
        id: 3333,
        itemName: "하",
        barCode: "111111",
      },
    },
    {
      actionType: "inventoryAdjustment",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 3,
      sku: {
        id: 222,
        itemName: "마늘",
        barCode: "11111",
        productCode: "110102022020",
      },
    },
    {
      actionType: "receiving",
      createdAt: new Date("2022-07-29T05:54:44.619Z"),
      companyName: "김",
      actionId: 123123,
      quantity: 100,
      sku: {
        id: 3333,
        itemName: "하",
        barCode: "111111",
      },
    },
  ],
  total: 10,
};
