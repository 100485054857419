import React, {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { SetupWorkerApi } from "msw";

import useLocalStorage from "../../hooks/common/useLocalStorage";
import {
  getHandlersToReset,
  printMessageWithActiveHandlers,
} from "../../services/msw";
import { COLOR } from "../../styles/constants";

import Icon from "../../components/Icon";
import { TableDataListItem } from "../../components/table/Table";

import { Handler } from "./useHandlerData";

export type MSWAPIType =
  | "shipda"
  | "boful"
  | "content"
  | "localPrinter"
  | "shipdaAdmin";

export interface HandlerTableItem {
  handler: string;
  active: ReactNode;
}

export default function useHandlerList({
  workerRef,
  handlerData,
  setHandlerData,
}: {
  workerRef: MutableRefObject<SetupWorkerApi | undefined>;
  handlerData: Handler[];
  setHandlerData: Dispatch<SetStateAction<Handler[]>>;
}) {
  const [mswAPIType, setMSWAPIType] = useLocalStorage<MSWAPIType>(
    "mswType",
    "shipda"
  );

  const handleMSWAPITypeChange = useCallback(
    (v: MSWAPIType) => {
      setMSWAPIType(v);
    },
    [setMSWAPIType]
  );

  const handleActiveStateToggle = useCallback(
    (selectedHandler: string) => () => {
      setHandlerData((prevData) => {
        const newData = [...prevData];
        const listOfSelectedAppType = newData.filter(
          (v) => v.type === mswAPIType
        );
        const index = listOfSelectedAppType.findIndex(
          (item) => item.handler === selectedHandler
        );
        listOfSelectedAppType[index].active =
          !listOfSelectedAppType[index].active;

        return newData;
      });

      workerRef.current?.resetHandlers(...getHandlersToReset(handlerData));

      printMessageWithActiveHandlers(workerRef, "Update active handlers");
    },
    [setHandlerData, workerRef, handlerData, mswAPIType]
  );

  const handlerTableList =
    useMemo((): TableDataListItem<HandlerTableItem>[] => {
      const listOfSelectedAppType = handlerData.filter(
        (v) => v.type === mswAPIType
      );

      return listOfSelectedAppType.map((v) => ({
        rowKey: v.handler,
        handler: v.handler,
        active: (
          <Icon
            type={v.active ? "toggleOn" : "toggleOff"}
            size={3}
            color={v.active ? COLOR.primaryBlue : COLOR.grayScale_700}
            onClick={handleActiveStateToggle(v.handler)}
          />
        ),
      }));
    }, [handlerData, mswAPIType, handleActiveStateToggle]);

  return { mswAPIType, handleMSWAPITypeChange, handlerTableList };
}
