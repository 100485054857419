import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/styles/constants";
import { setFontStyle } from "@sellernote/_shared/src/styles/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  > .title {
    text-align: center;
    padding: 4px 0;
    background-color: ${COLOR.grayScale_200};
    color: ${COLOR.grayScale_700};
    ${setFontStyle("SubHead3", "Bold")};
    border-bottom: 1px solid ${COLOR.grayScale_400};
  }

  > .detail {
    display: flex;
    align-items: center;
    height: 38px;

    > .out-side-package {
      flex: 1 0 auto;
      width: 33%;
      padding: 8px 4px;
      background-color: ${COLOR.grayScale_200};
      color: ${COLOR.grayScale_700};
      ${setFontStyle("SubHead3", "Bold")};
    }

    > .temp-provider {
      flex: 1 0 auto;
      width: 33%;
      height: 100%;
      background-color: ${COLOR.white};
    }

    > .temp-out-side-package-list {
      flex: 1 0 auto;
      width: 33%;
      height: 100%;
      background-color: ${COLOR.grayScale_100};
    }

    > .input-select {
      flex: 1 0 auto;
      height: 38px;

      .disabled {
        cursor: not-allowed;

        > .value {
          &.has-value {
            color: ${COLOR.grayScale_400};
          }
        }
      }

      > div {
        height: 100%;
        padding: 8px;
        border: 0;
      }
    }
  }
`;

export default {
  container,
};
