import React from "react";

import DATA, { IconType } from "./DATA";
import Styled from "./index.styles";

/**
 * lat과 lng은 이 컴포넌트에서는 쓰지 않지만, GoogleMap 컴포넌트 내부에서 아이콘으로 사용될때 GoogleMap 컴포넌트에 의해 사용된다.
 */
export default function Icon({
  type,
  size,
  color,
  bgColor,
  className,
  onClick,
  style,
  lat,
  lng,
}: {
  type: IconType;
  className?: string;
  size?: number;
  color?: string;
  bgColor?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  lat?: number;
  lng?: number;
}) {
  const getIcon = DATA[type];
  let combinedStyle: React.CSSProperties = {};

  if (style) {
    combinedStyle = {
      ...style,
    };
  }

  if (size) {
    combinedStyle = {
      ...style,
      width: `${size}rem`,
      height: `${size}rem`,
    };
  }

  function handelClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <Styled.container
      onClick={handelClick}
      style={combinedStyle}
      className={`icon icon-${type} ${className ? className : ""}`}
    >
      {getIcon({ width: size, color, bgColor })}
    </Styled.container>
  );
}
