import { useCallback, useMemo, useState } from "react";

import Toast from "@sellernote/_shared/src/components/Toast";
import Loading from "@sellernote/_shared/src/legacyComponents/Loading";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import PRINT_QUERY from "@sellernote/_shared/src/queries/fulfillment/PRINT_QUERY";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

/**
 * 전체 출력 시에만 바코드 버튼 동작을 위해서 packingIds를 전달
 */
export default function usePrintInvoice(packingIds?: number[]) {
  const [isVisibleToast, setIsVisibleToast] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");

  const {
    mutate: getInvoicePrintingData,
    ResponseHandler: ResponseHandlerOfGettingInvoicePrintingData,
    reset: resetGetInvoicePrintingData,
  } = PACKING_QUERY.useGetInvoicePrintingData({
    onSuccess: (res) => print(res),
  });

  const {
    mutate: print,
    data: resultOfPrinting,
    isLoading: isLoadingOfPrinting,
    isError: isErrorOfPrinting,
    reset: resetPrint,
  } = PRINT_QUERY.usePrint({
    onSettled: () => setIsVisibleToast(true),
  });

  const ToastOfPrintingInvoice = useMemo(() => {
    const message = isErrorOfPrinting ? (
      `송장번호 ${invoiceNo} 인쇄 요청을 실패하였습니다.`
    ) : resultOfPrinting?.RESULT_CD === "1" ? (
      `송장번호 ${invoiceNo} 인쇄 요청이 완료되었습니다.`
    ) : (
      <>
        송장번호 {invoiceNo} 인쇄에 실패하였습니다.
        <br />( {resultOfPrinting?.RESULT_MSG || "-"})
      </>
    );

    const failureStatus =
      isErrorOfPrinting || resultOfPrinting?.RESULT_CD !== "1";

    return (
      <>
        {isVisibleToast && (
          <Toast
            message={message}
            status={failureStatus ? "failure" : "success"}
            needIcon
            reset={() => {
              setIsVisibleToast(false);
              resetPrint();
            }}
          />
        )}
      </>
    );
  }, [
    invoiceNo,
    isErrorOfPrinting,
    isVisibleToast,
    resetPrint,
    resultOfPrinting?.RESULT_CD,
    resultOfPrinting?.RESULT_MSG,
  ]);

  const LoadingOfPrintingInvoice = useMemo(
    () => (
      <>{isLoadingOfPrinting && <Loading active={isLoadingOfPrinting} />}</>
    ),
    [isLoadingOfPrinting]
  );

  const printInvoice = useCallback(
    ({ packingIds, invoiceNo }: { packingIds: number[]; invoiceNo?: string }) =>
      () => {
        getInvoicePrintingData({ packingIds });
        invoiceNo && setInvoiceNo(invoiceNo);
      },
    [getInvoicePrintingData]
  );

  useScanActionBarcode({
    actionBarcode: "H_GET_INV_PRI",
    actionFn: resetGetInvoicePrintingData,
  });
  useScanActionBarcode({
    actionBarcode: "PRI_ALL_INV",
    actionFn: printInvoice({ packingIds: packingIds ?? [] }),
  });

  return {
    printInvoice,

    ResponseHandlerOfGettingInvoicePrintingData,

    ToastOfPrintingInvoice,
    LoadingOfPrintingInvoice,
  };
}
