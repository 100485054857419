import React, { useEffect, useRef } from "react";
import JSBarcode from "jsbarcode";

/**
 * https://github.com/lindell/JsBarcode/wiki/Options
 */
interface Options {
  width?: number;
  height?: number;
  format?: string;
  displayValue?: boolean;
  fontOptions?: string;
  font?: string;
  text?: string;
  textAlign?: string;
  textPosition?: string;
  textMargin?: number;
  fontSize?: number;
  background?: string;
  lineColor?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  valid?: (valid: boolean) => void;
}

export default function Barcode({
  value,
  options,
  className,
}: {
  value: string;
  options?: Options;
  className?: string;
}) {
  const barcodeRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (barcodeRef) {
      JSBarcode(barcodeRef.current, value, options);
    }
  }, [value, options]);

  return (
    <img
      className={`${className ? className : ""} barcode`}
      ref={barcodeRef}
      alt={value}
    />
  );
}
