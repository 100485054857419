import React, { memo } from "react";

import Table from "@sellernote/_shared/src/components/table/Table";

import useProblemListTable from "./useProblemListTable";

export type ReportProblemTableItem =
  | {
      skuId: string;
      count: React.ReactNode;
      workerName: string;
      problem: React.ReactNode;
    }
  | Record<string, unknown>;

function ProblemListTable() {
  const { problemTableList } = useProblemListTable();

  return (
    <Table<ReportProblemTableItem>
      className="report-problem-table"
      columnInfo={{
        skuId: {
          label: (
            <>
              SKU ID
              <br />
              (상품 바코드)
            </>
          ),
          portion: 2,
        },
        count: {
          label: "카운트",
        },
        workerName: {
          label: "담당자",
        },
        problem: {
          label: "발생문제 선택",
          portion: 2,
        },
      }}
      dataList={problemTableList}
    />
  );
}

export default memo(ProblemListTable);
