import React, { useEffect, useState } from "react";

import { COLOR } from "../../styles/constants";
import { useBodyScrollLock } from "../../utils/common/hook";

import Barcode from "../Barcode";
import Button from "../button/Button";
import Icon from "../Icon";
import Portal from "../Portal";
import ConfirmBeforeCloseModal from "./ConfirmBeforeCloseModal";
import Styled from "./index.styles";

export interface ModalFooterActions {
  actionPositive?: {
    label: React.ReactNode;
    handleClick: () => void;
  };
  actionNegative?: {
    label: React.ReactNode;
    handleClick: () => void;
  };
}

export type ModalTitleOnlyProps = {
  uiType: "titleOnly" | "webNegativeTitleOnly";
  isWebNegativeTitle?: boolean;
  isWebNegativeActionPositive?: boolean;
} & ModalFooterActions;

export type ModalContentProps = {
  uiType: "content" | "contentWithCustomBody";
  body: React.ReactNode;
  submitButton?: React.ReactNode;
  className?: string;
} & ModalFooterActions;

export type ModalWebNegativeProps = {
  uiType: "webNegative";
  body?: React.ReactNode;
} & ModalFooterActions;

export interface ModalFormInputProps {
  uiType: "formInput";
  desc?: string | React.ReactNode;
  body: React.ReactNode;
  hasBorder: boolean;
  submitButton?: React.ReactNode;
  disableMaxHeight?: boolean;
}

export interface ModalCommonProps {
  /**
   * 하위 element가 modal범위를 넘어가서 표시되어야 할 때 사용
   */
  allowOverflow?: boolean;
  barcodeValues?: {
    actionNegative?: string;
    actionPositive: string;
  };
  active: boolean;
  title: React.ReactNode;
  onClose?: () => void;
  needConfirmBeforeCloseModal?: boolean;
  className?: string;
  usePortal?: boolean;
}

/**
 * - contentWithCustomBody: 타이틀만 있고, 내용은 자유롭게
 */

function ModalComponent({
  active,
  title,
  onClose,
  allowOverflow,
  barcodeValues, // Boful > 패킹화면의 Barcode 형식의 모달용
  needConfirmBeforeCloseModal,
  usePortal,
  className,
  ...propsByType
}: ModalCommonProps &
  (
    | ModalTitleOnlyProps
    | ModalWebNegativeProps
    | ModalContentProps
    | ModalFormInputProps
  )) {
  const [
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  ] = useState(false);

  const { lockBodyScroll, unLockBodyScroll } = useBodyScrollLock();

  const handleClickCloseModal = (e: React.MouseEvent) => {
    if (!onClose) return;

    if (needConfirmBeforeCloseModal)
      return setIsVisibleConfirmBeforeCloseModal(true);

    onClose();
    e.stopPropagation();
  };

  useEffect(() => {
    if (active) {
      lockBodyScroll();
    } else {
      unLockBodyScroll();
    }
  }, [active]);

  useEffect(() => {
    return unLockBodyScroll;
  }, []);

  if (!active) {
    return null;
  }

  return (
    <>
      <Styled.containerWrapper
        className={`${className ? className : ""} modal`}
        onClick={(e) => e.stopPropagation()}
      >
        <Styled.container
          uiType={propsByType.uiType}
          onScroll={(e) => {
            e.stopPropagation();
          }}
          allowOverflow={allowOverflow}
          hasBorder={
            propsByType.uiType === "formInput" && propsByType.hasBorder
          }
          disableMaxHeight={
            propsByType.uiType === "formInput" && propsByType.disableMaxHeight
          }
          isWebNegativeTitle={
            propsByType.uiType === "titleOnly" && propsByType.isWebNegativeTitle
          }
          isWebNegativeActionPositive={
            propsByType.uiType === "titleOnly" &&
            propsByType.isWebNegativeActionPositive
          }
          className="modal-contents"
        >
          <div className="header">
            <div className="title">{title}</div>

            {propsByType.uiType === "formInput" && (
              <div className="desc">{propsByType.desc}</div>
            )}

            {onClose && (
              <Icon
                onClick={handleClickCloseModal}
                type="clear"
                size={2}
                color={COLOR.grayScale_800}
              />
            )}
          </div>

          {(propsByType.uiType === "webNegative" ||
            propsByType.uiType === "content" ||
            propsByType.uiType === "contentWithCustomBody" ||
            propsByType.uiType === "formInput") &&
            propsByType.body && <div className="body">{propsByType.body}</div>}

          {propsByType.uiType !== "formInput" &&
            !barcodeValues &&
            (propsByType.actionPositive || propsByType.actionNegative) && (
              <div className="actions">
                {propsByType.actionNegative && (
                  <div
                    className="negative"
                    onClick={(e) => {
                      if (propsByType.actionNegative) {
                        propsByType.actionNegative.handleClick();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {propsByType.actionNegative.label}
                  </div>
                )}

                {propsByType.actionPositive && (
                  <div
                    className="positive"
                    onClick={(e) => {
                      if (propsByType.actionPositive) {
                        propsByType.actionPositive.handleClick();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {propsByType.actionPositive.label}
                  </div>
                )}
              </div>
            )}

          {propsByType.uiType === "formInput" && propsByType.submitButton && (
            <div className="submit-button-container">
              {propsByType.submitButton}
            </div>
          )}

          {propsByType.uiType !== "formInput" &&
            !!barcodeValues &&
            (propsByType.actionPositive || propsByType.actionNegative) && (
              <div className="barcode-actions">
                {barcodeValues.actionNegative && propsByType.actionNegative && (
                  <div className="barcode-action-container">
                    <Barcode
                      value={barcodeValues.actionNegative}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                    <Button
                      theme="dangerStroke"
                      size="block"
                      label={propsByType.actionNegative.label}
                      handleClick={(e) => {
                        if (propsByType.actionNegative) {
                          propsByType.actionNegative.handleClick();
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>
                )}

                {barcodeValues.actionPositive && propsByType.actionPositive && (
                  <div className="barcode-action-container">
                    <Barcode
                      value={barcodeValues.actionPositive}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                    <Button
                      theme="secondary"
                      size="block"
                      label={propsByType.actionPositive.label}
                      handleClick={(e) => {
                        if (propsByType.actionPositive) {
                          propsByType.actionPositive.handleClick();
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
        </Styled.container>

        <Styled.dimmedBackground onClick={handleClickCloseModal} />
      </Styled.containerWrapper>

      {onClose && isVisibleConfirmBeforeCloseModal && (
        <ConfirmBeforeCloseModal
          setIsVisibleModal={setIsVisibleConfirmBeforeCloseModal}
          actionPositiveHandleClick={() => onClose()}
        />
      )}
    </>
  );
}

export default function Modal(props: Parameters<typeof ModalComponent>[0]) {
  return props.usePortal ? (
    <Portal selector="#app-portal">
      <ModalComponent {...props} />
    </Portal>
  ) : (
    <ModalComponent {...props} />
  );
}
