import React, { memo } from "react";

import Barcode from "@sellernote/_shared/src/components/Barcode";
import Button from "@sellernote/_shared/src/components/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";

import useDeleteLastPacking from "./useDeleteLastPacking";
import Styled from "./index.styles";

function DeleteLastPacking({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const {
    isActiveDeleteLastPackingButton,
    handleDeleteModalOpen,

    DeleteLastPackingModal,

    ResponseHandlerOfDeletingLastPacking,
  } = useDeleteLastPacking({ setRowInfoToHighlight });

  return (
    <>
      <Styled.deleteLastPacking>
        <Barcode
          value="DEL_LAST_PAC"
          options={{
            displayValue: false,
            margin: 0,
          }}
        />
        <Button
          theme="dangerStroke"
          size="block"
          label="최근포장삭제"
          handleClick={handleDeleteModalOpen}
          disabled={!isActiveDeleteLastPackingButton}
        />
      </Styled.deleteLastPacking>

      {DeleteLastPackingModal}

      {ResponseHandlerOfDeletingLastPacking}
    </>
  );
}

export default memo(DeleteLastPacking);
