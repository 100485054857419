import React from "react";

import Chip from "../Chip";
import Styled from "./index.styles";

export interface TabFilterItem<Filter> {
  filter: Filter;
  label: string;
}

export default function TabFilter<Filter extends string>({
  filterList,
  selectedFilter,
  setFilter,
  className,
}: {
  filterList: TabFilterItem<Filter>[];
  selectedFilter?: Filter;
  setFilter: (filter: Filter) => void;
  className?: string;
}) {
  return (
    <Styled.container className={`${className ? className : ""} tab-filter`}>
      {filterList.map((v, i) => {
        return (
          <Chip
            key={v.filter}
            shape="round"
            className="service-badge"
            label={v.label}
            selectableInfo={{
              selected: v.filter === selectedFilter,
              handleClick: () => setFilter(v.filter),
            }}
          />
        );
      })}
    </Styled.container>
  );
}
