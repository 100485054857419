import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import TextButton from "@sellernote/_shared/src/components/button/TextButton";
import Modal from "@sellernote/_shared/src/components/Modal";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/components/table/Table";
import ToolTip from "@sellernote/_shared/src/components/ToolTip";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import { getFormattedSKUId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  getDetailOfSku,
  getPackingInfoOfPackingNo,
  restoreInvoiceDetailList,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

import { PackingDetailTableItem, SkuToDelete } from ".";
import CountForScanning from "./CountForScanning";
import useCountForScanning from "./useCountForScanning";

export default function usePackingDetail({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const [filterForPackingDetail, setFilterForPackingDetail] =
    useState<string>();
  const [skuToDelete, setSkuToDelete] = useState<SkuToDelete>();

  const isEmptyPackingDetailList = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_EMPTY_PACKING_DETAIL_LIST
  );
  const isVisibleScrollbarForPackingDetailTable = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_VISIBLE_SCROLL_BAR_FOR_PACKING_DETAIL_TABLE
  );
  const tabFilterListOfPackingDetail = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.TAB_FILTER_LIST_OF_PACKING_DETAIL
  );
  const packingInfoOfSelectedPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.PACKING_INFO_OF_SELECTED_PACKING_NO
  );
  const lastPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.LAST_PACKING_NO
  );
  const [invoiceDetailList, setInvoiceDetailList] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const [packingInfoList, setPackingInfoList] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const [selectedPackingNo, setSelectedPackingNo] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO
  );
  const setIsScannedSkuIdComplete = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_SCANNED_SKU_ID_COMPLETE
  );

  const {
    mutate: deleteLastPacking,
    reset: resetDeleteLastPacking,
    ResponseHandler: ResponseHandlerOfDeletingLastPacking,
  } = PACKING_QUERY.useDeleteLastPacking({
    type: "packedItem",
  });

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const setCountInputResult = useCallback(
    /** 두 테이블에 각각 적용해야 하기 때문에 PDA와 다르게 실제 입력한 숫자만 전달 */
    ({ selectedSkuId }: { selectedSkuId: number }) =>
      (count: number) => {
        // 직접 입력 단계에서는 항상 존재
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const invoiceDetailOfSelectedSkuId = getDetailOfSku({
          list: invoiceDetailList,
          skuId: selectedSkuId,
        })!;

        /** INVOICE_DETAIL_LIST */
        setInvoiceDetailList((prevInvoiceDetailList) => {
          const newInvoiceDetail = { ...invoiceDetailOfSelectedSkuId };

          newInvoiceDetail.currentQty += count;

          return prevInvoiceDetailList.map((prevInvoiceDetail) =>
            prevInvoiceDetail.skuId === selectedSkuId
              ? newInvoiceDetail
              : prevInvoiceDetail
          );
        });

        /** PACKING_INFO_LIST */
        setPackingInfoList((prevPackingInfoList) => {
          const lastPackingInfo = getPackingInfoOfPackingNo({
            packingInfoList: prevPackingInfoList,
            packingNo: "last",
          });
          // 직접 입력 단계에서는 항상 존재
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const packingDetailOfSelectedSkuId = getDetailOfSku({
            list: lastPackingInfo.packingDetailList,
            skuId: selectedSkuId,
          })!;

          const newPackingDetail = { ...packingDetailOfSelectedSkuId };

          newPackingDetail.currentQty += count;

          return [
            ...prevPackingInfoList.slice(0, -1),
            {
              ...lastPackingInfo,
              packingDetailList: lastPackingInfo.packingDetailList.map(
                (prevPackingDetailItem) =>
                  prevPackingDetailItem.skuId === selectedSkuId
                    ? newPackingDetail
                    : prevPackingDetailItem
              ),
            },
          ];
        });

        // ! 취소관리 위한 임시 확인용
        const isSelectedSkuIdComplete =
          invoiceDetailOfSelectedSkuId.currentQty + count ===
          invoiceDetailOfSelectedSkuId.quantity;
        // 실제 취소 확인은 useScanSku에서 처리하기 때문에 완료 여부만 확인함
        if (isSelectedSkuIdComplete) {
          setIsScannedSkuIdComplete(true);
        }

        setRowInfoToHighlight({ rowKey: selectedSkuId });
      },
    [
      invoiceDetailList,
      setInvoiceDetailList,
      setIsScannedSkuIdComplete,
      setPackingInfoList,
      setRowInfoToHighlight,
    ]
  );

  // 완충재가 여러 개인 경우 툴팁 표시
  const toolTipRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (toolTipRef.current) {
        toolTipRef.current.style.left = `${String(e.pageX)}px`;
        toolTipRef.current.style.top = `${String(e.pageY + 20)}px`;
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  // 추가, 삭제 등으로 selectedPackingNo가 바뀌는 경우 탭도 연동되어 변경되도록 함
  useEffect(() => {
    setFilterForPackingDetail(String(selectedPackingNo));
  }, [selectedPackingNo]);

  const handlePackingDetailTabFilterChange = useCallback(
    (selectedTabFilter: string) => {
      setFilterForPackingDetail(selectedTabFilter);

      setSelectedPackingNo(Number(selectedTabFilter));
    },
    [setSelectedPackingNo]
  );

  const handleDeleteModalOpen = useCallback(
    (skuToDelete: SkuToDelete) => () => {
      setSkuToDelete(skuToDelete);
    },
    []
  );

  const packingDetailTableDataList =
    useMemo((): TableDataListItem<PackingDetailTableItem>[] => {
      const packingDetailListOfSelectedPackingNo =
        packingInfoOfSelectedPackingNo?.packingDetailList ?? [];

      return packingDetailListOfSelectedPackingNo.length === 0
        ? [
            {
              rowKey: -1,
              no: "",
              skuId: "",
              itemName: "",
              quantity: "",
              bufferPackage: "",
              tapePackage: "",
              deleteButton: "",
            },
          ]
        : packingDetailListOfSelectedPackingNo.map((packingDetailItem, i) => {
            const bufferPackageList = packingDetailItem.bufferPackageList;
            const hasMoreThanTwoData = bufferPackageList.length > 1;

            return {
              rowKey: packingDetailItem.skuId,

              no: i + 1,

              skuId: `${getFormattedSKUId(packingDetailItem.skuId)} ${
                packingDetailItem.skuBarcode
                  ? `/ ${packingDetailItem.skuBarcode}`
                  : ""
              }`,

              itemName: packingDetailItem.itemName,

              quantity: (
                <CountForScanning
                  canOpenDirectInputModal={
                    packingDetailItem.isActiveDeleteButton
                  }
                  currentQty={packingDetailItem.currentQty}
                  quantity={packingDetailItem.quantity}
                  openDirectInputModal={() => {
                    handleDirectInputModalOpen();
                    setCountForScanning({
                      skuId: packingDetailItem.skuId,
                      currentQty: packingDetailItem.currentQty,
                      quantity: packingDetailItem.quantity,
                      onCountAdd: setCountInputResult({
                        selectedSkuId: packingDetailItem.skuId,
                      }),
                    });
                  }}
                />
              ),

              bufferPackage: hasMoreThanTwoData ? (
                <ToolTip
                  toolTipBodyRef={toolTipRef}
                  displayType="block"
                  contentForDesktop={{
                    position: "bottomRight",
                    bodyWidth: 14,
                    content: {
                      type: "titleAndDesc",
                      title: "완충재",
                      desc: bufferPackageList.map((bufferPackage) => (
                        <div key={bufferPackage}>-{bufferPackage}</div>
                      )),
                    },
                  }}
                  contentForMobile={{}}
                >
                  {bufferPackageList[0]}...
                </ToolTip>
              ) : (
                bufferPackageList[0]
              ),

              tapePackage: packingDetailItem.tapePackage,

              deleteButton: (
                <TextButton
                  theme="danger"
                  size="14px"
                  icon="arrowRight"
                  label="삭제"
                  disabled={!packingDetailItem.isActiveDeleteButton}
                  handleClick={handleDeleteModalOpen({
                    skuId: packingDetailItem.skuId,
                    itemName: packingDetailItem.itemName,
                  })}
                />
              ),
            };
          });
    }, [
      handleDeleteModalOpen,
      handleDirectInputModalOpen,
      packingInfoOfSelectedPackingNo?.packingDetailList,
      setCountForScanning,
      setCountInputResult,
    ]);

  const handlePackedSkuDelete = useCallback(
    (skuIdToDelete: number | undefined) => {
      if (!skuIdToDelete) return;

      const lastPackingInfo = getPackingInfoOfPackingNo({
        packingInfoList,
        packingNo: "last",
      });
      const lastPackingDetailList = lastPackingInfo.packingDetailList;

      /** INVOICE_DETAIL_LIST 되돌리기 */
      restoreInvoiceDetailList({
        packingDetailList: lastPackingDetailList,
        skuIdListToDelete: [skuIdToDelete],
        setInvoiceDetailList,
      });

      /** PACKING_INFO_LIST의 packingDetailList 되돌리기 */
      setPackingInfoList((prevPackingInfoList) => [
        ...prevPackingInfoList.slice(0, -1),
        {
          ...lastPackingInfo,
          packingDetailList: lastPackingDetailList.filter(
            (packingDetailItem) => packingDetailItem.skuId !== skuIdToDelete
          ),
        },
      ]);

      setRowInfoToHighlight(undefined);
      setSkuToDelete(undefined);

      // 개별 삭제 후 포장이 한 개인 경우를 제외하고, 해당 포장 탭이 비게 되면 포장 탭을 삭제
      const hasNotOnlyOnePacking = packingInfoList.length > 1;
      const isEmptyPackingDetailListAfterDeletingPackedSKU =
        lastPackingInfo.packingDetailList.length - 1 === 0;
      if (
        hasNotOnlyOnePacking &&
        isEmptyPackingDetailListAfterDeletingPackedSKU
      ) {
        deleteLastPacking();
      }
    },
    [
      deleteLastPacking,
      packingInfoList,
      setInvoiceDetailList,
      setPackingInfoList,
      setRowInfoToHighlight,
    ]
  );

  const DeletePackingSkuModal = useMemo(
    () => (
      <Modal
        barcodeValues={{
          actionPositive: "O_DEL_PAD_SKU",
          actionNegative: "X_DEL_PAD_SKU",
        }}
        uiType="content"
        title={
          <>
            {getFormattedSKUId(skuToDelete?.skuId)}(SKU ID)
            <br />
            {skuToDelete?.itemName}
          </>
        }
        body="검수내역이 모두 삭제됩니다. 삭제하시겠습니까?"
        active={!!skuToDelete?.skuId}
        actionPositive={{
          label: "네",
          handleClick: () => handlePackedSkuDelete(skuToDelete?.skuId),
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setSkuToDelete(undefined),
        }}
      />
    ),
    [handlePackedSkuDelete, skuToDelete?.itemName, skuToDelete?.skuId]
  );

  useScanActionBarcode({
    actionBarcode: "O_DEL_PAD_SKU",
    actionFn: () => handlePackedSkuDelete(skuToDelete?.skuId),
  });
  useScanActionBarcode({
    actionBarcode: "X_DEL_PAD_SKU",
    actionFn: () => setSkuToDelete(undefined),
  });
  useScanActionBarcode({
    actionBarcode: "H_DEL_LAST_PAC",
    actionFn: resetDeleteLastPacking,
  });

  return {
    isEmptyPackingDetailList,
    isVisibleScrollbarForPackingDetailTable,

    tabFilterListOfPackingDetail,
    filterForPackingDetail,
    handlePackingDetailTabFilterChange,

    isSelectedTabFilter: filterForPackingDetail === String(lastPackingNo),

    packingDetailTableDataList,

    CountForScanningModal,

    DeletePackingSkuModal,

    ResponseHandlerOfDeletingLastPacking,
  };
}
