import React, { memo } from "react";

import Button from "@sellernote/_shared/src/components/button/Button";
import InputText from "@sellernote/_shared/src/components/input/InputText";
import Modal from "@sellernote/_shared/src/components/Modal";

import useInvoiceDirectInput from "./useInvoiceDirectInput";
import Styled from "./index.styles";

function InvoiceDirectInput() {
  const {
    isActiveInvoiceDirectInputButton,

    isVisibleInvoiceDirectInputModal,

    invoiceNo,
    setInvoiceNo,

    handleDirectInputModalOpen,
    handleDirectInputModalClose,
    handleDirectInputModalConfirm,

    ResponseHandlerOfStartingPacking,
  } = useInvoiceDirectInput();

  return (
    <>
      <Button
        className="direct-input-button"
        theme="secondary"
        size="small"
        label="송장 직접입력"
        handleClick={handleDirectInputModalOpen}
        disabled={!isActiveInvoiceDirectInputButton}
      />

      <Modal
        uiType="contentWithCustomBody"
        active={isVisibleInvoiceDirectInputModal}
        title="송장 직접입력"
        body={
          <Styled.invoiceDirectInputModalBody>
            <InputText
              label="송장번호"
              placeholder="입력해주세요."
              borderType="outline"
              valueType="string"
              value={invoiceNo}
              setValue={setInvoiceNo}
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              handleClick={handleDirectInputModalConfirm}
              disabled={!invoiceNo}
            />
          </Styled.invoiceDirectInputModalBody>
        }
        onClose={handleDirectInputModalClose}
      />

      {ResponseHandlerOfStartingPacking}
    </>
  );
}

export default memo(InvoiceDirectInput);
