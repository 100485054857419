import { useRecoilValue } from "recoil";

import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";

import useCheckHasOutSidePackage from "hooks/packing/useCheckHasOutSidePackage";
import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useNextPacking() {
  const isActiveNextPackingButton = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_ACTIVE_NEXT_PACKING_BUTTON
  );
  const { shippingId, deliveryType } = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );

  const {
    mutate: addNextPacking,
    reset: resetAddNextPacking,
    ResponseHandler: ResponseHandlerOfAddingNextPacking,
  } = PACKING_QUERY.useAddNextPacking();

  const { checkHasOutSidePackage, CheckHasOutSidePackageModal } =
    useCheckHasOutSidePackage({
      onSuccess: () => addNextPacking({ shippingId, deliveryType }),
    });

  useScanActionBarcode({
    actionBarcode: "NEXT_PAC",
    actionFn: checkHasOutSidePackage,
  });
  useScanActionBarcode({
    actionBarcode: "H_ADD_NEXT_PAC",
    actionFn: resetAddNextPacking,
  });

  return {
    isActiveNextPackingButton,
    checkHasOutSidePackage,

    CheckHasOutSidePackageModal,

    ResponseHandlerOfAddingNextPacking,
  };
}
