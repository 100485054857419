import React, { memo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import useClock from "./useClock";
import Styled from "./index.styles";

function WorkingStatus() {
  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

  return (
    <Styled.workingStatus>
      {isWorking ? "작업 중" : "작업 대기"}
    </Styled.workingStatus>
  );
}

function WorkingTableAndClock() {
  const workingTableName = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.deviceName;

  return (
    <Styled.workingTableAndClock className="right-container">
      <strong>{`${workingTableName} 작업대`}</strong>
      <span className="separator">|</span>
      <Clock />
    </Styled.workingTableAndClock>
  );
}

function Clock() {
  const currentTime = useClock();

  return <Styled.clock>{currentTime}</Styled.clock>;
}

function StatusBar() {
  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

  return (
    <Styled.statusBar isWorking={isWorking}>
      <WorkingStatus />
      <WorkingTableAndClock />
    </Styled.statusBar>
  );
}

export default memo(StatusBar);
