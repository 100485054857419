import {
  BofulAuthority,
  BofulWorker,
} from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

/** 매니저일 경우 '이름', 파트타이머일 경우 '계정명'으로 표시 */
function getWorkerNameByAuthority(worker?: BofulWorker) {
  if (!worker) return "";

  return worker.authority === "manager"
    ? worker.name ?? ""
    : worker.accountId ?? "";
}

/** 작업자 리스트에서 담당자명 가져오기 */
function getWorkerNameById(workerList: BofulWorker[], workerId?: number) {
  if (!workerId) return "";

  const worker = workerList.find((worker) => worker.id === workerId);

  if (!worker) {
    return "";
  }
  return getWorkerNameByAuthority(worker);
}

/** 위치 리스트에서 위치명 가져오기 */
function getLocationBarcodeById({
  locationList,
  locationId,
}: {
  locationList: BofulLocation[];
  locationId: number | undefined;
}) {
  return locationList.find((v) => v.id === locationId)?.barCode ?? "";
}

const AuthorityLabelDict: { [key in BofulAuthority]: string } & {
  "": string;
} = {
  admin: "관리자",
  manager: "매니저",
  partTimer: "파트타이머",
  whMaster: "창고 마스터",
  "": "-",
};

/**
 * sku 아이템 종류의 수를 반환
 */
function getSKUItemTypeQuantity(items?: { skuId: number }[]) {
  if (!items) return 0;

  const skuIdSet = new Set(items.map((item) => item.skuId));

  return skuIdSet.size;
}

export {
  AuthorityLabelDict,
  getSKUItemTypeQuantity,
  getWorkerNameByAuthority,
  getLocationBarcodeById,
  getWorkerNameById,
};
