import React, { useMemo } from "react";

import Icon from "../../Icon";
import { IconType } from "../../Icon/DATA";
import Styled from "./index.styles";

export type ButtonTheme =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "dangerStroke"
  | "scan";

/**
 * 디자인 시스템의 'Text'라는 유형의 버튼은 TextButton 컴포넌트를 사용한다.
 */
export type ButtonSize =
  | "normal"
  | "small"
  | "smallMultiRow"
  | "xLarge"
  | "block"
  | "input";

export type ButtonIconInfoPosition = "left" | "right";

export interface Props {
  theme: ButtonTheme;
  size: ButtonSize;
  label: React.ReactNode;
  handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  icon?: {
    position: ButtonIconInfoPosition;
    type: IconType;
  };
  className?: string;
}

// TODO: 아이콘이 있을때 & 남은 Theme(input, text)
export default function Button({
  theme,
  size,
  label,
  handleClick,
  disabled,
  icon,
  className,
}: Props) {
  const iconSize = useMemo(getIconSize, [size]);

  function getIconSize() {
    switch (size) {
      case "small":
      case "smallMultiRow": {
        return 1;
      }
      default: {
        return 1.5;
      }
    }
  }

  return (
    <Styled.container
      className={`${className ? className : ""} button`}
      theme={theme}
      size={size}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled) {
          handleClick(e);
        }
      }}
      iconPosition={icon?.position}
    >
      {icon?.position === "left" && <Icon type={icon.type} size={iconSize} />}

      <div className="label">{label}</div>

      {icon?.position === "right" && <Icon type={icon.type} size={iconSize} />}
    </Styled.container>
  );
}
