import React from "react";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";

import withRequireAuth from "hocs/withRequireAuth";

import CompletePacking from "./CompletePacking";
import DeleteLastPacking from "./DeleteLastPacking";
import InvoiceDetail from "./InvoiceDetail";
import InvoiceDirectInput from "./InvoiceDirectInput";
import InvoiceSummary from "./InvoiceSummary";
import MainMenu from "./MainMenu";
import NextPacking from "./NextPacking";
import OutSidePackage from "./OutSidePackage";
import PackingDetail from "./PackingDetail";
import PrintInvoice from "./PrintInvoice";
import PrintPackingLabel from "./PrintPackingLabel";
import ReportProblem from "./ReportProblem";
import RequestMemo from "./RequestMemo";
import StatusBar from "./StatusBar";
import useCanceledInvoice from "./useCanceledInvoice";
import useFetchOutSidePackageOfShipda from "./useFetchOutSidePackageOfShipda";
import usePacking from "./usePacking";
import useScanCompletedInvoice from "./useScanCompletedInvoice";
import VideoRecording from "./VideoRecording";
import Styled from "./index.styles";

function InvoiceInfo({
  rowInfoToHighlight,
}: {
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
}) {
  return (
    <Styled.invoiceInfoContainer>
      <div className="summary">
        <InvoiceDirectInput />
        <InvoiceSummary />
        <OutSidePackage />
        <NextPacking />
      </div>

      <div className="detail">
        <InvoiceDetail rowInfoToHighlight={rowInfoToHighlight} />
      </div>
    </Styled.invoiceInfoContainer>
  );
}

function Packing() {
  const { ResponseHandlerOfGettingOutSidePackageListOfShipda } =
    useFetchOutSidePackageOfShipda();

  const {
    rowInfoToHighlight,
    setRowInfoToHighlight,

    ErrorMessageOfScanningSkuModal,

    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResultHandlerOfSelectingDuplicateBarcode,
  } = usePacking();

  const { CanceledInvoiceModal } = useCanceledInvoice();

  const { CompletedInvoiceModal } = useScanCompletedInvoice();

  return (
    <Styled.container>
      <MainMenu />

      <Styled.rightSideContainer>
        <StatusBar />

        <Styled.mainContainer>
          <div className="header">
            <div className="title">패킹</div>
            <PrintPackingLabel />
          </div>

          <div className="body">
            <div className="info">
              <InvoiceInfo rowInfoToHighlight={rowInfoToHighlight} />

              <PackingDetail
                rowInfoToHighlight={rowInfoToHighlight}
                setRowInfoToHighlight={setRowInfoToHighlight}
              />
            </div>

            <div className="action">
              <VideoRecording />
              <ReportProblem setRowInfoToHighlight={setRowInfoToHighlight} />
              <PrintInvoice />
              <DeleteLastPacking
                setRowInfoToHighlight={setRowInfoToHighlight}
              />
              <CompletePacking setRowInfoToHighlight={setRowInfoToHighlight} />
              <RequestMemo />
            </div>
          </div>
        </Styled.mainContainer>
      </Styled.rightSideContainer>

      {ErrorMessageOfScanningSkuModal}
      {CanceledInvoiceModal}
      {CompletedInvoiceModal}

      {ResponseHandlerOfGettingOutSidePackageListOfShipda}
      {ResponseHandlerOfStartingPacking}
      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResultHandlerOfSelectingDuplicateBarcode}
    </Styled.container>
  );
}

export default withRequireAuth(Packing);
