import React, { useState } from "react";

import { useDeviceDetect } from "@sellernote/_shared/src/utils/common/hook";

import { COLOR } from "../../../styles/constants";
import { noop } from "../../../utils/common/etc";

import Icon from "../../Icon";
import Styled from "./index.styles";

export interface InputSelectOption<T> {
  label: React.ReactNode;
  desc?: React.ReactNode;
  value: T;
  disabled?: boolean;
}

export type InputSelectUiType = "filled" | "outline";

export default function InputSelect<T>({
  uiType,
  optionList,
  label,
  selectedOption,
  handleSelect,
  placeholder = "선택해주세요.",
  disabled,
  note,
  warningMessage,
  width,
  errorMessage,
  isRequired,
  className,
}: {
  uiType: InputSelectUiType;
  optionList: InputSelectOption<T>[];
  selectedOption?: InputSelectOption<T>;
  handleSelect: (v: InputSelectOption<T>) => void;
  label?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  note?: React.ReactNode;
  warningMessage?: string;
  /** -px or -% */
  width?: string; // --px or --%
  errorMessage?: React.ReactNode;
  isRequired?: boolean;
  className?: string;
}) {
  const { isMobile } = useDeviceDetect();

  const [isOpen, setIsOpen] = useState(false);

  function handleSelectOption(v: InputSelectOption<T>) {
    handleSelect(v);
    setIsOpen(false);
  }

  return (
    <Styled.container
      className={`${className ? className : ""} ${
        disabled ? "disabled" : ""
      } input-select`}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      {...(isMobile
        ? {
            onPointerLeaveCapture: () => {
              setIsOpen(false);
            },
          }
        : {})}
      width={width}
    >
      {label && (
        <div className="label">
          {label}
          {isRequired && <em> (*필수)</em>}
        </div>
      )}

      <Styled.selected
        onClick={() => {
          if (!disabled) {
            setIsOpen(true);
          }
        }}
        className={`${isOpen ? "opened" : ""} ${disabled ? "disabled" : ""}`}
        uiType={uiType}
      >
        <div className={`value ${selectedOption ? "has-value" : ""}`}>
          {selectedOption?.label || placeholder}
        </div>

        {errorMessage ? (
          <Icon type="warning" size={1} color={COLOR.pointWarning} />
        ) : (
          <Icon
            type={isOpen ? "caretUp" : "caretDown"}
            size={1}
            color={isOpen ? COLOR.primaryBlue : COLOR.grayScale_400}
          />
        )}
      </Styled.selected>

      {(warningMessage || errorMessage) && (
        <div className="warning-message-wrapper">
          <div className="warning-message">
            {errorMessage || warningMessage}
          </div>
        </div>
      )}

      <div className="option-list-wrapper">
        {isOpen && (
          <Styled.optionList>
            {optionList.map((v, i) => {
              return (
                <div
                  className={`${v.disabled ? "disabled" : ""} item`}
                  key={i}
                  onClick={v.disabled ? noop : () => handleSelectOption(v)}
                >
                  <div className="label">{v.label}</div>

                  {v.desc && <div className="desc">{v.desc}</div>}
                </div>
              );
            })}
          </Styled.optionList>
        )}
      </div>

      {note && <div className="note-wrapper">{note}</div>}
    </Styled.container>
  );
}
