import React, { useEffect, useState } from "react";
import { useCallback } from "react";

import Icon from "../Icon";
import Styled from "./index.styles";

export type StatusType = "success" | "failure";

export default function Toast({
  message,
  status,
  reset,
  needIcon,
  className,
}: {
  message: React.ReactNode;
  status: StatusType;
  reset: () => void;
  needIcon?: boolean;
  className?: string;
}) {
  const [isVisibleToast, setIsVisibleToast] = useState(true);

  const disappearToast = useCallback(() => {
    setTimeout(() => {
      setIsVisibleToast(false);

      reset();
    }, 2000);
  }, [reset]);

  useEffect(() => {
    disappearToast();
  }, [disappearToast]);

  if (!isVisibleToast) return null;

  return (
    <Styled.container
      {...{ status }}
      className={`${className ? className : ""} toast`}
    >
      <div className="toast-block">
        {needIcon && (
          <Icon
            type={
              status === "success"
                ? "checkFilledCircleChecked"
                : "circleFilledError"
            }
            size={1}
            color="#fff"
          />
        )}
        <span className="message">{message}</span>
      </div>
    </Styled.container>
  );
}
