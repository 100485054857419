import React, { memo } from "react";

import TextButton from "@sellernote/_shared/src/components/button/TextButton";

import useMainMenu from "./useMainMenu";
import Styled from "./index.styles";

function MainMenu() {
  const { workerName, handleLogoutClick } = useMainMenu();

  return (
    <Styled.container>
      <img src="/images/logo_boful_web.svg" alt="logo" />

      <div className="name">
        <strong>{workerName}</strong>님
      </div>

      <div className="menu-item">패킹</div>

      <TextButton
        label="로그아웃"
        theme="danger"
        size="14px"
        icon="arrowRight"
        handleClick={handleLogoutClick}
      />
    </Styled.container>
  );
}

export default memo(MainMenu);
