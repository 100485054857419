import { detect } from "detect-browser";

import {
  SellernoteAppName,
  SellernoteAppType,
  SellernoteLocale,
} from "./types/common/common";

const browser = detect();

export const SHIPDA_API_URL = "https://api-v2-dev.ship-da.com";
export const SHIPDA_ADMIN_API_URL = "https://admin-api-dev.ship-da.com";
export const BOFUL_API_URL = "https://boful-api-dev.ship-da.com";
export const CONTENTS_API_URL = "https://contents-api-dev.ship-da.com";
export const LOCAL_PRINTER_URL = "http://localhost:1457";

export const ARTIFICIAL_DELAY_MS = 500;

/**
 * 보통은 dev환경일때 process.env.NODE_ENV을 체크하는 것으로 하지만,
 * dev도 배포환경에서는 process.env.NODE_ENV가 production으로 나오므로 (production빌드 결과가 실행되는 것이므로)
 * 혼돈을 방지하고자 이런 상수를 만듦
 */
export const IS_UNDER_LOCAL_DEVELOPMENT = process.env.NODE_ENV !== "production";

/**
 * Production 배포 환경인지 확인
 */
export const IS_UNDER_PRODUCTION = (() => {
  return (
    process.env.REACT_APP_ENV === "production" ||
    process.env.NEXT_PUBLIC_ENV === "production"
  );
})();

export const APP_ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;

export const IS_ON_BROWSER = typeof window !== "undefined";

/**
 * 셀러노트 앱의 고유 이름
 * (환경변수를 통해 계산됨)
 */
export const APP_NAME = (() => getAppNameFromEnvVar())();

/**
 * 셀러노트 앱을 구분하는 카테고리
 * (환경변수를 통해 계산됨)
 */
export const APP_TYPE = (() => getAppTypeFromAppName(APP_NAME))();

/**
 * 앱의 LOCALE
 * (환경변수를 통해 계산됨)
 */
export const APP_LOCALE = (() => getAppLocaleFromAppName(APP_NAME))();

/**
 * 앱 담당자
 */
export const APP_RESPONSIBILITY: FE_DEVELOPER[] = (() =>
  getAppResponsibility())();

export type FE_DEVELOPER = "변형준" | "조형근" | "송승은" | "박문하";

function getAppResponsibility(): FE_DEVELOPER[] {
  switch (APP_NAME) {
    case "boful-worker-web":
    case "boful-worker-pda": {
      return ["박문하", "변형준"];
    }
    case "boful-admin": {
      return ["변형준", "박문하"];
    }

    case "shipda-admin": {
      return ["조형근", "변형준"];
    }

    case "partner-admin": {
      return ["조형근", "변형준"];
    }

    case "bringoodz-web":
    case "shipda-web": {
      return ["변형준", "송승은"];
    }

    case "content-admin": {
      return ["조형근", "변형준"];
    }

    case "design": {
      return ["변형준", "박문하"];
    }

    default: {
      return ["변형준"];
    }
  }
}

function getAppNameFromEnvVar(): SellernoteAppName {
  const appName =
    process.env.REACT_APP_APP_NAME || process.env.NEXT_PUBLIC_APP_NAME;

  if (!appName) {
    throw new Error("cannot found *_APP_NAME from ENV");
  }

  return appName as SellernoteAppName;
}

/**
 * 셀러노트 앱을 구분하는 카테고리로서의 AppType를 가져옴
 */
function getAppTypeFromAppName(appName: SellernoteAppName): SellernoteAppType {
  switch (appName) {
    case "boful-worker-web":
    case "boful-admin": {
      return "Boful";
    }

    case "boful-worker-pda": {
      return "BofulMobile";
    }

    case "partner-admin":
    case "shipda-admin":
    case "bringoodz-web":
    case "shipda-web": {
      return "ShipDa";
    }

    case "content-admin": {
      return "ContentAdmin";
    }

    case "design": {
      return "Design";
    }

    case "jest-testing": {
      return "JestTesting";
    }

    default: {
      throw new Error("invalid APP_NAME");
    }
  }
}

/**
 * 셀러노트 앱을 구분하는 카테고리로서의 AppType를 가져옴
 */
function getAppLocaleFromAppName(appName: SellernoteAppName): SellernoteLocale {
  switch (appName) {
    case "content-admin":
    case "boful-worker-pda":
    case "boful-worker-web":
    case "shipda-admin":
    case "partner-admin":
    case "boful-admin": {
      return "KR";
    }

    case "jest-testing":
    case "bringoodz-web":
    case "shipda-web": {
      return process.env.NEXT_PUBLIC_LOCALE as SellernoteLocale;
    }

    default: {
      throw new Error("invalid APP_NAME");
    }
  }
}

export const IS_READY_FOR_MSW_TOOL =
  IS_ON_BROWSER &&
  (browser?.name === "chrome" || browser?.name === "firefox") &&
  IS_UNDER_LOCAL_DEVELOPMENT;
