import React, { memo } from "react";

import Button from "@sellernote/_shared/src/components/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";

import useCompleteReportProblem from "./useCompleteReportProblem";

function CompleteButton({
  setRowInfoToHighlight,
  handleReportProblemModalClose,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  handleReportProblemModalClose: () => void;
}) {
  const {
    checkIsCanceledInvoice,
    isActiveCompleteButton,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfReportingProblem,
  } = useCompleteReportProblem({
    setRowInfoToHighlight,
    handleReportProblemModalClose,
  });

  return (
    <>
      <Button
        theme="primary"
        size="normal"
        label="확인"
        handleClick={checkIsCanceledInvoice}
        disabled={!isActiveCompleteButton}
      />

      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfReportingProblem}
    </>
  );
}

export default memo(CompleteButton);
