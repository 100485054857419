import React, { memo } from "react";

import Modal from "@sellernote/_shared/src/components/Modal";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";

import CompleteButton from "./CompleteButton";
import ProblemListTable from "./ProblemListTable";
import Styled from "./index.styles";

function ReportProblemModal({
  setRowInfoToHighlight,
  handleReportProblemModalClose,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  handleReportProblemModalClose: () => void;
}) {
  return (
    <Styled.reportProblemModal>
      <Modal
        uiType="contentWithCustomBody"
        active={true}
        title="문제가 발생한 상품(들)을 선택해주세요."
        body={
          <>
            <ProblemListTable />
            <CompleteButton
              setRowInfoToHighlight={setRowInfoToHighlight}
              handleReportProblemModalClose={handleReportProblemModalClose}
            />
          </>
        }
        onClose={handleReportProblemModalClose}
      />
    </Styled.reportProblemModal>
  );
}

export default memo(ReportProblemModal);
