import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  FULFILLMENT_AUTH_ATOMS,
  FULFILLMENT_AUTH_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/auth";
import { getWorkerNameByAuthority } from "@sellernote/_shared/src/utils/fulfillment/common";

export default function useMainMenu() {
  const setLoggedIn = useSetRecoilState(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const handleLogoutClick = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/";
  }, [setLoggedIn]);

  return {
    workerName: getWorkerNameByAuthority(currentUser),

    handleLogoutClick,
  };
}
