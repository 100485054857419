import { MutableRefObject } from "react";
import { SetupWorkerApi } from "msw";

import {
  BOFUL_API_URL,
  CONTENTS_API_URL,
  LOCAL_PRINTER_URL,
  SHIPDA_ADMIN_API_URL,
  SHIPDA_API_URL,
} from "../constants";
import bofulHandlers from "../mocks/boful-api";
import contentHandlers from "../mocks/content-api";
import localPrinterHandlers from "../mocks/local-printer";
import { Handler } from "../mocks/MSWTool/useHandlerData";
import shipdaAdminHandlers from "../mocks/shipda-admin-api";
import shipdaHandlers from "../mocks/shipda-api";

function getBofulDevAPI(path: string) {
  return new URL(path, BOFUL_API_URL).toString();
}

function getShipdaDevAPI(path: string) {
  return new URL(path, SHIPDA_API_URL).toString();
}

function getShipdaAdminDevAPI(path: string) {
  return new URL(path, SHIPDA_ADMIN_API_URL).toString();
}

function getContentsDevAPI(path: string) {
  return new URL(path, CONTENTS_API_URL).toString();
}

function getLocalPrinterDevAPI(path: string) {
  return LOCAL_PRINTER_URL;
}

function getActiveHandlers(handlers: Handler[]) {
  return handlers.filter((item) => item.active === true);
}

function getHandlersToReset(localStorageData: Handler[]) {
  const activeHandlers = getActiveHandlers(localStorageData);

  const handlers = activeHandlers.map((handlerItem) => {
    switch (handlerItem.type) {
      case "shipda":
        return shipdaHandlers[handlerItem.handler];
      case "boful":
        return bofulHandlers[handlerItem.handler];
      case "content":
        return contentHandlers[handlerItem.handler];
      case "localPrinter":
        return localPrinterHandlers[handlerItem.handler];
      case "shipdaAdmin":
        return shipdaAdminHandlers[handlerItem.handler];
    }
  });

  return handlers;
}

function printMessageWithActiveHandlers(
  workerRef: MutableRefObject<SetupWorkerApi | undefined>,
  message: string
) {
  console.log(`%c@@@ ${message}`, "color: #F08080");

  try {
    workerRef.current?.printHandlers();
  } catch (e) {
    console.warn(e);
  }
}

export {
  getBofulDevAPI,
  getShipdaDevAPI,
  getContentsDevAPI,
  getLocalPrinterDevAPI,
  getHandlersToReset,
  printMessageWithActiveHandlers,
  getShipdaAdminDevAPI,
};
