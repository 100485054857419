// date 관련
// 매년 같은 공휴일
export const PUBLIC_HOLIDAY_LIST = [
  "01/01",
  "03/01",
  "05/01",
  "05/05",
  "06/06",
  "08/15",
  "10/03",
  "10/09",
  "12/25",
];

// 음력 공휴일, 음력 명절
export const LUNAR_PUBLIC_HOLIDAY_LIST = [
  "2022/05/08",
  "2022/01/31",
  "2022/02/01",
  "2022/02/02",
  "2022/06/01", // 지방선거
  "2022/09/09",
  "2022/09/10",
  "2022/09/11",
  "2023/05/27",
  "2023/01/21",
  "2023/01/22",
  "2023/01/23",
  "2023/09/28",
  "2023/09/29",
  "2023/09/30",
];

// 대체 공휴일
export const SUBSTITUTE_HOLIDAY_LIST = [
  "2022/09/12",
  "2022/10/10",
  "2023/01/24",
];

export const TODAY = new Date();

export const TOMORROW = (() => {
  const tempDate = new Date();

  tempDate.setDate(TODAY.getDate() + 1);

  return tempDate;
})();
