import styled, { css } from "styled-components";

import { COLOR } from "../../styles/constants";
import { fontStyle } from "../../styles/functions";

import { ToolTipDisplayType, ToolTipPosition, TriggerTargetInfo } from ".";

interface ContainerProps {
  displayType: ToolTipDisplayType;
}
const container = styled.div`
  position: relative;
  display: ${(props: ContainerProps) => props.displayType};

  > .trigger {
    cursor: pointer;
  }
`;

const bodyForDesktop = styled.div<{
  position: ToolTipPosition;
  triggerTargetInfo: TriggerTargetInfo;
  type: "titleOnly" | "descOnly" | "titleAndDesc";
  width?: number;
}>`
  position: absolute;
  border-radius: 4px;
  background-color: ${COLOR.white};
  box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  z-index: 10;
  width: ${(props) => (props.width ? `${props.width}rem` : "100%")};
  text-align: left;

  // 이하 triggerTargetInfo 값이 px로 밖에 계산이 안되어 rem이 아닌  px단위를 사용
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: ${COLOR.white};
    box-shadow: 1px -19px 30px -19px rgba(0, 0, 0, 0.05);
    z-index: -1;
  }

  ${(props) => {
    switch (props.position) {
      case "right": {
        return css`
          left: ${props.triggerTargetInfo.width + 10}px;
          top: -${props.triggerTargetInfo.height / 2}px;

          &::before {
            content: "";
            top: 13px;
            left: -4px;
          }
        `;
      }
      case "left": {
        return css`
          right: calc(100% + 10px);
          top: -${props.triggerTargetInfo.height / 2}px;

          &::before {
            content: "";
            top: 13px;
            right: -4px;
          }
        `;
      }
      case "bottomLeft": {
        return css`
          right: calc(100% - 30px);
          top: ${props.triggerTargetInfo.height + 10}px;

          &::before {
            content: "";
            top: -4px;
            right: 12px;
          }
        `;
      }
      case "bottomRight": {
        return css`
          left: -10px;
          top: ${props.triggerTargetInfo.height + 10}px;

          &::before {
            content: "";
            top: -4px;
            left: 12px;
          }
        `;
      }
      case "topRight": {
        return css`
          left: -10px;
          bottom: ${props.triggerTargetInfo.height + 10}px;

          &::before {
            content: "";
            bottom: -4px;
            left: 12px;
          }
        `;
      }
    }
  }}

  > .title {
    padding: 0.25rem 1rem;
    ${fontStyle("subHead3")};
    color: ${COLOR.grayScale_800};
  }

  > .desc {
    padding: 0.5rem 1rem;
    ${fontStyle("body3")};
    color: ${COLOR.grayScale_700};
    border-top: ${({ type }) =>
      type === "descOnly" ? "0px" : `1px solid ${COLOR.grayScale_500}`};
  }
`;

const bodyForMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.bk_40};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  cursor: default;

  > .body-container {
    display: inline-block;
    position: relative;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: ${COLOR.white};
    padding: 1.5rem 1rem 1rem;
    width: 90%;
    text-align: left;

    > .icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
    }

    > .content {
      > .item {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        > .title {
          ${fontStyle("subHead4")};
          color: ${COLOR.grayScale_700};
        }

        > .desc {
          ${fontStyle("caption")};
          color: ${COLOR.grayScale_700};
        }
      }
    }
  }
`;

export default {
  container,
  bodyForDesktop,
  bodyForMobile,
};
