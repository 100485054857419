import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";

import Modal from "@sellernote/_shared/src/components/Modal";
import { FULFILLMENT_PACKING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/packing";

import useScanActionBarcode from "./useScanActionBarcode";

export default function useCheckHasOutSidePackage({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const hasSelectedOutSidePackage = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.HAS_SELECTED_OUT_SIDE_PACKAGE
  );

  const [isVisibleHasOutSidePackageModal, setIsVisibleHasOutSidePackageModal] =
    useState(false);

  const checkHasOutSidePackage = useCallback(() => {
    if (!hasSelectedOutSidePackage) {
      setIsVisibleHasOutSidePackageModal(true);
      return;
    }

    onSuccess();
  }, [hasSelectedOutSidePackage, onSuccess]);

  useScanActionBarcode({
    actionBarcode: "O_SEL_OUT",
    actionFn: () => setIsVisibleHasOutSidePackageModal(false),
  });

  const CheckHasOutSidePackageModal = (
    <Modal
      barcodeValues={{
        actionPositive: "O_SEL_OUT",
      }}
      active={isVisibleHasOutSidePackageModal}
      uiType="titleOnly"
      title="외포장재가 선택되지 않았습니다."
      actionPositive={{
        label: "확인",
        handleClick: () => setIsVisibleHasOutSidePackageModal(false),
      }}
    />
  );

  return {
    checkHasOutSidePackage,
    CheckHasOutSidePackageModal,
  };
}
