import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  CONFIRM_OAUTH_CAFE24_RESULT,
  GET_BUYER_INFO,
  GET_LINKED_SHIPPING_MALL_LIST_TEST,
  GET_SELLER_ORDER_LIST,
  GET_SELLER_SKU_LIST,
} from "./data";

export const requestSellerCredential = rest.post(
  getBofulDevAPI("/seller-credential"),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json({}));
  }
);

export const getSellerSKUList = rest.get(
  getBofulDevAPI("/seller-sku"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_SELLER_SKU_LIST)
    );
  }
);

export const updateSellerSKUList = rest.post(
  getBofulDevAPI("/seller-sku/update"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        result: "ok",
      })
    );
  }
);

export const linkSellerSKU = rest.patch(
  getBofulDevAPI("/seller-sku/link"),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json({}));
  }
);

export const unLinkSellerSKU = rest.patch(
  getBofulDevAPI("/seller-sku/unlink/:id"),
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json({}));
  }
);

export const getSellerOrderList = rest.get(
  getBofulDevAPI("/seller-order"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_SELLER_ORDER_LIST)
    );
  }
);

export const getBuyerInfo = rest.get(
  getBofulDevAPI("/seller-order/buyer/:id"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_BUYER_INFO)
    );
  }
);

export const setDueDate = rest.patch(
  getBofulDevAPI("/seller-order/hopeDate"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        result: "ok",
      })
    );
  }
);

export const getLinkShoppingMallList = rest.get(
  getBofulDevAPI("/fulfillment/:userId"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_LINKED_SHIPPING_MALL_LIST_TEST)
    );
  }
);

export const getConfirmOAuthCafe24 = rest.post(
  getBofulDevAPI("/seller-credential/integration/cafe24"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(CONFIRM_OAUTH_CAFE24_RESULT)
    );
  }
);
