import styled, { css } from "styled-components";

import { COLOR } from "../../../styles/constants";
import { mobile } from "../../../styles/functions";
import { setFontStyle } from "../../../styles/typography";

import { NoBorderBottomForTd } from ".";

const container = styled.div<{
  isOverflowed: boolean;
  tableWidth?: string;
  columnGroupSizes?: number[];
}>`
  width: 100%;
  background-color: ${COLOR.white};
  position: relative;

  table {
    ${({ isOverflowed, tableWidth }) => {
      if (isOverflowed) {
        return css`
          width: ${tableWidth || "100%"};

          ${mobile(css`
            width: calc(100vw - 20px);
          `)}

          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          overflow-x: auto;
        `;
      } else {
        return css`
          width: 100%;
        `;
      }
    }}
  }

  > .curtain {
    position: absolute;
    top: 0;
    height: 100%;
    width: 32px;

    &.right {
      right: 0;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &.left {
      left: 0;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }

  ${({ columnGroupSizes }) => {
    let styleLiteral = ``;
    let nthValue = 0;
    columnGroupSizes?.forEach((v) => {
      nthValue += v;
      styleLiteral += `
        tr th:nth-child(${nthValue}):not(:last-child),
        td:nth-child(${nthValue - 1}):not(:last-child) {
          border-right: 1px solid ${COLOR.grayScale_100};
        }
      `;
    });

    return styleLiteral;
  }}
`;

const TableTr = styled.tr<{
  rowFontColor?: string;
  rowMinHeight?: string;
  rowBackgroundColor?: string;
  isHighlighted?: boolean;
  disabledRowHoverBgColor?: boolean;
}>`
  color: ${({ rowFontColor }) => rowFontColor || COLOR.grayScale_700};
  background-color: ${COLOR.white};

  ${({ rowMinHeight }) => {
    if (rowMinHeight) {
      return css`
        min-height: ${rowMinHeight};
      `;
    }
  }}

  ${setFontStyle("Body7")};
  display: flex;
  width: 100%;
  flex-shrink: 0;

  &.blink {
    td {
      animation-duration: 1s;
      animation-name: blink;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    color: ${COLOR.grayScale_400};
  }

  &:hover.disabled {
    td {
      background-color: ${COLOR.white};
      cursor: default;
    }
  }

  &:hover {
    td {
      background-color: ${({ disabledRowHoverBgColor, rowBackgroundColor }) =>
        disabledRowHoverBgColor
          ? rowBackgroundColor || "transparent"
          : COLOR.primaryBlue_10};
    }
  }

  td {
    ${({ rowBackgroundColor }) =>
      rowBackgroundColor &&
      css`
        background-color: ${rowBackgroundColor};
      `};

    ${({ isHighlighted }) => {
      if (isHighlighted) {
        return css`
          background-color: ${COLOR.primaryPurple_10};
        `;
      }
    }};
  }

  @keyframes blink {
    0% {
      background-color: ${COLOR.primaryPurple_10};
    }

    80% {
      background-color: ${COLOR.white};
    }

    100% {
      background-color: ${COLOR.primaryPurple_10};
    }
  }
`;

const TableThGroup = styled.th<{
  isOverflowed: boolean;
  portion?: number;
  maxWidth?: string;
  fixedWidth?: number;
}>`
  padding: 8px 0;
  background-color: ${COLOR.primaryBlue_60};
  color: ${COLOR.white};
  ${setFontStyle("SubHead4")};
  &:not(:last-child) {
    border-right: 1px solid ${COLOR.grayScale_100};
  }
  justify-content: center;

  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  word-break: break-word;
  cursor: default;
  ${({ isOverflowed, fixedWidth, maxWidth, portion }) => {
    if (isOverflowed) {
      if (fixedWidth) {
        return css`
          width: ${fixedWidth}px;
        `;
      } else {
        return css`
          width: 80px;
        `;
      }
    } else {
      return css`
        flex: ${portion || 1};

        ${maxWidth
          ? css`
              max-width: ${maxWidth};
            `
          : undefined}
      `;
    }
  }}
`;

const TableTh = styled.th<{
  isOverflowed: boolean;
  portion?: number;
  maxWidth?: string;
  fixedWidth?: number;
}>`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  word-break: break-word;
  padding: 16px 0 16px 16px;
  background-color: ${COLOR.grayScale_200};
  border-bottom: 1px solid ${COLOR.grayScale_400};
  text-align: left;
  color: ${COLOR.grayScale_700};
  ${setFontStyle("Body7", "Bold")};
  cursor: default;
  align-items: center;

  ${({ isOverflowed, fixedWidth, maxWidth, portion }) => {
    if (isOverflowed) {
      if (fixedWidth) {
        return css`
          width: ${fixedWidth}px;
        `;
      } else {
        return css`
          width: 80px;
        `;
      }
    } else {
      return css`
        flex: ${portion || 1};

        ${maxWidth
          ? css`
              max-width: ${maxWidth};
            `
          : undefined}
      `;
    }
  }}

  &:nth-last-of-type(2) {
    padding: 16px;
  }

  strong {
    display: contents;
  }
`;

const TableTd = styled.td<{
  isOverflowed: boolean;
  portion?: number;
  maxWidth?: string;
  fixedWidth?: number;
  noBorderBottom?: boolean;
  noBorderBottomForTd?: NoBorderBottomForTd;
  rowMinHeight?: string;
}>`
  display: flex;
  align-items: center;

  ${({ noBorderBottom }) => {
    if (!noBorderBottom) {
      return css`
        border-bottom: 1px solid ${COLOR.grayScale_200};
      `;
    }
  }}

  ${({ rowMinHeight }) => {
    if (rowMinHeight) {
      return css`
        min-height: ${rowMinHeight};
      `;
    }
  }}
  
  flex-wrap: wrap;
  flex-shrink: 0;
  word-break: break-word !important;
  -ms-word-break: break-all !important;
  padding: 16px 0 16px 16px;

  ${({ isOverflowed, fixedWidth, portion, maxWidth }) => {
    if (isOverflowed) {
      if (fixedWidth) {
        return css`
          width: ${fixedWidth}px;
        `;
      } else {
        return css`
          width: 80px;
        `;
      }
    } else {
      return css`
        flex: ${portion || 1};

        ${maxWidth
          ? css`
              max-width: ${maxWidth};
            `
          : undefined}
      `;
    }
  }}

  &:last-child {
    padding: 16px;
  }

  strong {
    display: contents;
  }

  > .input-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  > .text-button {
    height: 24px;
  }

  ${({ noBorderBottomForTd }) => {
    if (noBorderBottomForTd?.key && !noBorderBottomForTd.isLastRow) {
      return css`
        border-bottom: none;
      `;
    }
  }};
`;

export default {
  container,
  TableThGroup,
  TableTr,
  TableTh,
  TableTd,
};
