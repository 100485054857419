import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/styles/constants";

const nextPacking = styled.div<{ isActive: boolean }>`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .barcode {
    width: 100%;
    height: 56px;
  }

  > .next-packing-button {
    background-color: ${COLOR.primaryPurple_80};

    &:hover {
      background-color: ${COLOR.primaryPurple_60};
    }

    &:focus,
    &:active {
      background-color: ${COLOR.primaryPurple_40};
    }

    ${({ isActive }) =>
      !isActive &&
      css`
        color: ${COLOR.white};
        background-color: ${COLOR.grayScale_300};
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
          color: ${COLOR.white};
          background-color: ${COLOR.grayScale_300};
        }
      `}
  }
`;

export default {
  nextPacking,
};
