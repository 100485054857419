import React from "react";

import Styled from "./index.styles";

export type ChipShape = "rect" | "round";

export default function Chip({
  shape,
  label,
  selectableInfo,
  className,
}: {
  shape: ChipShape;
  label: string;
  selectableInfo?: {
    selected?: boolean;
    handleClick: () => void;
  };
  className?: string;
}) {
  return (
    <Styled.container
      shape={shape}
      className={`${className ? className : ""} chip ${
        selectableInfo?.selected ? "selected" : ""
      }`}
      onClick={selectableInfo && selectableInfo.handleClick}
      isSelectable={!!selectableInfo}
    >
      {label}
    </Styled.container>
  );
}
