import React from "react";

import Portal from "../../components/Portal";

import HandlerList from "./HandlerList";
import ToggleButtons from "./ToggleButtons";
import useChangeAllHandlers from "./useChangeAllHandlers";
import useHandlerData from "./useHandlerData";
import useHandlerList from "./useHandlerList";
import useMSWSetting from "./useSetting";
import useToggleTool from "./useToggleTool";
import Styled from "./index.styles";

export type ViewType = "web" | "PDA";

export default function MSWTool({ type }: { type: ViewType }) {
  const { workerRef } = useMSWSetting();

  const { handlerData, setHandlerData } = useHandlerData({ workerRef });

  const { mswAPIType, handlerTableList, handleMSWAPITypeChange } =
    useHandlerList({
      workerRef,
      handlerData,
      setHandlerData,
    });

  const { MSWToolRef, isOpenedTool, handleMSWToolToggle } = useToggleTool();

  const { mode, handleModeToggle, deactivateAllHandlers } =
    useChangeAllHandlers({
      workerRef,
      handlerData,
      setHandlerData,
    });

  return (
    <Portal selector="#msw-tool-portal">
      <Styled.MSWTool type={type} isOpenedTool={isOpenedTool} ref={MSWToolRef}>
        {isOpenedTool && (
          <HandlerList
            mswAPIType={mswAPIType}
            handleMSWAPITypeChange={handleMSWAPITypeChange}
            handlerTableList={handlerTableList}
          />
        )}

        <ToggleButtons
          isOpenedTool={isOpenedTool}
          mode={mode}
          handleModeToggle={handleModeToggle}
          type={type}
          handleMSWToolToggle={handleMSWToolToggle}
          deactivateAllHandlers={deactivateAllHandlers}
        />
      </Styled.MSWTool>
    </Portal>
  );
}
