import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "dayjs/locale/ko";
import "dayjs/locale/en-sg";
import "date-time-format-timezone";

import { APP_LOCALE } from "../../constants";
import {
  LUNAR_PUBLIC_HOLIDAY_LIST,
  PUBLIC_HOLIDAY_LIST,
  SUBSTITUTE_HOLIDAY_LIST,
} from "./constants";

dayjs.extend(utc);
dayjs.extend(timezone);

// 필요할때마다 더 추가해서 사용
export type TimeZone = "Asia/Seoul" | "Asia/Singapore";

APP_LOCALE === "KR" ? dayjs.locale("ko") : dayjs.locale("en-sg");

(function () {
  if (new Date().toLocaleString().charCodeAt(0) == 8206) {
    const original = Date.prototype.toLocaleString;
    Date.prototype.toLocaleString = function (...args) {
      return original.apply(this, args as any).replace(/\u200E/g, "");
    };
  }
})();

/**
 * @param {*} durationAsSecond 초로 환산된 duration
 */
function getExpiredAt(durationAsSecond: number) {
  if (!durationAsSecond) {
    return;
  }

  const duration = Number(durationAsSecond);

  if (isNaN(duration)) {
    return;
  }

  const expiredAt = dayjs().add(duration, "second").toDate();

  return expiredAt;
}

/**
 * 시간을 특정 포맷으로 변경해준다.
 * @param value 변환할 원본 시간 데이터
 * @param format 변환하고자 하는 데이터 포맷 (입력하지 않으면 기본: 'YYYY.MM.DD')
 */
function toFormattedDate(
  value?: string | Date | number,
  format = "YYYY.MM.DD hh:mm:ss a",
  asUTC?: boolean // timezone이아니라 utc로 받고싶을때
) {
  if (!value) return "";

  const date = dayjs(value);

  if (asUTC) {
    return date.utc().format(format);
  }

  return APP_LOCALE === "KR"
    ? date.tz("Asia/Seoul").format(format)
    : date.tz("Asia/Singapore").format(format);
}

/**
 * 시간을 utc 포맷 (ISO 형식) 으로 변환.
 * @param value 변환할 원본 시간 데이터
 */
function toFormattedDateToUTCDate(value: string | Date | number | undefined) {
  if (!value) return "";

  const date = dayjs(value);

  return date.utc().toISOString();
}

/**
 * 시간을 timezone에 맞는 Date 로 변환.
 * @param value 변환할 원본 시간 데이터
 * @param timeZone
 */
function toFormattedDateToLocaleDate({
  value,
  timeZone,
}: {
  value: string | Date | number | undefined;
  timeZone: TimeZone;
}) {
  if (!value) return "";

  const date = dayjs(value);

  return date.tz(timeZone).toISOString();
}

function getRemainedDays(from: string | Date, to: string | Date) {
  if (!from || !to) return 0;

  const target = dayjs(to);

  const difference = target.diff(from, "days");

  return difference;
}

function isToday(value?: string | Date | number) {
  if (!value) return false;

  const now = dayjs();
  const target = dayjs(value);

  const difference = now.diff(target, "days");

  return !difference;
}

function isBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const now = dayjs();

  const isBefore = target.diff(now, "days") < 0;

  return isBefore;
}

function isTodayOrBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const now = dayjs();

  const isBefore = target.diff(now, "days") <= 0;

  return isBefore;
}

const isSameDay = (date1: Date, date2: Date) => {
  return dayjs(date1).isSame(date2);
};

const isWeekday = (date: Date) => {
  const day = date.getDay();

  return day === 0 || day === 6;
};

function getHolidayList() {
  const yearArr = new Array(5)
    .fill(0)
    .map((v, i) => new Date().getFullYear() + i);

  const holidayListWithYear = yearArr
    .map((year) => PUBLIC_HOLIDAY_LIST.map((date) => `${year}/${date}`))
    .reduce((acc, items) => {
      return acc.concat(items);
    }, []);

  return [
    ...holidayListWithYear,
    ...LUNAR_PUBLIC_HOLIDAY_LIST,
    ...SUBSTITUTE_HOLIDAY_LIST,
  ];
}

const MINUTE_AS_MILLISECONDS = 1000 * 60;

const HOUR_AS_MILLISECONDS = MINUTE_AS_MILLISECONDS * 60;

const DAY_AS_MILLISECONDS = HOUR_AS_MILLISECONDS * 24;

function getUnixTime(date: Date, type: "seconds" | "milliseconds") {
  if (type === "seconds") {
    return date.getTime() / 1000;
  }

  if (type === "milliseconds") {
    return date.getTime();
  }
}

const getTodayDateToLocaleDateStringKr = () => {
  const date = new Date();
  return date.toLocaleDateString("ko-KR");
};

/**
 * utc Date나 string(2022-04-18T04:14:05.371Z 포맷)을 보내면
 * 그 중 날짜부분만(시간 제외)고려 하여 timezone을 고려한 Date로 변환한다
 * (시간 부분이 무시됨에 유의한다)
 * @param utcDateTime
 * @param timeZone
 * @param when
 */
function transformUTCDateToLocalDateTime({
  utcDateTime,
  timeZone,
  when,
}: {
  utcDateTime: Date | string;
  timeZone: TimeZone;
  when: "start" | "end";
}): Date | undefined {
  if (!(utcDateTime && timeZone && when)) return;

  const dateString = (() => {
    const target =
      typeof utcDateTime === "string" ? utcDateTime : utcDateTime.toISOString();
    return target.slice(0, 10);
  })();

  const dayByTimeZone = dayjs.tz(dateString, timeZone);

  if (when === "start") {
    return dayByTimeZone.startOf("day").toDate();
  }

  if (when === "end") {
    return dayByTimeZone.endOf("day").toDate();
  }
}

const THIS_YEAR_AS_TWO_DIGITS = (() => {
  const thisYear = new Date().getFullYear().toString();
  return thisYear.substring(2, 4);
})();

function isInvalidDate(date: Date | string) {
  if (date instanceof Date && isNaN(date.valueOf())) {
    return true;
  }
  return false;
}

export {
  getExpiredAt,
  toFormattedDate,
  toFormattedDateToUTCDate,
  toFormattedDateToLocaleDate,
  isToday,
  isBeforeToday,
  isTodayOrBeforeToday,
  isSameDay,
  isWeekday,
  getHolidayList,
  getRemainedDays,
  MINUTE_AS_MILLISECONDS,
  HOUR_AS_MILLISECONDS,
  DAY_AS_MILLISECONDS,
  getUnixTime,
  getTodayDateToLocaleDateStringKr,
  transformUTCDateToLocalDateTime,
  THIS_YEAR_AS_TWO_DIGITS,
  isInvalidDate,
};
