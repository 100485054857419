import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/styles/constants";
import { setFontStyle } from "@sellernote/_shared/src/styles/typography";

const container = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const rightSideContainer = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.bgColor_2};
`;

const mainContainer = styled.div`
  background-color: ${COLOR.white};
  padding: 24px;
  margin-left: 24px;
  margin-right: 16px;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    > .title {
      ${setFontStyle("Head4", "Bold")};
      color: ${COLOR.grayScale_700};
    }
  }

  > .body {
    display: flex;

    > .info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-right: 16px;
    }

    > .action {
      flex-shrink: 0;
      width: 240px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const invoiceInfoContainer = styled.div`
  display: flex;

  > .summary {
    flex-shrink: 0;
    width: 287px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;

    > .direct-input-button {
      align-self: flex-end;
      padding: 0 8px;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }

  > .detail {
    flex-grow: 1;
    /* 테이블이 깨지는 것을 막기 위해서 임시로 min-width 지정 */
    min-width: 500px;
    display: flex;
    flex-direction: column;
  }
`;

const canceledInvoiceModalWrapper = styled.div`
  > .modal {
    .body {
      margin-bottom: 32px;

      > div {
        margin-bottom: 24px;
        ${setFontStyle("Body6")};
        color: ${COLOR.grayScale_700};

        > strong {
          color: ${COLOR.pointWarning};
        }
      }

      > em {
        ${setFontStyle("Body7")}
        color: ${COLOR.pointWarning};
      }
    }

    .barcode-actions {
      margin-bottom: 24px;
      align-self: center;
      justify-content: center;
      width: 100%;

      > .barcode-action-container {
        width: 240px;
        margin: 0;
      }
    }
  }
`;

export default {
  container,
  rightSideContainer,
  mainContainer,
  invoiceInfoContainer,
  canceledInvoiceModalWrapper,
};
