import styled, { css } from "styled-components";

import { COLOR } from "../../styles/constants";
import { fontStyle } from "../../styles/functions";
import { setFontStyle } from "../../styles/typography";

import { ChipShape } from ".";

const container = styled.div<{
  shape: ChipShape;
  isSelectable: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${(props) => {
    if (props.isSelectable) {
      return css`
        cursor: pointer;
        height: ${props.shape === "round" ? "32px" : "38px"};
        padding: 0 16px;
        ${setFontStyle("SubHead3")};
        color: ${COLOR.grayScale_600};
        background-color: ${COLOR.white};
        border-radius: ${props.shape === "round" ? "100px" : "8px"};
        border: 1px solid ${COLOR.grayScale_400};

        &:hover {
          background-color: ${COLOR.primaryBlue_40};
        }

        &.selected {
          color: ${COLOR.white};
          background-color: ${COLOR.primaryBlue};
          border: 1px solid transparent;
        }
      `;
    } else {
      return css`
        height: 1.125rem;
        padding: 0 0.25rem;
        ${fontStyle("caption")};
        color: ${COLOR.white};
        background-color: ${COLOR.primaryPurple};
      `;
    }
  }}
`;

export default {
  container,
};
