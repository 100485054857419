import { rest } from "msw";

import { getShipdaAdminDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { GET_BID_TRELLO_LIST } from "./data";

export const getTrelloBidList = rest.get(
  getShipdaAdminDevAPI("/bid/list/trello"),
  (req, res, ctx) => {
    const status = req.url.searchParams.get("status");
    const perPage = req.url.searchParams.get("perPage");
    if (status === "inProgress" && perPage === "300") {
      return res(
        ctx.status(200),
        ctx.delay(ARTIFICIAL_DELAY_MS),
        ctx.json(GET_BID_TRELLO_LIST)
      );
    }
  }
);
