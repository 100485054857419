import { selector } from "recoil";

import { InputSelectOption } from "@sellernote/_shared/src/components/input/InputSelect";
import { TabFilterItem } from "@sellernote/_shared/src/components/TabFilter";
import {
  OutSidePackageProviderValue,
  PackingLabelData,
} from "@sellernote/_shared/src/types/fulfillment/packing";
import {
  getPackingInfoOfPackingNo,
  providerOptionDict,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

import { FULFILLMENT_PACKING_ATOMS } from ".";

const LAST_PACKING_NO = selector({
  key: "fulfillment/shipping/selectors/LAST_PACKING_NO",
  get: ({ get }) => get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST).length,
});

/** InvoiceSummary */
const INVOICE_NO_OF_SELECTED_PACKING_NO = selector({
  key: "fulfillment/shipping/selectors/INVOICE_NO_OF_SELECTED_PACKING_NO",
  get: ({ get }) =>
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO),
    })?.invoiceNo ?? "",
});

/** OutSidePackage */
const OUT_SIDE_PACKAGE_PROVIDER_OF_SELECTED_PACKING_NO = selector<
  InputSelectOption<OutSidePackageProviderValue>
>({
  key: "fulfillment/shipping/selectors/OUT_SIDE_PACKAGE_PROVIDER_OF_SELECTED_PACKING_NO",
  get: ({ get }) =>
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO),
    })?.outSidePackageProvider ?? providerOptionDict.shipda,
});
const OUT_SIDE_PACKAGE_OF_SELECTED_PACKING_NO = selector({
  key: "fulfillment/shipping/selectors/OUT_SIDE_PACKAGE_OF_SELECTED_PACKING_NO",
  get: ({ get }) =>
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO),
    })?.outSidePackage ?? {
      label: "",
      value: -1,
    },
});
const IS_SELECTABLE_LIST = selector({
  key: "fulfillment/shipping/selectors/IS_SELECTABLE_LIST",
  get: ({ get }) => {
    const setIsStatusForScanningCompletedInvoice = get(
      FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
    );

    const isLastPackingNoSelected =
      get(LAST_PACKING_NO) ===
      get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO);

    return !setIsStatusForScanningCompletedInvoice && isLastPackingNoSelected;
  },
});
const HAS_SELECTED_OUT_SIDE_PACKAGE = selector({
  key: "fulfillment/shipping/selectors/HAS_SELECTED_OUT_SIDE_PACKAGE",
  get: ({ get }) =>
    // 마지막 포장의 외포장재가 선택되어 있어야 '다음 포장으로', '포장마감'이 가능
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: "last",
    })?.outSidePackage.value !== -1,
});

/** InvoiceDetail */
const IS_VISIBLE_SCROLL_BAR_FOR_INVOICE_DETAIL_TABLE = selector({
  key: "fulfillment/shipping/selectors/IS_VISIBLE_SCROLL_BAR_FOR_INVOICE_DETAIL_TABLE",
  get: ({ get }) =>
    get(FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST).length > 4,
});
const TOTAL_CURRENT_QTY = selector({
  key: "fulfillment/shipping/selectors/TOTAL_CURRENT_QTY",
  get: ({ get }) =>
    get(FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST).reduce(
      (prevTotal, item) => prevTotal + item.currentQty,
      0
    ),
});
const TOTAL_QUANTITY = selector({
  key: "fulfillment/shipping/selectors/TOTAL_QUANTITY",
  get: ({ get }) =>
    get(FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST).reduce(
      (prevTotal, item) => prevTotal + item.quantity,
      0
    ),
});

/** NextPacking */
const IS_ACTIVE_NEXT_PACKING_BUTTON = selector({
  key: "fulfillment/shipping/selectors/IS_ACTIVE_NEXT_PACKING_BUTTON",
  get: ({ get }) => {
    const isWorking = get(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

    const isCompletedPacking = get(TOTAL_CURRENT_QTY) === get(TOTAL_QUANTITY);

    const isEmptyLastPacking =
      (
        getPackingInfoOfPackingNo({
          packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
          packingNo: "last",
        })?.packingDetailList ?? []
      ).length === 0;

    return isWorking && !isCompletedPacking && !isEmptyLastPacking;
  },
});

/** PackingDetail */
const IS_VISIBLE_SCROLL_BAR_FOR_PACKING_DETAIL_TABLE = selector({
  key: "fulfillment/shipping/selectors/IS_VISIBLE_SCROLL_BAR_FOR_PACKING_DETAIL_TABLE",
  get: ({ get }) =>
    (
      getPackingInfoOfPackingNo({
        packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
        packingNo: get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO),
      })?.packingDetailList ?? []
    ).length > 6,
});
const IS_EMPTY_PACKING_DETAIL_LIST = selector({
  key: "fulfillment/shipping/selectors/IS_EMPTY_PACKING_DETAIL_LIST",
  get: ({ get }) =>
    (
      getPackingInfoOfPackingNo({
        packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
        packingNo: get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO),
      })?.packingDetailList ?? []
    ).length === 0,
});
const TAB_FILTER_LIST_OF_PACKING_DETAIL = selector({
  key: "fulfillment/shipping/selectors/TAB_FILTER_LIST_OF_PACKING_DETAIL",
  get: ({ get }) =>
    get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST).map<TabFilterItem<string>>(
      (packingInfo, i) => ({
        label: `포장${i + 1}`,
        filter: String(i + 1),
      })
    ),
});
const PACKING_INFO_OF_SELECTED_PACKING_NO = selector({
  key: "fulfillment/shipping/selectors/PACKING_INFO_OF_SELECTED_PACKING_NO",
  get: ({ get }) =>
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: get(FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO),
    }),
});

/** DeletePacking */
const IS_ACTIVE_DELETE_LAST_PACKING_BUTTON = selector({
  key: "fulfillment/shipping/selectors/IS_ACTIVE_DELETE_LAST_PACKING_BUTTON",
  get: ({ get }) => {
    const isWorking = get(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

    const packingInfoList = get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST);
    const onlyOnePacking = packingInfoList.length === 1;
    const haMoreThanOnePackingDetail =
      (
        getPackingInfoOfPackingNo({ packingInfoList, packingNo: 1 })
          ?.packingDetailList ?? []
      ).length > 0;

    return isWorking && (onlyOnePacking ? haMoreThanOnePackingDetail : true);
  },
});
const INVOICE_NO_OF_LAST_PACKING = selector({
  key: "fulfillment/shipping/selectors/INVOICE_NO_Of_LAST_PACKING",
  get: ({ get }) =>
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: "last",
    })?.invoiceNo ?? "",
});

/** CompletePacking */
const IS_ACTIVE_COMPLETE_PACKING_BUTTON = selector({
  key: "fulfillment/shipping/selectors/IS_ACTIVE_COMPLETE_PACKING_BUTTON",
  get: ({ get }) => {
    const isWorking = get(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

    const isCompletedWorking = get(TOTAL_CURRENT_QTY) === get(TOTAL_QUANTITY);

    return isWorking && isCompletedWorking;
  },
});
const BOXES_TO_COMPLETE = selector({
  key: "fulfillment/shipping/selectors/BOXES_TO_COMPLETE",
  get: ({ get }) =>
    get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST).map((packingInfo) => {
      const items = packingInfo.packingDetailList.map((packingDetailItem) => ({
        skuId: packingDetailItem.skuId,
        qty: packingDetailItem.currentQty,
      }));

      return {
        invoiceNo: packingInfo.invoiceNo,
        packings: [
          {
            outerPackagesId:
              // '포장불필요'를 선택한 경우 id: 98로 설정(백엔드 요청)
              packingInfo.outSidePackage.value === "none"
                ? 98
                : packingInfo.outSidePackage.value,
            items,
          },
        ],
      };
    }),
});

/** canceling */
const INVOICE_NO_OF_FIRST_PACKING = selector({
  key: "fulfillment/shipping/selectors/INVOICE_NO_OF_FIRST_PACKING",
  get: ({ get }) =>
    getPackingInfoOfPackingNo({
      packingInfoList: get(FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST),
      packingNo: 1,
    })?.invoiceNo ?? "",
});

/** Print Packing Label */
const PACKING_LIST_TO_PRINT_PACKING_LABEL = selector<PackingLabelData[]>({
  key: "fulfillment/shipping/selectors/PACKING_LIST_TO_PRINT_PACKING_LABEL",
  get: ({ get }) => {
    const dataForPackingLabel = get(
      FULFILLMENT_PACKING_ATOMS.DATA_FOR_PACKING_LABEL
    );

    return get(
      FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
    ).map<PackingLabelData>((packingInfo, i, arr) => ({
      invoiceNo: packingInfo.invoiceNo,
      total: arr.length,
      packingNo: i + 1,
      orderNo: dataForPackingLabel?.orderNo ?? "",
      customerName: dataForPackingLabel?.customerName ?? "",
      customerPhone: dataForPackingLabel?.customerPhone ?? "",
      items: packingInfo.packingDetailList.map((packingDetailItem) => ({
        productName: packingDetailItem.itemName,
        quantity: packingDetailItem.currentQty,
      })),
    }));
  },
});

export default {
  LAST_PACKING_NO,

  INVOICE_NO_OF_SELECTED_PACKING_NO,

  OUT_SIDE_PACKAGE_PROVIDER_OF_SELECTED_PACKING_NO,
  OUT_SIDE_PACKAGE_OF_SELECTED_PACKING_NO,
  IS_SELECTABLE_LIST,
  HAS_SELECTED_OUT_SIDE_PACKAGE,

  IS_VISIBLE_SCROLL_BAR_FOR_INVOICE_DETAIL_TABLE,
  TOTAL_CURRENT_QTY,
  TOTAL_QUANTITY,

  IS_ACTIVE_NEXT_PACKING_BUTTON,

  IS_VISIBLE_SCROLL_BAR_FOR_PACKING_DETAIL_TABLE,
  IS_EMPTY_PACKING_DETAIL_LIST,
  TAB_FILTER_LIST_OF_PACKING_DETAIL,
  PACKING_INFO_OF_SELECTED_PACKING_NO,

  IS_ACTIVE_DELETE_LAST_PACKING_BUTTON,
  INVOICE_NO_OF_LAST_PACKING,

  IS_ACTIVE_COMPLETE_PACKING_BUTTON,
  BOXES_TO_COMPLETE,

  INVOICE_NO_OF_FIRST_PACKING,

  PACKING_LIST_TO_PRINT_PACKING_LABEL,
};
