import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/styles/constants";
import { setFontStyle } from "@sellernote/_shared/src/styles/typography";

const container = styled.div`
  flex: 0 0 200px;
  background-color: ${COLOR.white};
  width: 100%;
  padding: 48px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  > img {
    height: 32px;
    display: block;
    padding: 0 16px;
    margin-bottom: 8px;
  }

  > .name {
    padding: 0 16px;
    margin-bottom: 24px;
    color: ${COLOR.grayScale_700};
    ${setFontStyle("SubHead2")};

    strong {
      ${setFontStyle("SubHead2", "Bold")};
      margin-right: 4px;
    }
  }

  > .menu-item {
    ${setFontStyle("Head6", "Bold")};
    color: ${COLOR.primaryBlue};
    padding-right: 8px;
    border-right: 4px solid ${COLOR.primaryBlue};
  }

  > .text-button {
    position: absolute;
    bottom: 80px;
    right: 16px;
  }
`;

export default {
  container,
};
