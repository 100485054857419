import { css } from "styled-components";

export const COLORS = {
  gray: "rgb(235, 235, 235)",
  grayDark: "rgb(162, 160, 160)",
  grayDarkest: "#2d2d2d",
  grayTransparent: "rgba(235, 235, 235, 0.6)",
  lightBlue: "#0091ff",
  greenDark: "#466575",
  red: "#c34646",
  navy: "#001429",
};

export const boxShadow = css`
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
`;

export const NEW_COLORS = {
  fontColor: "#1D3557",

  primary1: "#5482D6",
  primary1_80: "rgba(84, 130, 214, 0.8)",
  primary1_60: "rgba(84, 130, 214, 0.6)",
  primary1_40: "rgba(84, 130, 214, 0.4)",
  primary1_10: "rgba(84, 130, 214, 0.1)",

  sub1: "#4DD0E3",
  sub1_80: "rgba(77, 208, 227, 0.8)",
  sub1_60: "rgba(77, 208, 227, 0.6)",
  sub1_40: "rgba(77, 208, 227, 0.4)",
  sub1_10: "rgba(77, 208, 227, 0.1)",

  point1: "#FA5959",
  point1_10: "rgba(250, 89, 89, 0.1)",
  point1_70: "rgba(250, 89, 89, 0.7)",
  point2: "#A31721",

  grey1: "#424242",
  grey2: "#BDBDBD",
  grey3: "#E0E0E0",
  grey4: "#EEEEEE",

  bk: "#000000",
  white: "#FFFFFF",
};

export const NEW_COLORS_V2 = {
  fontColor: "#1D3557",

  amazoneYellow: "#FF9900",
  amazoneYellow_60: "rgba(255, 153, 0, 0.6)",
  amazoneYellow_20: "rgba(255, 153, 0, 0.2)",

  amazoneBlue: "#146EB4",
  amazoneBlue_60: "rgba(20, 110, 180, 0.6)",
  amazoneBlue_20: "rgba(20, 110, 180, 0.2)",

  deepBlue: "#4062A3",

  primaryBlue: "#5482D6",
  primaryBlue_80: "rgba(84, 130, 214, 0.8)",
  primaryBlue_60: "rgba(84, 130, 214, 0.6)",
  primaryBlue_40: "rgba(84, 130, 214, 0.4)",
  primaryBlue_10: "rgba(84, 130, 214, 0.1)",

  primaryPurple: "#745ED6",
  primaryPurple_80: "rgba(116, 94, 214, 0.8)",
  primaryPurple_60: "rgba(116, 94, 214, 0.6)",
  primaryPurple_40: "rgba(116, 94, 214, 0.4)",
  primaryPurple_10: "rgba(116, 94, 214, 0.1)",

  warning: "#eb5757",

  bk: "#000000",
  bk_80: "rgba(0, 0, 0, 0.8)",
  bk_60: "rgba(0, 0, 0, 0.6)",
  bk_40: "rgba(0, 0, 0, 0.4)",

  grey1: "#212121",
  grey2: "#616161",
  grey3: "#9E9E9E",
  grey4: "#E0E0E0",
  grey5: "#FAFAFA",

  white: "#FFFFFF",
};
