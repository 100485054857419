import { css, FlattenInterpolation } from "styled-components";

/** 모바일 size용 스타일 */
export function mobile(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: 767px) {
      ${styles}
    }
  `;
}

export type FontStyle =
  | "head1"
  | "head1-bold"
  | "head2"
  | "head2-bold"
  | "head3"
  | "head3-bold"
  | "body1"
  | "body1-bold"
  | "body2"
  | "body2-bold"
  | "body3"
  | "body3-bold"
  | "caption"
  | "caption-bold"
  | "overline"
  | "overline-bold";

export function fontStyle(type: FontStyle) {
  const head1 = css`
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
  `;
  const head2 = css`
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
  `;
  const head3 = css`
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  `;
  const body1 = css`
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  `;
  const body2 = css`
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  `;
  const body3 = css`
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
  `;
  const caption = css`
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.25px;
  `;
  const overline = css`
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.25px;
  `;

  switch (type) {
    case "head1": {
      return css`
        ${head1};
        font-weight: normal;
      `;
    }
    case "head1-bold": {
      return css`
        ${head1};
        font-weight: bold;
      `;
    }

    case "head2": {
      return css`
        ${head2};
        font-weight: normal;
      `;
    }
    case "head2-bold": {
      return css`
        ${head2};
        font-weight: bold;
      `;
    }

    case "head3": {
      return css`
        ${head3};
        font-weight: normal;
      `;
    }
    case "head3-bold": {
      return css`
        ${head3};
        font-weight: bold;
      `;
    }

    case "body1": {
      return css`
        ${body1};
        font-weight: normal;
      `;
    }
    case "body1-bold": {
      return css`
        ${body1};
        font-weight: bold;
      `;
    }

    case "body2": {
      return css`
        ${body2};
        font-weight: normal;
      `;
    }
    case "body2-bold": {
      return css`
        ${body2};
        font-weight: bold;
      `;
    }

    case "body3": {
      return css`
        ${body3};
        font-weight: normal;
      `;
    }
    case "body3-bold": {
      return css`
        ${body3};
        font-weight: bold;
      `;
    }

    case "caption": {
      return css`
        ${caption};
        font-weight: normal;
      `;
    }
    case "caption-bold": {
      return css`
        ${caption};
        font-weight: bold;
      `;
    }

    case "overline": {
      return css`
        ${overline};
        font-weight: normal;
      `;
    }
    case "overline-bold": {
      return css`
        ${overline};
        font-weight: bold;
      `;
    }
  }
}

export type FontStyleV2 =
  | "h0"
  | "h0-bold"
  | "h0-bold-en"
  | "h1"
  | "h1-bold"
  | "h2"
  | "h2-bold"
  | "h2-bold-en"
  | "body1"
  | "body1-bold"
  | "body2"
  | "body2-bold"
  | "caption"
  | "caption-bold"
  | "overline"
  | "overline-bold";
export function fontStyleV2(type: FontStyleV2) {
  const h0 = css`
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.25px;
  `;

  const h1 = css`
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.25px;
  `;

  const h2 = css`
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.5px;
  `;

  const body1 = css`
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
  `;

  const body2 = css`
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
  `;

  const caption = css`
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.5px;
  `;

  const overline = css`
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.5px;
  `;

  switch (type) {
    case "h0": {
      return css`
        ${h0};
        font-weight: normal;
      `;
    }
    case "h0-bold": {
      return css`
        ${h0};
        font-weight: bold;
        letter-spacing: -0.5px;
      `;
    }
    case "h0-bold-en": {
      return css`
        ${h0};
        font-size: 27px;
        font-weight: bold;
        letter-spacing: -0.5px;
      `;
    }

    case "h1": {
      return css`
        ${h1};
        font-weight: normal;
      `;
    }
    case "h1-bold": {
      return css`
        ${h1};
        font-weight: bold;
      `;
    }

    case "h2": {
      return css`
        ${h2};
        font-weight: normal;
      `;
    }
    case "h2-bold": {
      return css`
        ${h2};
        font-weight: bold;
      `;
    }
    case "h2-bold-en": {
      return css`
        ${h2};
        font-size: 16px;
        font-weight: bold;
      `;
    }

    case "body1": {
      return css`
        ${body1};
        font-weight: normal;
      `;
    }
    case "body1-bold": {
      return css`
        ${body1};
        font-weight: bold;
      `;
    }

    case "body2": {
      return css`
        ${body2};
        font-weight: normal;
      `;
    }
    case "body2-bold": {
      return css`
        ${body2};
        font-weight: bold;
      `;
    }

    case "caption": {
      return css`
        ${caption};
        font-weight: normal;
      `;
    }
    case "caption-bold": {
      return css`
        ${caption};
        font-weight: bold;
      `;
    }

    case "overline": {
      return css`
        ${overline};
        font-weight: normal;
      `;
    }
    case "overline-bold": {
      return css`
        ${overline};
        font-weight: bold;
      `;
    }
  }
}
