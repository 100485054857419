import React, { memo } from "react";

import Button from "@sellernote/_shared/src/components/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";

import ReportProblemModal from "./ReportProblemModal";
import useReportProblem from "./useReportProblem";
import Styled from "./index.styles";

function ReportProblem({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const {
    isVisibleReportProblemModal,
    handleReportProblemModalOpen,
    handleReportProblemModalClose,

    isWorking,
  } = useReportProblem();

  return (
    <>
      <Styled.reportProblem>
        <Button
          theme="primary"
          size="block"
          label="문제보고"
          handleClick={handleReportProblemModalOpen}
          disabled={!isWorking}
        />
      </Styled.reportProblem>

      {isVisibleReportProblemModal && (
        <ReportProblemModal
          setRowInfoToHighlight={setRowInfoToHighlight}
          handleReportProblemModalClose={handleReportProblemModalClose}
        />
      )}
    </>
  );
}

export default memo(ReportProblem);
