import React, { memo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import Styled from "./index.styles";

function RequestMemoContents({
  requestMemoList,
}: {
  requestMemoList: string[];
}) {
  return (
    <Styled.requestMemoContents>
      {requestMemoList.map((requestMemo, i) => (
        <div key={i}>{requestMemo}</div>
      ))}
    </Styled.requestMemoContents>
  );
}

function RequestMemo() {
  const requestMemoList = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.REQUEST_MEMO_LIST
  );

  return (
    <Styled.requestMemo>
      <Styled.requestMemoTitle>요청사항</Styled.requestMemoTitle>
      <RequestMemoContents requestMemoList={requestMemoList} />
    </Styled.requestMemo>
  );
}

export default memo(RequestMemo);
