import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import { PackingProblemItem } from "@sellernote/_shared/src/types/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useCompleteReportProblem({
  setRowInfoToHighlight,
  handleReportProblemModalClose,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  handleReportProblemModalClose: () => void;
}) {
  const problemDict = useRecoilValue(FULFILLMENT_PACKING_ATOMS.PROBLEM_DICT);
  const invoiceNoOfFirstPacking = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.INVOICE_NO_OF_FIRST_PACKING
  );
  const setCanceledInvoiceNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );

  const {
    mutate: interpretBarcode,
    reset: resetInterpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
    isBarcodeModal: true,
  });

  const {
    mutate: reportProblem,
    reset: resetReportProblem,
    ResponseHandler: ResponseHandlerOfReportingProblem,
  } = PACKING_QUERY.useReportProblem();

  const isActiveCompleteButton = useMemo(
    () =>
      Object.values(problemDict).every((problemItem) => {
        if (problemItem.kind?.value === "directInput") {
          return !!problemItem.directInput?.length;
        }

        return !!problemItem.kind?.value;
      }),
    [problemDict]
  );

  const handleReportProblemConfirm = useCallback(() => {
    const problemItems: PackingProblemItem[] = Object.entries(problemDict).map(
      ([skuId, problemItem]) => {
        return {
          skuId: Number(skuId),
          locationId: problemItem.locationId,
          workerId: problemItem.workerId,
          wmsStatus: "packing",
          kind: problemItem.kind?.value ?? "none",
          directInput: problemItem.directInput,
        };
      }
    );

    reportProblem(problemItems, {
      onSuccess: () => {
        handleReportProblemModalClose();
        setRowInfoToHighlight(undefined);
      },
    });
  }, [
    handleReportProblemModalClose,
    problemDict,
    reportProblem,
    setRowInfoToHighlight,
  ]);

  const checkIsCanceledInvoice = useCallback(() => {
    interpretBarcode(
      {
        type: "shipping",
        barCode: invoiceNoOfFirstPacking,
      },
      {
        onSuccess: ({ data: { shippingStatus } }) => {
          if (shippingStatus === "cancel") {
            handleReportProblemModalClose();
            setCanceledInvoiceNo(invoiceNoOfFirstPacking);
            return;
          }

          handleReportProblemConfirm();
        },
      }
    );
  }, [
    invoiceNoOfFirstPacking,
    handleReportProblemConfirm,
    handleReportProblemModalClose,
    interpretBarcode,
    setCanceledInvoiceNo,
  ]);

  useScanActionBarcode({
    actionBarcode: "H_REP_PRO",
    actionFn: resetReportProblem,
  });
  useScanActionBarcode({
    actionBarcode: "H_INT_BAR",
    actionFn: resetInterpretBarcode,
  });

  return {
    checkIsCanceledInvoice,
    isActiveCompleteButton,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfReportingProblem,
  };
}
