import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useCheckIsTablet } from "../../../utils/common/hook";

import ReactWindowList from "../../ReactWindowList";
import Styled from "./index.styles";

/**
 * 특정 Td의 border-bottom을 없애기 위해서 사용
 * 반드시 값이 들어가는 key(테이블에 표시할 데이터)보다 상단에 작성해야 함
 */
export interface NoBorderBottomForTd {
  key: string; // column 이름
  isLastRow: boolean; // 마지막 Row에는 border-bottom이 있어야 함
}

/**
 * 특정 Row의 컬럼을 합쳐서 데이터를 입력하고 싶을 경우 사용
 */
export interface ColSpan {
  value: number;
  content: React.ReactNode;
  hasFullWidth?: boolean;
}

/**
 * Th를 Grouping하기 위해 사용
 */
type TableColumnGroupInfo = TableColumnInfoValue & {
  /**
   * th의 좌, 우측 끝에는 curtain이 있기 때문에
   * 좌, 우측 끝 Th group에는 원하는 colSpan+1을 해줘야 함
   */
  colSpan: number;
};

export type TableDataListItem<T> = {
  [P in keyof T]: T[P];
} & {
  rowKey: string | number;
  /**
   * 특정 row의 글자색을 다르게 하고 싶을 경우 HEX값으로 전달(COLOR 상수 이용)
   */
  rowFontColor?: string;
  rowBackgroundColor?: string;
  handleRowClick?: () => void;
  disabled?: boolean;
  rowClassName?: string;
  /**
   * border-bottom 효과를 없애고 싶을때 사용
   */
  noBorderBottom?: boolean;
  colSpan?: ColSpan;
  noBorderBottomForTd?: NoBorderBottomForTd[];
  /**
   * 특정 Row의 hover 배경컬러를 무시하고 싶을 때 사용
   */
  disabledRowHoverBgColor?: boolean;
};

type TableColumnInfoValue = {
  label: React.ReactNode;
  /**
   * flex 비율. default: 1
   * web에서는 반응형 구현을 위해서 portion을 사용
   */
  portion?: number;
  /**
   * 최대 너비를 제한. fixedWidth 속성이 있을때는 무시됨
   */
  maxWidth?: string;
  /**
   * width에 고정 px를 주고 싶을때 사용
   * - table width 가 길어져서 가로스크롤이 필요할 때, 총 너비가 아닌 화면에 노출되어야 하는 너비를 지정한다.
   * - fixedWidth가 할당되면 portion은 무효화 됨.
   * - 하나 이상의 column에 fixedWidth이 할당되면 나머지 column은 기본 width가 80px로 지정됨
   * - device width가 좁으면 overflow됨에 유의
   */
  fixedWidth?: number;
};

export type TableColumnInfo<T> = {
  [P in keyof T]: TableColumnInfoValue;
};

export interface TableRowInfoToHighlight {
  rowKey: string | number;
}

type TableProps<T> =
  | (TableBasicProps<T> & { isWindowed?: false })
  | (TableBasicProps<T> & { isWindowed: true; height: number });

interface TableBasicProps<T> {
  columnGroupInfo?: TableColumnGroupInfo[];
  columnInfo: TableColumnInfo<T>;
  dataList?: TableDataListItem<T>[];
  setTestMounted?: (val: boolean) => void;
  rowInfoToHighlight?: TableRowInfoToHighlight;
  /**
   * row의 최소 높이. (*헤드 row에는 반영되지 않음)
   */
  rowMinHeight?: string;
  tableWidth?: string;
  /**
   * 특정 Row의 hover 배경컬러를 무시하고 싶을 때 사용
   */
  disabledRowHoverBgColor?: boolean;
  className?: string;
}

type RowToHighlightRef =
  | ((instance: HTMLTableRowElement | null) => void)
  | React.MutableRefObject<HTMLTableRowElement | null>
  | null;

type TablePropsWithRef<T> = TableProps<T> & {
  /**
   * 부모컴포넌트로부터 전달받는 ref로서,
   * rowInfoToHighlight에 따라 highlight할 row가 되는 dom요소를 가져오는데 사용됨.
   */
  ref?: RowToHighlightRef;
};

function useTableCurtain({
  activated,
  table,
  leftEnd,
  rightEnd,
}: {
  activated: boolean;
  table: HTMLTableElement;
  leftEnd: HTMLTableCellElement;
  rightEnd: HTMLTableCellElement;
}) {
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const [isRightEnd, setIsRightEnd] = useState(activated ? false : true);

  function onScrollTable() {
    if (!activated) return;

    if (checkIsEnd("left", table, leftEnd)) {
      setIsLeftEnd(true);
    } else {
      setIsLeftEnd(false);
    }

    if (checkIsEnd("right", table, rightEnd)) {
      setIsRightEnd(true);
    } else {
      setIsRightEnd(false);
    }
  }

  useEffect(() => {
    if (!activated) {
      setIsRightEnd(true);
    }
  }, [activated]);

  function checkIsEnd(
    direction: "left" | "right",
    container: HTMLTableElement,
    inner: HTMLTableCellElement
  ) {
    const containerRect = container.getClientRects()[0];
    const innerRect = inner.getClientRects()[0];

    if (!containerRect || !innerRect) {
      return true;
    }

    if (direction === "left") {
      return containerRect.x === innerRect.x;
    }

    if (direction === "right") {
      return containerRect.right === innerRect.right;
    }
  }

  return {
    tableHasArriveLeftEnd: isLeftEnd,
    tableHasArriveRightEnd: isRightEnd,
    onScrollTable,
  };
}

function TableBodyRow<T>({
  rowKey,
  rowMinHeight,
  rowInfoToHighlight,
  rowToHighlightRef,
  disabled,
  handleRowClick,
  rowClassName,
  isBlinkOnHighlightRow,
  rowFontColor,
  rowBackgroundColor,
  disabledRowHoverBgColor,
  makeColumns,
  rowData,
}: {
  rowKey: string | number;
  rowMinHeight?: string;
  rowInfoToHighlight?: TableRowInfoToHighlight;
  rowToHighlightRef: RowToHighlightRef;
  disabled?: boolean;
  handleRowClick?: () => void;
  rowClassName?: string;
  isBlinkOnHighlightRow: boolean;
  rowFontColor?: string;
  rowBackgroundColor?: string;
  disabledRowHoverBgColor?: boolean;
  makeColumns: (rowData: TableDataListItem<T>) => JSX.Element[];
  rowData: TableDataListItem<T>;
}) {
  return (
    <Styled.TableTr
      key={`row${rowKey}`}
      rowMinHeight={rowMinHeight}
      ref={
        rowKey === rowInfoToHighlight?.rowKey ? rowToHighlightRef : undefined
      }
      onClick={handleRowClick}
      className={`${disabled ? "disabled" : ""} ${
        handleRowClick ? "clickable" : ""
      } ${rowClassName || ""} ${
        rowKey === rowInfoToHighlight?.rowKey && isBlinkOnHighlightRow
          ? "blink"
          : ""
      }`}
      rowFontColor={rowFontColor}
      isHighlighted={rowKey === rowInfoToHighlight?.rowKey}
      rowBackgroundColor={rowBackgroundColor}
      disabledRowHoverBgColor={disabledRowHoverBgColor}
    >
      {makeColumns(rowData)}
    </Styled.TableTr>
  );
}

const Table = <T extends unknown>(
  {
    columnGroupInfo,
    columnInfo,
    dataList,
    rowInfoToHighlight,
    rowMinHeight,
    tableWidth,
    disabledRowHoverBgColor,
    className,
    ...propsByType
  }: TableProps<T>,
  ref: RowToHighlightRef
) => {
  const { isTablet } = useCheckIsTablet();

  const columnGroupSizes = (columnGroupInfo ?? []).map((v) => v.colSpan);

  const hasFixedWidth = Object.values(columnInfo).some(
    (v) => !!(v as TableColumnInfoValue).fixedWidth
  );

  const isOverflowed = hasFixedWidth;

  const [isBlinkOnHighlightRow, setIsBlinkOnHighlightRow] = useState(
    !!rowInfoToHighlight
  );

  useEffect(() => {
    if (rowInfoToHighlight) {
      handleHighlightInfoChange();
    }

    function handleHighlightInfoChange() {
      setIsBlinkOnHighlightRow(true);
      setTimeout(() => {
        setIsBlinkOnHighlightRow(false);
      }, 1000);
    }
  }, [rowInfoToHighlight]);

  const makeColumns = useCallback(
    (rowData: TableDataListItem<T>) => {
      const result = [];

      let noBorderBottomForTd: NoBorderBottomForTd[] = [];
      for (const [key, value] of Object.entries(rowData)) {
        if (
          (key as keyof TableDataListItem<T>) === "disabled" ||
          (key as keyof TableDataListItem<T>) === "rowKey" ||
          (key as keyof TableDataListItem<T>) === "rowFontColor" ||
          (key as keyof TableDataListItem<T>) === "rowBackgroundColor" ||
          (key as keyof TableDataListItem<T>) === "handleRowClick" ||
          (key as keyof TableDataListItem<T>) === "noBorderBottom" ||
          (key as keyof TableDataListItem<T>) === "rowClassName"
        ) {
          continue;
        }

        const columnPortion =
          columnInfo[key as keyof TableColumnInfo<T>]?.portion;

        const columnMaxWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.maxWidth;

        const columnFixedWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.fixedWidth;

        if ((key as keyof TableDataListItem<T>) === "colSpan") {
          const colSpanValue = value as ColSpan;

          result.push(
            <Styled.TableTd
              colSpan={colSpanValue.value}
              key={key}
              portion={columnPortion}
              maxWidth={columnMaxWidth}
              fixedWidth={columnFixedWidth}
              isOverflowed={isOverflowed}
              noBorderBottom={rowData.noBorderBottom}
              rowMinHeight={rowMinHeight}
              style={{
                ...(colSpanValue.hasFullWidth && {
                  width: tableRef.current.scrollWidth,
                }),
              }}
            >
              {colSpanValue.content}
            </Styled.TableTd>
          );

          continue;
        }

        /** noBorderBottomForTd */
        if ((key as keyof TableDataListItem<T>) === "noBorderBottomForTd") {
          noBorderBottomForTd = value as NoBorderBottomForTd[];

          continue;
        }

        const noBorderBottomForTdKeys = noBorderBottomForTd.map((v) => v.key);
        if (noBorderBottomForTdKeys.includes(key)) {
          const tdInfo = noBorderBottomForTd.find((v) => v.key === key);

          result.push(
            <Styled.TableTd
              noBorderBottomForTd={tdInfo}
              key={key}
              portion={columnPortion}
              maxWidth={columnMaxWidth}
              fixedWidth={columnFixedWidth}
              isOverflowed={isOverflowed}
              noBorderBottom={rowData.noBorderBottom}
              rowMinHeight={rowMinHeight}
            >
              {value}
            </Styled.TableTd>
          );

          continue;
        }

        /** 일반 Td */
        result.push(
          <Styled.TableTd
            key={key}
            portion={columnPortion}
            maxWidth={columnMaxWidth}
            fixedWidth={columnFixedWidth}
            isOverflowed={isOverflowed}
            noBorderBottom={rowData.noBorderBottom}
            rowMinHeight={rowMinHeight}
          >
            {value}
          </Styled.TableTd>
        );
      }

      return result;
    },
    [columnInfo, isOverflowed, rowMinHeight]
  );

  const RowsForNotIsWindowed = useMemo(() => {
    if (propsByType.isWindowed) return null;

    return dataList?.map((row) => {
      return (
        <TableBodyRow<T>
          key={row.rowKey}
          rowKey={row.rowKey}
          rowMinHeight={rowMinHeight}
          rowInfoToHighlight={rowInfoToHighlight}
          rowToHighlightRef={ref}
          disabled={row.disabled}
          handleRowClick={row.handleRowClick}
          rowClassName={row.rowClassName}
          isBlinkOnHighlightRow={isBlinkOnHighlightRow}
          rowFontColor={row.rowFontColor}
          rowBackgroundColor={row.rowBackgroundColor}
          disabledRowHoverBgColor={row.disabledRowHoverBgColor}
          makeColumns={makeColumns}
          rowData={row}
        />
      );
    });
  }, [
    propsByType.isWindowed,
    dataList,
    isBlinkOnHighlightRow,
    makeColumns,
    ref,
    rowMinHeight,
    rowInfoToHighlight,
  ]);

  const tableRef = useRef(null as unknown as HTMLTableElement);

  const tableLeftEndRef = useRef(null as unknown as HTMLTableCellElement);
  const tableRightEndRef = useRef(null as unknown as HTMLTableCellElement);

  const { tableHasArriveLeftEnd, tableHasArriveRightEnd, onScrollTable } =
    useTableCurtain({
      activated: isOverflowed && !isTablet,
      table: tableRef.current,
      leftEnd: tableLeftEndRef.current,
      rightEnd: tableRightEndRef.current,
    });

  return (
    <Styled.container
      className={`${className || ""} table`}
      isOverflowed={isOverflowed}
      tableWidth={tableWidth}
      columnGroupSizes={columnGroupSizes}
    >
      {!tableHasArriveLeftEnd && <div className="curtain left" />}

      {!tableHasArriveRightEnd && <div className="curtain right" />}

      <table
        ref={tableRef}
        {...(isOverflowed
          ? {
              onScroll: () => {
                onScrollTable();
              },
            }
          : {})}
      >
        <thead>
          {columnGroupInfo && (
            <Styled.TableTr>
              {columnGroupInfo.map((col, i) => (
                <Styled.TableThGroup
                  key={`col${i}`}
                  scope="colgroup"
                  portion={(col as TableColumnInfoValue).portion}
                  maxWidth={(col as TableColumnInfoValue).maxWidth}
                  fixedWidth={col.fixedWidth}
                  isOverflowed={isOverflowed}
                  colSpan={col.colSpan}
                >
                  {(col as TableColumnInfoValue).label}
                </Styled.TableThGroup>
              ))}
            </Styled.TableTr>
          )}
          <Styled.TableTr>
            <th ref={tableLeftEndRef} style={{ visibility: "hidden" }} />
            {Object.values(columnInfo).map((col, i) => (
              <Styled.TableTh
                key={`col${i}`}
                scope="col"
                portion={(col as TableColumnInfoValue).portion}
                maxWidth={(col as TableColumnInfoValue).maxWidth}
                fixedWidth={(col as TableColumnInfoValue).fixedWidth}
                isOverflowed={isOverflowed}
              >
                {(col as TableColumnInfoValue).label}
              </Styled.TableTh>
            ))}
            <th ref={tableRightEndRef} style={{ visibility: "hidden" }} />
          </Styled.TableTr>
        </thead>

        <tbody>
          {propsByType.isWindowed ? (
            <ReactWindowList
              height={propsByType.height}
              scrollWidth={tableRef.current?.scrollWidth}
              dataList={dataList}
              rowKeyToHighlight={rowInfoToHighlight?.rowKey}
              Row={({ data, index }) => (
                <TableBodyRow
                  rowKey={data[index].rowKey}
                  rowMinHeight={rowMinHeight}
                  rowInfoToHighlight={rowInfoToHighlight}
                  rowToHighlightRef={ref}
                  disabled={data[index].disabled}
                  handleRowClick={data[index].handleRowClick}
                  rowClassName={data[index].rowClassName}
                  isBlinkOnHighlightRow={isBlinkOnHighlightRow}
                  rowFontColor={data[index].rowFontColor}
                  rowBackgroundColor={data[index].rowBackgroundColor}
                  disabledRowHoverBgColor={data[index].disabledRowHoverBgColor}
                  makeColumns={makeColumns}
                  rowData={data[index]}
                />
              )}
            />
          ) : (
            RowsForNotIsWindowed
          )}
        </tbody>
      </table>
    </Styled.container>
  );
};

export default React.forwardRef(Table) as <T extends unknown>(
  props: TablePropsWithRef<T>
) => React.ReactElement;
