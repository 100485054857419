import { css, FlattenInterpolation } from "styled-components";

import { COLOR } from "./constants";

/** 모바일 size용 스타일 */
export function mobile(styles: FlattenInterpolation<any>) {
  return css`
    /*  Small devices (landscape phones, less than 768px) */
    @media (max-width: 767.98px) {
      ${styles}
    }
  `;
}

/** 태블릿 size용 스타일 */
export function tablet(styles: FlattenInterpolation<any>) {
  return css`
    /* Medium devices (tablets, less than 992px) */
    @media (max-width: 991.98px) {
      ${styles}
    }
  `;
}

export type FontStyle =
  | "head1"
  | "head2"
  | "head3"
  | "head4"
  | "subHead1"
  | "subHead2"
  | "subHead3"
  | "subHead4"
  | "body1"
  | "body2"
  | "body3"
  | "body4"
  | "caption";

export function fontStyle(type: FontStyle) {
  switch (type) {
    case "head1": {
      return css`
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "head2": {
      return css`
        font-weight: bold;
        font-size: 2.25rem;
        line-height: 2.875rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "head3": {
      return css`
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.625rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "head4": {
      return css`
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2.125rem;
        letter-spacing: -0.0375rem;
      `;
    }

    case "subHead1": {
      return css`
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 1.875rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "subHead2": {
      return css`
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "subHead3": {
      return css`
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1.375rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "subHead4": {
      return css`
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: -0.0375rem;
      `;
    }

    case "body1": {
      return css`
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.625rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "body2": {
      return css`
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "body3": {
      return css`
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.375rem;
        letter-spacing: -0.0375rem;
      `;
    }
    case "body4": {
      return css`
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: -0.0375rem;
      `;
    }

    case "caption": {
      return css`
        font-weight: normal;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: -0.0375rem;
      `;
    }
  }
}

/**
 * 스크롤 바를 표시하지 않는다
 */
export function hideScrollBar() {
  return css`
    &::-webkit-scrollbar {
      display: none;
    }
  `;
}

/**
 * 세로 스크롤바를 항상 표시한다
 */
export function showVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      width: 8px;
      background-color: transparent;
      display: block;
    }
    &::-webkit-scrollbar-thumb:vertical {
      background-color: ${COLOR.grayScale_400};
      border: 3px solid transparent;
      border-radius: 9px;
    }
  `;
}

/**
 *
 * showVerticalScrollBarAlways의 효과를 무효화
 */
export function disableSettingOfShowVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      display: none;
    }
  `;
}
