import React, { memo } from "react";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";
import TableForCounting from "@sellernote/_shared/src/components/table/TableForCounting";

import useInvoiceDetail from "./useInvoiceDetail";
import Styled from "./index.styles";

export interface InvoiceDetailListItem {
  no: number;
  packingNo: string;
  skuId: string;
  itemName: string;
  quantity: React.ReactNode;
}

function TotalCount({
  isWorking,
  isStatusOfScanningCompletedInvoice,
  totalCount,
}: {
  isWorking: boolean;
  isStatusOfScanningCompletedInvoice: boolean;
  totalCount: React.ReactNode;
}) {
  if (!isWorking && !isStatusOfScanningCompletedInvoice) return null;

  return <div className="total-count">{totalCount}</div>;
}

function InvoiceDetail({
  rowInfoToHighlight,
}: {
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
}) {
  const {
    isVisibleScrollbarForInvoiceDetailTable,

    isWorking,
    isStatusOfScanningCompletedInvoice,
    totalCount,
    invoiceDetailTableDataList,
  } = useInvoiceDetail();

  return (
    <Styled.invoiceDetail
      isVisibleScrollbar={isVisibleScrollbarForInvoiceDetailTable}
    >
      <TotalCount
        isWorking={isWorking}
        isStatusOfScanningCompletedInvoice={isStatusOfScanningCompletedInvoice}
        totalCount={totalCount}
      />
      <TableForCounting<InvoiceDetailListItem>
        rowInfoToHighlight={rowInfoToHighlight}
        className="invoice-detail-table"
        columnInfo={{
          no: {
            label: "NO.",
          },
          packingNo: {
            label: "포장번호",
            portion: 2,
          },
          skuId: {
            label: "SKU ID / 상품바코드",
            portion: 3,
          },
          itemName: {
            label: "상품명",
            portion: 4,
          },
          quantity: {
            label: "수량",
            portion: 2,
          },
        }}
        dataList={invoiceDetailTableDataList}
      />
    </Styled.invoiceDetail>
  );
}

export default memo(InvoiceDetail);
