import React, { useMemo, useRef, useState } from "react";

import { useDeviceDetect } from "@sellernote/_shared/src/utils/common/hook";

import { COLOR } from "../../styles/constants";

import Icon from "../Icon";
import Styled from "./index.styles";

export type ToolTipPosition =
  | "left"
  | "right"
  | "bottomLeft"
  | "bottomRight"
  | "topRight";

export type ToolTipDisplayType = "block" | "inline-block";

export interface ToolTipContentTitleAndDesc {
  type: "titleAndDesc";
  title: React.ReactNode;
  desc: React.ReactNode;
}

export interface ToolTipContentTitleOnly {
  type: "titleOnly";
  title: React.ReactNode;
}

export interface ToolTipContentItemDescOnly {
  type: "descOnly";
  desc: React.ReactNode;
}

export type ToolTipContentItem =
  | ToolTipContentTitleAndDesc
  | ToolTipContentTitleOnly
  | ToolTipContentItemDescOnly;

export type TriggerTargetInfo = {
  width: number;
  height: number;
};

/**
 * @author 변형준
 * @description
 * - 툴팁 트리거로 사용하고자 하는 대상을 감싸는 식으로 사용한다.
 */
export default function ToolTip({
  displayType,
  contentForDesktop,
  contentForMobile,
  children,
  toolTipBodyRef,
  className,
}: {
  displayType: ToolTipDisplayType;
  contentForDesktop: {
    content: ToolTipContentItem;
    position: ToolTipPosition;
    bodyWidth?: number; // 데스크탑에서 width를 정하고싶을때 사용
  };
  contentForMobile: {
    content?: ToolTipContentItem[];
    isSameToDesktop?: boolean; // contentForDesktop과 동일한 content인 경우, content 데이터 없이 true로 셋팅해서 사용한다.
  };
  children: React.ReactNode;
  toolTipBodyRef?: React.RefObject<HTMLDivElement>; // 상위 컴포넌트에서 Styled.bodyForDesktop에 접근하여 해당 위치를 조절하는 등의 용도로 사용
  className?: string;
}) {
  const { isMobile } = useDeviceDetect();

  const [isVisibleBody, setIsVisibleBody] = useState(false);

  /**
   * 툴팁 body의 정확한 위치를 찾기 위함이다.
   * triggerRef를 이용해서 trigeer의 height, width를 파악하여 triggerTargetInfo로 저장 -> triggerTargetInfo를 이용하여 툴팁 위치를 정한다.
   */
  const triggerRef = useRef(null as unknown as HTMLDivElement);
  const [triggerTargetInfo, setTriggerTargetInfo] = useState({
    width: 0,
    height: 0,
  });

  const toolTipBody = useMemo(getToolTipBody, [
    isVisibleBody,
    triggerTargetInfo,
    contentForDesktop,
    contentForMobile,
    isMobile,
  ]);

  function checkTriggerTarget() {
    if (triggerRef.current.firstChild) {
      setTriggerTargetInfo({
        height: (triggerRef.current.firstChild as Element).clientHeight,
        width: (triggerRef.current.firstChild as Element).clientWidth,
      });
    }
  }

  function getToolTipBody() {
    if (!isVisibleBody) {
      return null;
    }

    if (isMobile) {
      return (
        <Styled.bodyForMobile
          onClick={(e) => {
            e.stopPropagation();
            setIsVisibleBody(false);
          }}
        >
          <div
            className="body-container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon
              type="clear"
              size={1}
              color={COLOR.grayScale_700}
              onClick={(e) => {
                e.stopPropagation();
                setIsVisibleBody(false);
              }}
            />

            <div className="content">
              {contentForMobile.isSameToDesktop && (
                <div className="item">
                  {(contentForDesktop.content.type === "titleOnly" ||
                    contentForDesktop.content.type === "titleAndDesc") && (
                    <div className="title">
                      ・{contentForDesktop.content.title}
                    </div>
                  )}

                  {(contentForDesktop.content.type === "descOnly" ||
                    contentForDesktop.content.type === "titleAndDesc") && (
                    <div className="desc">{contentForDesktop.content.desc}</div>
                  )}
                </div>
              )}

              {!contentForMobile.isSameToDesktop &&
                contentForMobile.content &&
                contentForMobile.content.map((v, i) => (
                  <div className="item" key={i}>
                    {(v.type === "titleOnly" || v.type === "titleAndDesc") && (
                      <div className="title">・{v.title}</div>
                    )}

                    {(v.type === "descOnly" || v.type === "titleAndDesc") && (
                      <div className="desc">{v.desc}</div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Styled.bodyForMobile>
      );
    } else {
      return (
        <Styled.bodyForDesktop
          ref={toolTipBodyRef}
          position={contentForDesktop.position}
          triggerTargetInfo={triggerTargetInfo}
          width={contentForDesktop.bodyWidth}
          type={contentForDesktop.content.type}
          className={`body-for-desktop ${
            contentForDesktop.position === "bottomLeft" ? "bottom-left" : ""
          }`}
        >
          {(contentForDesktop.content.type === "titleOnly" ||
            contentForDesktop.content.type === "titleAndDesc") && (
            <div className="title">{contentForDesktop.content.title}</div>
          )}

          {(contentForDesktop.content.type === "descOnly" ||
            contentForDesktop.content.type === "titleAndDesc") && (
            <div className="desc">{contentForDesktop.content.desc}</div>
          )}
        </Styled.bodyForDesktop>
      );
    }
  }

  return (
    <Styled.container
      className={`${className ? className : ""} tool-tip`}
      onMouseEnter={() => setIsVisibleBody(true)}
      onMouseLeave={() => setIsVisibleBody(false)}
      onClick={() => setIsVisibleBody(true)}
      displayType={displayType}
    >
      <div
        ref={triggerRef}
        className="trigger"
        onMouseEnter={checkTriggerTarget}
        onClick={checkTriggerTarget}
      >
        {children}
      </div>

      {toolTipBody}
    </Styled.container>
  );
}
