import {
  GET_BUYER_INFO_RES,
  GET_LINKED_SHIPPING_MALL_LIST_RES,
  GET_SELLER_ORDER_LIST_RES,
  GET_SELLER_SKU_LIST_RES,
} from "../../../api-interfaces/boful-api/order";
import { CONFIRM_INLAND_RES } from "../../../api-interfaces/shipda-api/trello";

export const GET_SELLER_SKU_LIST: GET_SELLER_SKU_LIST_RES = {
  total: 19,
  availableCount: 1,
  list: [
    {
      id: 1665,
      mall: "coupang",
      productId: "5998563392",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "A129",
      optionName: "네이비 XS",
      optionManageCode: "78131222145",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1664,
      mall: "coupang",
      productId: "5998563392",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "B128",
      optionName: "네이비 S",
      optionManageCode: "78131222098",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1660,
      mall: "coupang",
      productId: "5998563392",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "A126",
      optionName: "옐로우 L",
      optionManageCode: "78131222124",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1658,
      mall: "coupang",
      productId: "5998563393",
      productName: "강아지 인형",
      sellerProductCode: "A124",
      optionName: "옐로우 S",
      optionManageCode: "78131222315",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1656,
      mall: "coupang",
      productId: "5998563392",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "B127",
      optionName: "네이비 3XL",
      optionManageCode: "78131222370",
      integratedAt: undefined,
      sku: undefined,
    },

    {
      id: 1610,
      mall: "eleven",
      productId: "1234",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "A129",
      optionName: "네이비 XS",
      optionManageCode: "78131222145",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1617,
      mall: "eleven",
      productId: "4567",
      productName: "강아지 인형",
      sellerProductCode: "A124",
      optionName: "옐로우 S",
      optionManageCode: "78131222315",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1618,
      mall: "eleven",
      productId: "4567",
      productName: "강아지 인형",
      sellerProductCode: "A123",
      optionName: "옐로우 XS",
      optionManageCode: "78131222247",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1630,
      mall: "eleven",
      productId: "489",
      productName: "고양이 인형",
      sellerProductCode: "A124",
      optionName: "옐로우 S",
      optionManageCode: "78131222315",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1631,
      mall: "eleven",
      productId: "489",
      productName: "고양이 인형",
      sellerProductCode: "A123",
      optionName: "옐로우 XS",
      optionManageCode: "78131222247",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1619,
      mall: "eleven",
      productId: "1234",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "B127",
      optionName: "네이비 3XL",
      optionManageCode: "78131222370",
      integratedAt: undefined,
      sku: undefined,
    },

    {
      id: 9090,
      mall: "auction",
      productId: "7070",
      productName: "뭔가 한개",
      sellerProductCode: "B127",
      optionName: "네이비 3XL",
      optionManageCode: "78131222370",
      integratedAt: undefined,
      sku: undefined,
    },

    {
      id: 1010,
      mall: "lotteOn",
      productId: "1114",
      productName: "뭔가 두개",
      sellerProductCode: "B127",
      optionName: "네이비 3XL",
      optionManageCode: "78131222370",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 1011,
      mall: "lotteOn",
      productId: "1114",
      productName: "뭔가 두개",
      sellerProductCode: "B127",
      optionName: "네이비 33XL",
      optionManageCode: "78131222371",
      integratedAt: "2021-11-18T06:15:49.000Z",
      sku: {
        id: 1234,
        productCode: "A1101010",
        managementCode: "Aad0f0sdf0",
      },
    },

    {
      id: 1111,
      mall: "gmarket",
      productId: "9999",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "A126",
      optionName: "옐로우 L",
      optionManageCode: "78131222124",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 2222,
      mall: "gmarket",
      productId: "9999",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "A125",
      optionName: "옐로우 M",
      optionManageCode: "78131222163",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 3333,
      mall: "gmarket",
      productId: "7777",
      productName: "강아지 인형",
      sellerProductCode: "A124",
      optionName: "옐로우 S",
      optionManageCode: "78131222315",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 4444,
      mall: "gmarket",
      productId: "7777",
      productName: "강아지 인형",
      sellerProductCode: "A123",
      optionName: "옐로우 XS",
      optionManageCode: "78131222247",
      integratedAt: undefined,
      sku: undefined,
    },
    {
      id: 5555,
      mall: "gmarket",
      productId: "9999",
      productName: "강아지 산책용 우비 레인코트 MG014",
      sellerProductCode: "B127",
      optionName: "네이비 3XL",
      optionManageCode: "78131222370",
      integratedAt: undefined,
      sku: undefined,
    },
  ],
};

export const GET_SELLER_ORDER_LIST: GET_SELLER_ORDER_LIST_RES = {
  total: 14,
  list: [
    {
      id: 18,
      mall: "coupang",
      hopeDeliveryDate: "2021-12-03T00:00:00.000Z",
      memo: "문 앞",
      skuStatus: "beforeLink",
      items: [
        {
          quantity: 1,
          itemListId: 1,
          productId: "6129918280",
          itemOption: "중형40매(26-36인치)",
          optionCode: "78956361038",
          productName:
            "유케어 성인용 팬티기저귀 중대형 대용량 40매 요실금 언더웨어 여자 남자",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2930662283",
          count: 0,
        },
      ],
      orderId: "23000121050973",
      orderedAt: "2021-11-25T05:37:02.000Z",
    },
    {
      id: 17,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "문 앞",
      skuStatus: "error",
      items: [
        {
          quantity: 1,
          itemListId: 2,
          lackCount: 1,
          productId: "6165260745",
          itemOption: "단일상품",
          optionCode: "78160023266",
          productName: "유케어 위생깔개매트 방수 안심 50매 특대형 90x60cm",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2930424846",
          count: 1,
        },
      ],
      orderId: "4000121288380",
      orderedAt: "2021-11-25T04:03:44.000Z",
    },
    {
      id: 16,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "문 앞",
      skuStatus: "waiting",
      items: [
        {
          quantity: 1,
          itemListId: 3,
          productId: "6129918280",
          itemOption: "중형40매(26-36인치)",
          optionCode: "78956361038",
          productName:
            "유케어 성인용 팬티기저귀 중대형 대용량 40매 요실금 언더웨어 여자 남자",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2930343707",
          count: 2,
        },
      ],
      orderId: "18000121001196",
      orderedAt: "2021-11-25T03:32:03.000Z",
    },
    {
      id: 15,
      mall: "coupang",
      hopeDeliveryDate: "2021-12-03T00:00:00.000Z",
      memo: "6층 간호사실",
      skuStatus: "inProgress",
      items: [
        {
          quantity: 1,
          itemListId: 4,
          productId: "6129918280",
          itemOption: "대형40매(36-45인치)",
          optionCode: "78956361027",
          productName:
            "유케어 성인용 팬티기저귀 중대형 대용량 40매 요실금 언더웨어 여자 남자",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2930101835",
          count: 3,
        },
      ],
      orderId: "11000120224503",
      orderedAt: "2021-11-25T01:54:52.000Z",
    },
    {
      id: 14,
      mall: "coupang",
      hopeDeliveryDate: "2021-12-03T00:00:00.000Z",
      memo: "문 앞",
      skuStatus: "close",
      items: [
        {
          quantity: 1,
          itemListId: 5,
          productId: "6165260745",
          itemOption: "단일상품",
          optionCode: "78160023266",
          productName: "유케어 위생깔개매트 방수 안심 50매 특대형 90x60cm",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2929991987",
          count: 4,
        },
      ],
      orderId: "23000121029590",
      orderedAt: "2021-11-25T01:09:28.000Z",
    },
    {
      id: 13,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "문 앞",
      skuStatus: "done",
      items: [
        {
          quantity: 1,
          itemListId: 6,
          productId: "6165260745",
          itemOption: "단일상품",
          optionCode: "78160023266",
          productName: "유케어 위생깔개매트 방수 안심 50매 특대형 90x60cm",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2929685315",
          count: 5,
        },
      ],
      orderId: "19000120028370",
      orderedAt: "2021-11-24T22:55:07.000Z",
    },
    {
      id: 12,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "직접 받고 부재 시 문 앞",
      skuStatus: "cancel",
      items: [
        {
          quantity: 1,
          itemListId: 7,
          productId: "5988689562",
          itemOption: "중형(26-32인치) 블루",
          optionCode: "78086832116",
          productName: "유케어 방수 성인용기저귀커버 중형 대형 (남녀공용)",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2929558386",
          count: 6,
        },
      ],
      orderId: "12000120724581",
      orderedAt: "2021-11-24T21:00:04.000Z",
    },
    {
      id: 11,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "골목 들어와서 왼쪽으로 창고옆 박스안~~부탁드려요 ^^",
      skuStatus: "return",
      items: [
        {
          quantity: 1,
          itemListId: 8,
          productId: "6129918280",
          itemOption: "중형40매(26-36인치)",
          optionCode: "78956361038",
          productName:
            "유케어 성인용 팬티기저귀 중대형 대용량 40매 요실금 언더웨어 여자 남자",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2928573627",
          count: 7,
        },
      ],
      orderId: "19000119991551",
      orderedAt: "2021-11-24T12:07:19.000Z",
    },
    {
      id: 10,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "직접 받고 부재 시 문 앞",
      skuStatus: "returnDone",
      items: [
        {
          quantity: 1,
          itemListId: 9,
          productId: "6129918280",
          itemOption: "중형40매(26-36인치)",
          optionCode: "78956361038",
          productName:
            "유케어 성인용 팬티기저귀 중대형 대용량 40매 요실금 언더웨어 여자 남자",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-25T09:08:13.785Z",
          productOrderId: "2928027193",
          count: 8,
        },
      ],
      orderId: "20000120519729",
      orderedAt: "2021-11-24T09:22:30.000Z",
    },
    {
      id: 7,
      mall: "coupang",
      hopeDeliveryDate: undefined,
      memo: "직접 받고 부재 시 문 앞",
      skuStatus: "beforeLink",
      items: [
        {
          quantity: 1,
          itemListId: 10,
          productId: "5988689562",
          itemOption: "대형(33-40인치) 핑크",
          optionCode: "78086832156",
          productName: "유케어 방수 성인용기저귀커버 중형 대형 (남녀공용)",
          sellerProductCode: undefined,
          skuId: 2000008,
          integratedAt: "2021-11-25T05:55:55.310Z",
          productOrderId: "2908970240",
          count: 9,
        },
        {
          quantity: 1,
          itemListId: 11,
          productId: "5988689562",
          itemOption: "대형(33-40인치) 핑크",
          optionCode: "78086832156",
          productName: "유케어 방수 성인용기저귀커버 중형 대형 (남녀공용)",
          sellerProductCode: undefined,
          skuId: 2000008,
          integratedAt: "2021-11-25T05:55:55.310Z",
          productOrderId: "2908970240",
          count: 10,
        },
      ],
      orderId: "17000120359857",
      orderedAt: "2021-11-18T06:15:49.000Z",
    },
    {
      id: 2,
      mall: "eleven",
      hopeDeliveryDate: "2021-12-23T15:00:00.000Z",
      memo: "",
      skuStatus: "beforeLink",
      items: [
        {
          quantity: 1,
          itemListId: 12,
          productId: "강아지 산책용 우비 레인코트 MG014",
          itemOption: "컬러:옐로우,사이즈:S-1개",
          optionCode: "14316526835",
          productName: "강아지 산책용 우비 레인코트 MG014",
          skuId: undefined,
          integratedAt: "2021-11-02T21:26:04.708Z",
          productOrderId: "14316526835",
          count: 11,
        },
        {
          quantity: 1,
          itemListId: 13,
          productId: "강아지 산책용 우비 레인코트 MG014",
          itemOption: "컬러:옐로우,사이즈:S-1개",
          optionCode: "14316526835",
          productName: "강아지 산책용 우비 레인코트 MG014",
          skuId: undefined,
          integratedAt: "2021-11-02T21:26:04.708Z",
          productOrderId: "14316526835",
          count: 11,
        },
        {
          quantity: 1,
          itemListId: 14,
          productId: "강아지 산책용 우비 레인코트 MG014",
          itemOption: "컬러:옐로우,사이즈:S-1개",
          optionCode: "14316526835",
          productName: "강아지 산책용 우비 레인코트 MG014",
          skuId: undefined,
          integratedAt: "2021-11-02T21:26:04.708Z",
          productOrderId: "14316526835",
          count: 11,
        },
        {
          quantity: 1,
          itemListId: 15,
          productId: "강아지 산책용 우비 레인코트 MG014",
          itemOption: "컬러:옐로우,사이즈:S-1개",
          optionCode: "14316526835",
          productName: "강아지 산책용 우비 레인코트 MG014",
          skuId: undefined,
          integratedAt: "2021-11-02T21:26:04.708Z",
          productOrderId: "14316526835",
          count: 11,
        },
      ],
      orderId: "김종우",
      orderedAt: "2021-11-03T05:59:36.000Z",
    },
    {
      id: 1,
      mall: "eleven",
      hopeDeliveryDate: "2021-12-03T00:00:00.000Z",
      memo: "",
      skuStatus: "beforeLink",
      items: [
        {
          quantity: 1,
          itemListId: 16,
          productId: "5988689562",
          itemOption: "대형(33-40인치) 핑크",
          optionCode: "78086832156",
          productName: "유케어 방수 성인용기저귀커버 중형 대형 (남녀공용)",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-02T21:26:04.708Z",
          productOrderId: "14316526827",
          count: 12,
        },
        {
          quantity: 1,
          itemListId: 17,
          productId: "5988689562",
          itemOption: "대형(33-40인치) 핑크",
          optionCode: "78086832156",
          productName: "유케어 방수 성인용기저귀커버 중형 대형 (남녀공용)",
          sellerProductCode: undefined,
          skuId: undefined,
          integratedAt: "2021-11-02T21:26:04.708Z",
          productOrderId: "14316526827",
          count: 13,
        },
      ],
      orderId: "김종우",
      orderedAt: "2021-11-03T05:59:36.000Z",
    },
  ],
};

export const GET_BUYER_INFO: GET_BUYER_INFO_RES = {
  receiverName: "가나다라마바사아자차카차나자라",
  buyerPhone: "02-111-2202",
  receiverAddress: "서울 어쩌고 저쩌고 가나다라마 바사자아",
  receiverDetailAddress: "옆집 혹은 앞 혹은 뒤 혹은 아래 어딘가에 있을",
};

export const GET_LINKED_SHIPPING_MALL_LIST_TEST: GET_LINKED_SHIPPING_MALL_LIST_RES =
  [
    {
      id: 1,
      mall: "cafe24",
      accountId: "ASDFASDFASDFASDF",
      createdAt: "2022/03/22",
      deletedAt: "2023/03/22",
    },
    {
      id: 2,
      mall: "smartStore",
      accountId: "123123123123123",
      createdAt: "2022/03/22",
    },
    {
      id: 3,
      mall: "auction",
      accountId: "ASDFASDFASDFASDFASDFASDFASDF",
    },
  ];

export const CONFIRM_OAUTH_CAFE24_RESULT: CONFIRM_INLAND_RES = {
  result: 200,
};
