import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/styles/constants";
import { setFontStyle } from "@sellernote/_shared/src/styles/typography";

const StrongStyle = css`
  ${setFontStyle("Head6", "Bold")};
`;

const statusBar = styled.div<{ isWorking: boolean }>`
  height: 40px;
  padding: 8px 24px;
  color: ${COLOR.white};
  display: flex;
  justify-content: space-between;

  ${({ isWorking }) =>
    isWorking
      ? css`
          background-color: ${COLOR.pointWarning};
        `
      : css`
          background-color: ${COLOR.pointSuccess};
        `};
`;

const workingStatus = styled.strong`
  ${StrongStyle}
`;

const workingTableAndClock = styled.div`
  > strong {
    ${StrongStyle}
  }

  > .separator {
    margin: 0 10px;
  }
`;

const clock = styled.span`
  ${setFontStyle("Head6")};
`;

export default {
  statusBar,
  workingStatus,
  workingTableAndClock,
  clock,
};
