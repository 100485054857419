import React from "react";

import { PackingLabelData } from "@sellernote/_shared/src/types/fulfillment/packing";

import Styled from "./index.styles";

export default function PackingLabel({
  packingLabelData: {
    packingNo,
    total,
    orderNo,
    customerName,
    customerPhone,
    invoiceNo,
    items,
  },
}: {
  packingLabelData: PackingLabelData;
}) {
  return (
    <>
      <Styled.container>
        <div className="packing-no">
          포장번호: {packingNo} / {total}
        </div>

        <div className="order-info">
          주문번호 / 받는 분 정보: {orderNo} / {customerName} / {customerPhone}
        </div>

        <div className="code">코드: {invoiceNo}</div>

        <table>
          <thead>
            <tr>
              <th>목록</th>
              <th>상품명</th>
              <th>수량</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => (
              <tr key={item.productName + i}>
                <td>{i + 1}</td>
                <td>{item.productName}</td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Styled.container>

      <Styled.pageBreakWrapper>&nbsp;</Styled.pageBreakWrapper>
    </>
  );
}
