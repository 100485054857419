import styled from "styled-components";

const pageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;

const container = styled.div`
  padding: 0.5cm;
  font-size: 10pt;

  > .packing-no {
    text-align: right;
    font-size: 12pt;
    font-weight: 700;
    margin-bottom: 0.5cm;
  }

  > .order-info,
  .code {
    text-align: center;
    font-size: 11pt;
    margin-bottom: 0.3cm;
  }

  > table {
    width: 100%;

    th,
    td {
      border: 1px solid;
      padding: 8px;
    }

    th:first-child,
    th:last-child {
      width: 2cm;
    }
  }
`;

export default {
  pageBreakWrapper,
  container,
};
