import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";

export const getShippingTrackingInfoOfParcel = rest.get(
  getBofulDevAPI("/returning/user/tracking/parcel"),

  (req, res, ctx) => {
    return res(
      ctx.status(404),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 404 })
    );
  }
);
