import React from "react";
import { Trans, useTranslation } from "react-i18next";

import _SampleWithI18n from "@sellernote/_shared/src/components/_SampleWithI18n";

import withRequireAuth from "hocs/withRequireAuth";

import Styled from "./index.styles";

function Sample() {
  const { t } = useTranslation(["common"]);

  return (
    <Styled.container>
      <p>{t("common:logout")}</p>
      <_SampleWithI18n Trans={Trans} useTranslation={useTranslation} />
    </Styled.container>
  );
}

export default withRequireAuth(Sample);
