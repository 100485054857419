import React, { memo } from "react";

import Barcode from "@sellernote/_shared/src/components/Barcode";
import Button from "@sellernote/_shared/src/components/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/components/table/Table";

import PrintInvoiceModal from "../PrintInvoiceModal";
import useCompletePacking from "./useCompletePacking";
import Styled from "./index.styles";

function CompletePacking({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const {
    checkHasOutSidePackage,
    isActiveCompletePackingButton,

    isVisiblePrintInvoiceModal,
    handlePrintInvoiceModalClose,

    CheckHasOutSidePackageModal,
    CompletePackingModal,
    CheckPrintPackingLabel,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfCompletingPacking,
  } = useCompletePacking({ setRowInfoToHighlight });

  return (
    <>
      <Styled.completePacking>
        <Barcode
          value="COM_PAC"
          options={{
            displayValue: false,
            margin: 0,
          }}
        />
        <Button
          theme="danger"
          size="block"
          label="포장마감"
          handleClick={checkHasOutSidePackage}
          disabled={!isActiveCompletePackingButton}
        />
      </Styled.completePacking>

      <PrintInvoiceModal
        active={isVisiblePrintInvoiceModal}
        onClose={handlePrintInvoiceModalClose}
      />

      {CheckHasOutSidePackageModal}
      {CompletePackingModal}
      {CheckPrintPackingLabel}

      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfCompletingPacking}
    </>
  );
}

export default memo(CompletePacking);
