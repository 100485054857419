import { PackingInvoice } from "../../../types/fulfillment/packing";

export const START_PACKING_FOR_ISSUING_INVOICE: PackingInvoice = {
  id: 2576,
  shippingStatus: "close",
  wmsStatus: "ready",
  deliveringStatus: "notSent",
  userId: 399,
  managerId: 1,
  pickerId: 1,
  packerId: 1,
  shipperId: undefined,
  restockerId: undefined,
  warehouseId: 1,
  dueDate: "2022-02-15T00:00:00.000Z",
  shipmentDate: "2022-03-29T05:30:11.000Z",
  deliveryType: "parcel",
  orderNo: "1111",
  channelId: 1,
  customerName: "분할입고 테스트",
  customerPhone: "01012334567",
  customerAddress: "대전 동구 판교1길 3 (판암동)",
  customerDetailAddress: "1",
  customerPostalCode: "34672",
  senderName: "분할입고 테스트",
  senderPhone: "01011111111",
  senderAddress: "인천광역시 인천읍",
  senderDetailAddress: "인천군",
  senderPostalCode: "22222",
  parcelOrder: "firstOrder",
  invoiceNo: "pbsxmU57zzXCRpNjU7IbA",
  isIssue: false,
  isConfirm: false,
  sentAt: undefined,
  parcelCompany: "cj",
  truckCompany: undefined,
  endedPickingAt: "2022-03-11T08:49:18.000Z",
  memo: [],
  createdAt: "2022-02-14T05:07:04.757Z",
  updatedAt: "2022-03-29T06:47:10.000Z",
  deletedAt: undefined,
  items: [
    {
      id: 1150,
      skuId: 437,
      shippingId: 2576,
      locationId: 2,
      quantity: 5,
      currentQty: 0,
      createdAt: "2022-02-14T05:07:04.762Z",
      updatedAt: "2022-02-14T05:07:04.762Z",
      deletedAt: undefined,
      sku: {
        id: 437,
        barCode: "111",
        itemName: "테스트",
        totalInventory: {
          availableQty: 30,
          faultyQty: 10,
          inWorkingQty: 10,
          totalQty: 10,
        },
        productCode: "xnbdq23fdn",
        managementCode: "code",
        userId: 399,
        bidId: 1,
        category: "asdf",
        buyerId: 1,
        buyingURL: undefined,
        materialPackageType: "none",
        returningCondition: "openNotUsed",
        createdAt: "2022-02-08T01:03:53.568Z",
        updatedAt: "2022-02-18T09:10:03.000Z",
        deletedAt: undefined,
        packages: [],
      },
    },
    {
      id: 1151,
      skuId: 437,
      shippingId: 2576,
      locationId: 3,
      quantity: 7,
      currentQty: 0,
      createdAt: "2022-02-14T05:07:04.839Z",
      updatedAt: "2022-02-14T05:07:04.839Z",
      deletedAt: undefined,
      sku: {
        id: 437,
        barCode: "222",
        itemName: "테스트",
        productCode: "xnbdq23fdn",
        managementCode: "asdf",
        totalInventory: {
          availableQty: 30,
          faultyQty: 10,
          inWorkingQty: 10,
          totalQty: 10,
        },
        userId: 399,
        bidId: 1,
        category: "asdf",
        buyerId: 2,
        buyingURL: undefined,
        materialPackageType: "none",
        returningCondition: "openNotUsed",
        createdAt: "2022-02-08T01:03:53.568Z",
        updatedAt: "2022-02-18T09:10:03.000Z",
        deletedAt: undefined,
        packages: [],
      },
    },
    {
      id: 1157,
      skuId: 2,
      shippingId: 2576,
      locationId: 2,
      quantity: 2,
      currentQty: 0,
      createdAt: "2022-02-15T08:55:28.608Z",
      updatedAt: "2022-02-15T08:55:28.608Z",
      deletedAt: undefined,
      sku: {
        id: 2,
        barCode: "333",
        itemName: "러프웨어",
        productCode: "B23323",
        managementCode: "A234234",
        totalInventory: {
          availableQty: 30,
          faultyQty: 10,
          inWorkingQty: 10,
          totalQty: 10,
        },
        userId: 399,
        bidId: 1,
        category: "애견용품",
        buyerId: 1,
        buyingURL: undefined,
        materialPackageType: "none",
        returningCondition: "openNotUsed",
        createdAt: "2021-05-13T06:48:09.602Z",
        updatedAt: "2022-02-21T02:09:17.000Z",
        deletedAt: undefined,
        packages: [],
      },
    },
    {
      id: 1160,
      skuId: 1,
      shippingId: 2576,
      locationId: 2,
      quantity: 2,
      currentQty: 0,
      createdAt: "2022-02-15T08:56:57.865Z",
      updatedAt: "2022-02-15T08:56:57.865Z",
      deletedAt: undefined,
      sku: {
        id: 1,
        barCode: "444",
        itemName: "텀블러",
        productCode: "K4949",
        managementCode: "A123123",
        userId: 399,
        totalInventory: {
          availableQty: 30,
          faultyQty: 10,
          inWorkingQty: 10,
          totalQty: 10,
        },
        bidId: 1,
        category: "생황용품",
        buyerId: 1,
        buyingURL: undefined,
        materialPackageType: "basic",
        returningCondition: "openNotUsed",
        createdAt: "2021-05-13T06:47:33.460Z",
        updatedAt: "2022-03-29T06:46:46.000Z",
        deletedAt: undefined,
        packages: [
          {
            id: 50,
            isCustom: false,
            packingItemId: undefined,
            buyerId: undefined,
            buyingURL: undefined,
            maximumLoadWeight: undefined,
            name: "일반박스",
            packingName: "asdf",
            description: "asdf",
            materialCode: "GB",
            managementCode: undefined,
            barCode: undefined,
            packageCategory: "outside",
            packageType: "box",
            typeDirectInput: undefined,
            outerType: "none",
            width: undefined,
            length: undefined,
            height: undefined,
            provider: "shipda",
            userId: undefined,
            warehouseId: 1,
            createdAt: "2021-10-12T07:05:05.814Z",
            updatedAt: "2021-11-25T04:39:04.273Z",
            deletedAt: undefined,
          },
          {
            id: 53,
            packingItemId: undefined,
            buyerId: undefined,
            buyingURL: undefined,
            maximumLoadWeight: undefined,
            name: "에어캡",
            packingName: "asdf",
            description: "asdf",
            materialCode: "AC",
            managementCode: "",
            barCode: undefined,
            packageCategory: "buffer",
            packageType: "airCap",
            typeDirectInput: undefined,
            outerType: "none",
            width: undefined,
            length: undefined,
            height: undefined,
            provider: "shipda",
            userId: undefined,
            warehouseId: 1,
            isCustom: false,
            createdAt: "2021-10-12T07:09:28.221Z",
            updatedAt: "2021-11-25T04:39:04.359Z",
            deletedAt: undefined,
          },
          {
            id: 55,
            packingItemId: undefined,
            buyerId: undefined,
            buyingURL: undefined,
            maximumLoadWeight: undefined,
            name: "OPP 테이프",
            packingName: "asdf",
            description: "undefined",
            materialCode: "OP",
            managementCode: undefined,
            barCode: undefined,
            packageCategory: "tape",
            packageType: "oppTape",
            typeDirectInput: undefined,
            outerType: "none",
            width: undefined,
            length: undefined,
            height: undefined,
            provider: "shipda",
            userId: undefined,
            warehouseId: 1,
            isCustom: false,
            createdAt: "2021-10-12T07:15:36.394Z",
            updatedAt: "2021-11-25T04:39:04.421Z",
            deletedAt: undefined,
          },
        ],
      },
    },
  ],
  packings: [
    {
      id: 531,
      invoiceNo: "pbsxmU57zzXCRpNjU7IbA",
      shippingId: 2576,
      outerPackagesId: 50,
      createdAt: "2022-02-23T01:23:53.638Z",
      updatedAt: "2022-03-29T06:45:04.000Z",
      deletedAt: undefined,
      packingToSku: [
        {
          id: 1,
          packingId: 531,
          stockUnitId: 437,
          qty: 5,
        },
        {
          id: 2,
          packingId: 531,
          stockUnitId: 2,
          qty: 2,
        },
      ],
    },
    {
      id: 663,
      invoiceNo: "22222",
      shippingId: 2576,
      outerPackagesId: 98,
      createdAt: "2022-03-29T06:47:22.409Z",
      updatedAt: "2022-03-29T06:47:22.409Z",
      deletedAt: undefined,
      packingToSku: [
        {
          id: 3,
          packingId: 663,
          stockUnitId: 437,
          qty: 3,
        },
        {
          id: 4,
          packingId: 663,
          stockUnitId: 1,
          qty: 1,
        },
      ],
    },
    {
      id: 664,
      invoiceNo: "3333333",
      shippingId: 2576,
      outerPackagesId: 29,
      createdAt: "2022-03-29T06:47:28.537Z",
      updatedAt: "2022-03-29T06:47:28.537Z",
      deletedAt: undefined,
      packingToSku: [
        {
          id: 5,
          packingId: 664,
          stockUnitId: 437,
          qty: 1,
        },
        {
          id: 6,
          packingId: 664,
          stockUnitId: 1,
          qty: 1,
        },
      ],
    },
    {
      id: 665,
      invoiceNo: "4444",
      shippingId: 2576,
      outerPackagesId: 98,
      createdAt: "2022-03-29T06:47:28.537Z",
      updatedAt: "2022-03-29T06:47:28.537Z",
      deletedAt: undefined,
      packingToSku: [
        {
          id: 7,
          packingId: 665,
          stockUnitId: 437,
          qty: 1,
        },
      ],
    },
    {
      id: 666,
      invoiceNo: "5555555",
      shippingId: 2576,
      outerPackagesId: 98,
      createdAt: "2022-03-29T06:47:28.537Z",
      updatedAt: "2022-03-29T06:47:28.537Z",
      deletedAt: undefined,
      packingToSku: [
        {
          id: 8,
          packingId: 666,
          stockUnitId: 437,
          qty: 1,
        },
      ],
    },
    {
      id: 667,
      invoiceNo: "666666",
      shippingId: 2576,
      outerPackagesId: 98,
      createdAt: "2022-03-29T06:47:28.537Z",
      updatedAt: "2022-03-29T06:47:28.537Z",
      deletedAt: undefined,
      packingToSku: [
        {
          id: 9,
          packingId: 667,
          stockUnitId: 437,
          qty: 1,
        },
      ],
    },
  ],
  user: {
    id: 399,
    email: "qus@ship-da.com",
    name: "aaa",
    phone: "01011111111",
    company: "수입하기정말쉽다",
  },
};

// 피킹 위치 변경(같은 상품이 다른 위치에 있다가 위치 변경으로 인해 위치가 같아지는 경우를 테스트하기 위한 데이터)
export const START_PICKING_FOR_CHANGE_PICKING_LOCATION = {
  id: 2591,
  shippingStatus: "inProgress",
  wmsStatus: "picking",
  deliveringStatus: "delivering",
  userId: 3217,
  managerId: 11,
  pickerId: 7,
  packerId: 7,
  printerWorkerId: 6,
  shipperId: 7,
  restockerId: null,
  truckingId: null,
  warehouseId: 1,
  dueDate: "2022-04-12T11:49:54.000Z",
  shipmentDate: "2022-04-13T12:30:04.000Z",
  deliveryType: "parcel",
  orderNo: "123456",
  channelId: 999,
  channel: "string",
  integratedType: "string",
  customerName: "송",
  customerPhone: "01011111111",
  customerAddress: "서울시 관악구 서원 6길 26",
  customerDetailAddress: "22",
  customerPostalCode: "08844",
  senderName: "요요",
  senderPhone: "010111111111",
  senderAddress: "인천광역시 중구 서해대로94번길 98-1",
  senderDetailAddress: "쉽다 인천1센터",
  senderPostalCode: "22343",
  parcelOrder: "firstOrder",
  invoiceNo: "650019245925",
  isIssue: false,
  isConfirm: true,
  sentAt: null,
  parcelCompany: null,
  truckCompany: null,
  endedPickingAt: null,
  printCount: 16,
  invoiceErrorMessage: null,
  printedAt: "2022-04-01T14:27:14.000Z",
  isStartScanning: true,
  isPacking: true,
  memo: [
    {
      memo: "string",
      category: "customer",
      createdAt: "2022-03-14T04:47:05.562Z",
    },
  ],
  createdAt: "2022-03-14T04:47:06.129Z",
  updatedAt: "2022-04-13T00:30:04.000Z",
  deletedAt: null,
  items: [
    {
      id: 1547,
      skuId: 430,
      shippingId: 2591,
      locationId: 14,
      quantity: 20,
      currentQty: 0,
      createdAt: "2022-04-05T01:28:12.056Z",
      updatedAt: "2022-04-13T01:18:58.344Z",
      deletedAt: null,
      location: {
        id: 14,
        warehouseId: 1,
        name: "MINI CELL",
        kind: null,
        allocatedUserId: null,
        barCode: "M01-01-02",
        createdAt: "2022-03-31T00:06:38.606Z",
        updatedAt: "2022-03-31T00:06:38.606Z",
        deletedAt: null,
      },
      sku: {
        id: 430,
        itemName: "아몬드 빼빼로 32g",
        productCode: "SD-001",
        managementCode: "02",
        barCode: "",
        userId: 3217,
        bidId: null,
        category: null,
        buyerId: null,
        buyingURL: null,
        materialPackageType: "basic",
        returningCondition: "openNotUsed",
        createdAt: "2022-01-26T01:55:47.646Z",
        updatedAt: "2022-01-26T01:55:47.646Z",
        deletedAt: null,
      },
    },
    {
      id: 1548,
      skuId: 430,
      shippingId: 2591,
      locationId: 13,
      quantity: 20,
      currentQty: 0,
      createdAt: "2022-04-05T01:28:14.849Z",
      updatedAt: "2022-04-13T01:18:58.366Z",
      deletedAt: null,
      location: {
        id: 13,
        warehouseId: 1,
        name: "SMALL CELL",
        kind: null,
        allocatedUserId: null,
        barCode: "M01-01-01",
        createdAt: "2022-03-31T00:06:38.591Z",
        updatedAt: "2022-03-31T00:06:38.591Z",
        deletedAt: null,
      },
      sku: {
        id: 430,
        itemName: "아몬드 빼빼로 32g",
        productCode: "SD-001",
        managementCode: "02",
        barCode: "",
        userId: 3217,
        bidId: null,
        category: null,
        buyerId: null,
        buyingURL: null,
        materialPackageType: "basic",
        returningCondition: "openNotUsed",
        createdAt: "2022-01-26T01:55:47.646Z",
        updatedAt: "2022-01-26T01:55:47.646Z",
        deletedAt: null,
      },
    },
  ],
  user: { company: "초롱컴퍼니" },
};
