import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useScanInvoice() {
  const setCanceledInvoiceNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );

  const {
    mutate: startPacking,
    reset: resetStartPacking,
    ResponseHandler: ResponseHandlerOfStartingPacking,
  } = PACKING_QUERY.useStartPacking({
    type: "scan",
  });

  const scanInvoice = useCallback(
    (scannedInvoiceNo: string) => {
      startPacking(
        // TODO: pathParams를 사용하는 방식으로 수정
        { _customPath: `/shipping/packing/start/${scannedInvoiceNo}` },
        {
          onError: ({ response }) => {
            const failureInfo = response?.data;
            if (
              failureInfo?.code === 400 &&
              failureInfo.errorCode === "E4002"
            ) {
              resetStartPacking(); // ResponseHandler의 에러 모달 대신 취소 전용 에러 모달을 보여주기 위함
              setCanceledInvoiceNo(scannedInvoiceNo);
            }
          },
        }
      );
    },
    [resetStartPacking, setCanceledInvoiceNo, startPacking]
  );

  useScanActionBarcode({
    actionBarcode: "H_STA_PAC",
    actionFn: resetStartPacking,
  });

  return { scanInvoice, ResponseHandlerOfStartingPacking };
}
