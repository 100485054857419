import { TFunction } from "react-i18next";

import Button from "@sellernote/_shared/src/components/button/Button";
import PageNotFoundImage from "@sellernote/_shared/src/images/error/page_not_found.png";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import Styled from "./index.styles";

export default function PageNotFound({
  t,
  goBackToMainPage,
}: {
  t?: TFunction;
  goBackToMainPage: () => void;
}) {
  const { isMobile } = useCheckIsMobile();

  return (
    <>
      <Styled.container>
        <Styled.img>
          <img src={PageNotFoundImage} alt="not-found-page" />
        </Styled.img>
        <Styled.textGroup>
          <div className="text-bold">PAGE NOT FOUND</div>
          <div className="text-normal">
            {t ? t("errors:404Desc") : "페이지를 찾을 수가 없습니다."}
          </div>
        </Styled.textGroup>
      </Styled.container>
      <Styled.backAction>
        <Button
          theme="primary"
          size={isMobile ? "normal" : "xLarge"}
          label={t ? t("errors:goBack") : "< 돌아가기"}
          handleClick={goBackToMainPage}
        />
      </Styled.backAction>
    </>
  );
}
