export default {
  password: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
  blank: /[\s]/,
  phone: /^01([016789]?)([0-9]{3,4})([0-9]{4})$/,
  phoneSG: /^[6|8|9]\d{7}$/,
  email:
    /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
  id: /^[a-z0-9]{6,20}$/,
  numberOrEng: /^[a-zA-Z0-9]*$/,
  whiteSpace: /[\s\u0020\u200B\u115F\u1160\u3164\uFFA0\u2800\u3000\u00a0]+/g,
  number: /[^0-9]/gi,
  special: /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g,
};
