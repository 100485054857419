import { datatype, date } from "faker";
import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";
import {
  IssueOptionList,
  ShippingDeliveryType,
} from "../../../types/fulfillment/shipping";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { createCustomData, createData, DB } from "../../db";
import {
  E4000,
  E4002,
  E4008,
  E4009,
} from "../../error/4000_BOFUL_SHIPPING_ERROR";
import {
  START_PACKING_FOR_ISSUING_INVOICE,
  START_PICKING_FOR_CHANGE_PICKING_LOCATION,
} from "./data";

export const getShipping = rest.get(
  getBofulDevAPI("/shipping"),
  (req, res, ctx) => {
    const data = createCustomData("풀필먼트_출고관리_의뢰확인_리스트_res");

    const filter: IssueOptionList =
      (req.url.searchParams.get("filter") as IssueOptionList) ?? "all";
    const perPage = Number(req.url.searchParams.get("perPage") ?? 10);
    const getFilteredList = (filter: IssueOptionList) => {
      switch (filter) {
        case "all":
          return DB.풀필먼트_출고관리_출고.findMany({ take: perPage });
        case "unIssue":
          return DB.풀필먼트_출고관리_출고.findMany({
            where: { isIssue: { equals: false } },
            take: perPage,
          });
        case "issue":
          return DB.풀필먼트_출고관리_출고.findMany({
            where: { isIssue: { equals: true } },
            take: perPage,
          });
      }
    };
    const filteredList = getFilteredList(filter);

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ ...data, list: filteredList })
    );
  }
);

export const issueShippingInvoice = rest.patch(
  getBofulDevAPI("/shipping/invoice"),

  (req, res, ctx) => {
    const data = createData("풀필먼트_출고관리_출고", {
      isIssue: true,
    });

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

export const moveToMyTask = rest.patch(
  getBofulDevAPI("/shipping/task"),

  (req, res, ctx) => {
    const data = { result: "Update shipping managerId: 1" };

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

export const getCompletedPickingList = rest.get(
  getBofulDevAPI("/shipping/pickings"),

  (req, res, ctx) => {
    const data = createCustomData("풀필먼트_출고관리_피킹완료_리스트_res");

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

export const scanPickingInvoice = rest.patch(
  getBofulDevAPI("/shipping/picking"),

  (req, res, ctx) => {
    const { invoiceNo } = req.body as { invoiceNo: string };

    switch (invoiceNo) {
      case "400":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json({ code: 400, error: "400에러" })
        );
      case "E4000":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4000)
        );
      case "E4002":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4002)
        );
      case "E4008":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4008)
        );
      case "E4009":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4009)
        );

      case "change":
        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(START_PICKING_FOR_CHANGE_PICKING_LOCATION)
        );

      default:
        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(
            createData("풀필먼트_출고관리_출고", {
              pickerId: 1,
              wmsStatus: "picking",
              barCode: invoiceNo,
              skuId: 1,
              locationId: 2,
            })
          )
        );
    }
  }
);

export const startPickingScanning = rest.patch(
  getBofulDevAPI("/shipping/picking/start/scanning/:id"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        createData("풀필먼트_출고관리_출고", {
          id: Number(req.url.searchParams.get("id")),
          isStartScanning: true,
        })
      )
    )
);

export const changePickingLocation = rest.patch(
  getBofulDevAPI(`/shipping/picking/location`),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        result: `ShippingItem Location Updated: 1`,
      })
    )
);

export const completePicking = rest.patch(
  getBofulDevAPI("/shipping/picking/complete/:id"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        createData("풀필먼트_출고관리_출고", {
          id: Number(req.url.searchParams.get("id")),
          endedPickingAt: date.past(),
        })
      )
    )
);

export const startPacking = rest.patch(
  getBofulDevAPI("/shipping/packing/start/:invoiceNo"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        // createData("풀필먼트_출고관리_출고", {
        //   pickerId: 1,
        //   packerId: 1,
        //   wmsStatus: "packing",
        //   barCode: req.url.searchParams.get("invoiceNo"),
        //   skuId: 1,
        //   locationId: 2,
        //   user: DB.풀필먼트_user.create(),
        // })
        START_PACKING_FOR_ISSUING_INVOICE
      )
    )
);

export const AddNextPacking = rest.patch(
  getBofulDevAPI("/shipping/packing/add"),

  (req, res, ctx) => {
    const { shippingId, deliveryType } = req.body as {
      shippingId?: number;
      deliveryType?: ShippingDeliveryType;
    };

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        invoiceNo: String(
          datatype.number({ min: 50000000000, max: 70000000000 })
        ),
        shippingId,
        deliveryType,
        outerPackagesId: null,
        deletedAt: null,
        id: datatype.number({ min: 1, max: 100000 }),
      })
    );
  }
);

export const deleteLastPacking = rest.delete(
  getBofulDevAPI("/shipping/packing/:invoiceNo"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ result: "delete PackingItem" })
    )
);

export const completePacking = rest.patch(
  getBofulDevAPI("/shipping/packing/complete/:id"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        createData("풀필먼트_출고관리_출고", {
          id: Number(req.url.searchParams.get("id")),
        })
      )
    )
);

export const numberInvoice = rest.post(
  getBofulDevAPI("/shipping/admin/invoice"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        totalCount: 30,
        successCount: 19,
        failedCount: 11,
        shippingIds: [1, 3, 2, 23],
      })
    );
  }
);

export const updateStatusWhenPrintingTransactionStatement = rest.post(
  getBofulDevAPI("/shipping/admin/print/statement"),
  (req, res, ctx) => {
    return res(
      // ctx.status(400),
      // ctx.delay(ARTIFICIAL_DELAY_MS),
      // ctx.json({ code: 400, error: "400에러" })
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS + 1500),
      ctx.json({
        total: 20,
        success: 18,
        fail: {
          ids: [2789, 5445],
          count: 2,
        },
      })
    );
  }
);

export const getInvoicePrintingData = rest.post(
  getBofulDevAPI("/shipping/admin/print"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        script:
          "^XA^FWR^POI^SEE:UHANGUL.DAT^FS^CW0,E:H2.FNT^CI26^FS^LH5,20^FO740,80^A0,30,30^FD{0}^FS^FO740,430^A0,19,19^FD{1}^FS^FO740,640^A0,19,19^FD{2}^FS^FO600,8^BY2,3,120^BC,,N,N^FD{3}^FS^FO600,350^A0,42,42^FD{4}^FS^FT^A0,85,85^FD{5}^FS^FT^A0,42,42^FD{6}^FS^FO560,40^A0,21,21^FD{7}^FS^FT^A0,21,21^FD{8}^FS^FO490,40^FB800,2^A0,21,21^FD{9}^FS^FO550,575^BY3^BC,40,N,N,N^FD>;{10}^FS^FO440,40^A0,42,42^FD{11}^FS^FO400,40^A0,17,17^FD{12}^FS^FO400,250^A0,17,17^FD{13}^FS^FO395,550^A0,21,21^FD{14}^FS^FO395,780^A0,21,21^FD{15}^FS^FO395,910^A0,21,21^FD{16}^FS^FO370,40^A0,17,17^FD{17}^FS^FO185,40^FB1000,8^A0,20,20^FD{18}^FS^LH0,0^FO75,40^A0,35,35^FD{19}^FS^FO55,40^A0,19,19^FD{20}^FS^FO25,40^A0,25,25^FD{21}^FS^FO25,420^A0,21,21^FD{22}^FS^FO25,570^A0,21,21^FD{23}^FS^FO30,645^BY3^BC,80,N,N,N^FD>;{24}^FS^FO7,715^A0,21,21^FD{24}^FS^XZ",
        value: [
          {
            "{0}": "1234-1234-5678",
            "{1}": "2020.01.01",
            "{2}": "1/1",
            "{3}": ">98K95",
            "{4}": "8",
            "{5}": "K95",
            "{6}": "-3cnt:1",
            "{7}": "홍길*",
            "{8}": "010-1234-****/010-1234-****",
            "{9}": "서울중구세종대로9길53[서소문동58-12]홍길동아파트101동201호",
            "{10}": "1234-1234-5678",
            "{11}": "홍길동아파트101동201호",
            "{12}": "송화인이름",
            "{13}": "012-123-1234",
            "{14}": "극소B1",
            "{15}": "0",
            "{16}": "신용",
            "{17}": "서울중구세종대로9길53[서소문동58-12]",
            "{18}":
              "테스트TEST상품정보ABCDEFG0000컬러[COLOR]:12345BK_블랙\\&테스트TEST상품정보ABCDEFG0001컬러[COLOR]:12345BK_화이트",
            "{19}": "서소문58-12대한통운",
            "{20}": "안내문구삽입",
            "{21}": "중구Bsub-홍길동-A01",
            "{22}": "0",
            "{23}": "신용",
            "{24}": "123412345678",
          },
          {
            "{0}": "1234-1234-5678",
            "{1}": "2020.01.01",
            "{2}": "1/1",
            "{3}": ">98K95",
            "{4}": "8",
            "{5}": "K95",
            "{6}": "-3cnt:1",
            "{7}": "홍길*",
            "{8}": "010-1234-****/010-1234-****",
            "{9}": "서울중구세종대로9길53[서소문동58-12]홍길동아파트101동201호",
            "{10}": "1234-1234-5678",
            "{11}": "홍길동아파트101동201호",
            "{12}": "송화인이름",
            "{13}": "012-123-1234",
            "{14}": "극소B1",
            "{15}": "0",
            "{16}": "신용",
            "{17}": "서울중구세종대로9길53[서소문동58-12]",
            "{18}":
              "테스트TEST상품정보ABCDEFG0000컬러[COLOR]:12345BK_블랙\\&테스트TEST상품정보ABCDEFG0001컬러[COLOR]:12345BK_화이트",
            "{19}": "서소문58-12대한통운",
            "{20}": "안내문구삽입",
            "{21}": "중구Bsub-홍길동-A01",
            "{22}": "0",
            "{23}": "신용",
            "{24}": "123412345678",
          },
        ],
      })
    );
  }
);

export const getInvoicePrintingDataForPacking = rest.post(
  getBofulDevAPI("/shipping/packing/print"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        script:
          "^XA^FWR^POI^SEE:UHANGUL.DAT^FS^CW0,E:H2.FNT^CI26^FS^LH5,20^FO740,80^A0,30,30^FD{0}^FS^FO740,430^A0,19,19^FD{1}^FS^FO740,640^A0,19,19^FD{2}^FS^FO600,8^BY2,3,120^BC,,N,N^FD{3}^FS^FO600,350^A0,42,42^FD{4}^FS^FT^A0,85,85^FD{5}^FS^FT^A0,42,42^FD{6}^FS^FO560,40^A0,21,21^FD{7}^FS^FT^A0,21,21^FD{8}^FS^FO490,40^FB800,2^A0,21,21^FD{9}^FS^FO550,575^BY3^BC,40,N,N,N^FD>;{10}^FS^FO440,40^A0,42,42^FD{11}^FS^FO400,40^A0,17,17^FD{12}^FS^FO400,250^A0,17,17^FD{13}^FS^FO395,550^A0,21,21^FD{14}^FS^FO395,780^A0,21,21^FD{15}^FS^FO395,910^A0,21,21^FD{16}^FS^FO370,40^A0,17,17^FD{17}^FS^FO185,40^FB1000,8^A0,20,20^FD{18}^FS^LH0,0^FO75,40^A0,35,35^FD{19}^FS^FO55,40^A0,19,19^FD{20}^FS^FO25,40^A0,25,25^FD{21}^FS^FO25,420^A0,21,21^FD{22}^FS^FO25,570^A0,21,21^FD{23}^FS^FO30,645^BY3^BC,80,N,N,N^FD>;{24}^FS^FO7,715^A0,21,21^FD{24}^FS^XZ",
        value: [
          {
            "{0}": "1234-1234-5678",
            "{1}": "2020.01.01",
            "{2}": "1/1",
            "{3}": ">98K95",
            "{4}": "8",
            "{5}": "K95",
            "{6}": "-3cnt:1",
            "{7}": "홍길*",
            "{8}": "010-1234-****/010-1234-****",
            "{9}": "서울중구세종대로9길53[서소문동58-12]홍길동아파트101동201호",
            "{10}": "1234-1234-5678",
            "{11}": "홍길동아파트101동201호",
            "{12}": "송화인이름",
            "{13}": "012-123-1234",
            "{14}": "극소B1",
            "{15}": "0",
            "{16}": "신용",
            "{17}": "서울중구세종대로9길53[서소문동58-12]",
            "{18}":
              "테스트TEST상품정보ABCDEFG0000컬러[COLOR]:12345BK_블랙\\&테스트TEST상품정보ABCDEFG0001컬러[COLOR]:12345BK_화이트",
            "{19}": "서소문58-12대한통운",
            "{20}": "안내문구삽입",
            "{21}": "중구Bsub-홍길동-A01",
            "{22}": "0",
            "{23}": "신용",
            "{24}": "123412345678",
          },
          {
            "{0}": "1234-1234-5678",
            "{1}": "2020.01.01",
            "{2}": "1/1",
            "{3}": ">98K95",
            "{4}": "8",
            "{5}": "K95",
            "{6}": "-3cnt:1",
            "{7}": "홍길*",
            "{8}": "010-1234-****/010-1234-****",
            "{9}": "서울중구세종대로9길53[서소문동58-12]홍길동아파트101동201호",
            "{10}": "1234-1234-5678",
            "{11}": "홍길동아파트101동201호",
            "{12}": "송화인이름",
            "{13}": "012-123-1234",
            "{14}": "극소B1",
            "{15}": "0",
            "{16}": "신용",
            "{17}": "서울중구세종대로9길53[서소문동58-12]",
            "{18}":
              "테스트TEST상품정보ABCDEFG0000컬러[COLOR]:12345BK_블랙\\&테스트TEST상품정보ABCDEFG0001컬러[COLOR]:12345BK_화이트",
            "{19}": "서소문58-12대한통운",
            "{20}": "안내문구삽입",
            "{21}": "중구Bsub-홍길동-A01",
            "{22}": "0",
            "{23}": "신용",
            "{24}": "123412345678",
          },
        ],
      })
    );
  }
);

export const getShippingDetail = rest.get(
  getBofulDevAPI("/shipping/detail"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const getLocationDetails = rest.get(
  getBofulDevAPI("/location"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const changeShippingItemsLocations = rest.patch(
  getBofulDevAPI("/shipping/admin/item/locations"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const forceShipping = rest.post(
  getBofulDevAPI("/shipping/admin/force"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

// export const issueShippingInvoice3 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice4 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice5 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice6 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice7 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice8 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice9 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice10 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice11 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice12 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice13 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice14 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice15 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice16 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice17 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice18 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice19 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
// export const issueShippingInvoice20 = rest.patch(
//   getBofulDevAPI("/shipping/invoice"),
//   (req, res, ctx) => res(ctx.status(200), ctx.delay(500), ctx.json(data))
// );
