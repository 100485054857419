import styled from "styled-components";

const reportProblemModal = styled.div`
  .report-problem-table {
    margin-bottom: 24px;

    .curtain.right {
      display: none;
    }

    .textarea-upper-row {
      > td {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

const skuId = styled.div`
  text-align: left;
`;

export default {
  reportProblemModal,
  skuId,
};
