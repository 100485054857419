import React from "react";
import ReactDOM from "react-dom";

import { initSentryForCRA } from "@sellernote/_shared/src/services/sentry/sentryForCRA";
import "@sellernote/_shared/src/i18n/initI18nForCRA";

import App from "./App";

initSentryForCRA();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
